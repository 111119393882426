import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { carsActions, carFilterActions } from '@actions'
import { Collapse, Select, InputNumber } from 'antd';
import CustomSelect from '@components/CustomSelect';
import { useForm } from "react-hook-form";
import {getYears} from '@helpers';
import { INITIAL_STATE } from '@constants';
import _ from 'lodash';
import { Drawer, Button } from 'antd'

const MobileFilter = ({ setVisible, visible }) => {
  const { makes, models, generations, series, trims } = useSelector(({carFilterReducer}) => carFilterReducer);
  const { body_types, fuel_types, transmition_types, colors } = useSelector(state => state.carsReducer)
  const { carsMeta } = useSelector(state => state.carsReducer)

  const handleOpen = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const [selectedMake, setSelectedMake] = useState(null);
  const [selectedMakeTitle, setSelectedMakeTitle] = useState(null);

  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedModelTitle, setSelectedModelTitle] = useState(null);

  const [selectedGeneration, setSelectedGeneration] = useState(null);
  const [selectedGenerationTitle, setSelectedGenerationTitle] = useState(null);

  const [selectedSerie, setSelectedSerie] = useState(null);
  const [selectedSerieTitle, setSelectedSerieTitle] = useState(null);

  const [selectedTrim, setSelectedTrim] = useState(null);
  const [selectedTrimTitle, setSelectedTrimTitle] = useState(null);

  const [yearFromTitle, setYearFromTitle] = useState(null);
  const [yearToTitle, setYearToTitle] = useState(null);

  const [transmitionTitle, setTransmitionTitle] = useState(null);
  const [colorTitle, setColorTitle] = useState(null);


  const [additionalFilter, setAdditionalFilter] = useState({ price_from: '', price_to: '', odometer_from: '', odometer_to: '' })

  const { handleSubmit, register,  formState: { errors }, control, reset } = useForm();
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(carFilterActions.getMakes());
  }, []);

  useEffect(() => {
    dispatch(carsActions.getBodyTypes())
    dispatch(carsActions.getFuelTypes())
    dispatch(carsActions.getTransmitionTypes())
    dispatch(carsActions.getColors())
  }, [])

  const addFilter = (key, value) => { 
    const tempMeta = { ...carsMeta }  
    tempMeta.cars[0][key] = value
    // console.log(tempMeta)
    dispatch(carsActions.getCars(tempMeta));
  }

  const addSecondaryFilter = (key,  value) => {
    const tempMeta = { ...carsMeta }  
    tempMeta[key] = value
    dispatch(carsActions.getCars(tempMeta));
  }

  const addSecondaryFilterDebounced = _.debounce((key, value) => {
    setAdditionalFilter(prevFilter => ({ ...prevFilter, [`${key}`]: value }))
    const tempMeta = { ...carsMeta }  
    tempMeta[key] = value
    dispatch(carsActions.getCars(tempMeta));
  },
  500,
);

  const handleMakeSelect = value => {
    console.log({ value });
    const make_id = makes.filter(make => make.title === value)[0].id;
    const make_title = makes.filter(make => make.title === value)[0].title;
    dispatch(carFilterActions.getModels(make_id));
    setSelectedMake(make_id);
    setSelectedMakeTitle(make_title);
    // dispatch(carsActions.getCars({...carsMeta }));
    addFilter('make_id', make_id);
  }

  const handleModelSelect = value => {
    console.log(value);
    const model_id = models.filter(model => model.title === value)[0].id;
    const model_title = models.filter(model => model.title === value)[0].title;
    dispatch(carFilterActions.getGenerations(model_id));
    setSelectedModel(model_id);
    setSelectedModelTitle(model_title);
    addFilter('model_id', model_id);
  }

  const handleGenerationSelect = value => {
    const gen_id = generations.filter(gen => gen.name === value)[0].id;
    const gen_title = generations.filter(gen => gen.name === value)[0].title;
    dispatch(carFilterActions.getSeries(selectedModel, gen_id));
    setSelectedGeneration(gen_id);
    setSelectedGenerationTitle(gen_title);
    addFilter('generation_id', gen_id)
  }

  const handleSerieSelect = value => {
    const serie_id = series.filter(serie => serie.name === value)[0].id;
    const serie_title = series.filter(serie => serie.name === value)[0].title;
    dispatch(carFilterActions.getTrims(selectedModel, serie_id));
    setSelectedSerie(serie_id);
    setSelectedSerieTitle(serie_title);
    addFilter('serie_id', serie_id)
  }

  const handleTrimSelect = value => {
    const trim_id = trims.filter(trim => trim.name === value)[0].id;
    const trim_title = trims.filter(trim => trim.name === value)[0].title;
    setSelectedTrim(trim_id);
    setSelectedTrimTitle(trim_title);
    addFilter('trim_id', trim_id)
  }

  const handleClearFilter = () => {
    dispatch(carsActions.getCars({...INITIAL_STATE.CarsInitialState.carsMeta, cars: [ {} ], search: carsMeta.search }))

    reset({ make_id: null, model_id: null, generation_id: null, serie_id: null, car_year: null, trim_id: null, transmition: null, color: null });

    setAdditionalFilter({ price_from: '', price_to: '', odometer_from: '', odometer_to: '' })

    setSelectedMake(null)
    setSelectedMakeTitle(null)

    setSelectedModel(null)
    setSelectedModelTitle(null)

    setSelectedGeneration(null)
    setSelectedGenerationTitle(null)
    
    setSelectedSerie(null)
    setSelectedSerieTitle(null)

    setSelectedTrim(null)
    setSelectedTrimTitle(null)

    setYearFromTitle(null)
    setYearToTitle(null)

    setTransmitionTitle(null)

    setColorTitle(null)

    onClose()
  }

  
  const YEARS = getYears()
  const NCT_YEARS = getYears(2)
  const makesData = makes.map(make => make.title);
  const modelsData = models.map(model => model.title);
  const generationsData = generations.map(generation => generation.name)
  const seriesData = series.map(serie => serie.name)
  const trimsData = trims.map(trim => trim.name)

  return (
    <Drawer
          width="100%"
          // closable={false}
          onClose={onClose}
          visible={visible}
          bodyStyle={{ padding: 0 }}
          className="filter-drawer"
        >
    <div className="car-filter" style={{ display: 'block', marginTop: '10px' }}>
      <div className="car-filter__header">
        <h3>Filter</h3>
        <span onClick={handleClearFilter}>Reset all</span>
      </div>
      <form id="filter-form" className="filters-container" style={{ width: '85%', margin: 'auto' }}>
          <CustomSelect label="Make" control={control} placeholder="Choose a make from the list" name="make_id" values={makesData} value={selectedMakeTitle} onChange={handleMakeSelect} showSearch />
           <CustomSelect label="Model" control={control} placeholder="Choose the model from the list" name="model_id" values={modelsData} value={selectedModelTitle} onChange={handleModelSelect} showSearch />

          <div className="number-fields">
            <span>Price</span>
            <div className="from-to-filter-wrapper">
              <InputNumber
                placeholder="From"
                onChange={(val) => addSecondaryFilterDebounced('price_from', val)}
                className='sub-menu'
                value={additionalFilter.price_from}
                // value={filter.price_from || ''}
                // onBlur={handleBlurPrice}
              />
              <InputNumber
                placeholder="To"
                // onChange={handlePriceToChange}
                className='sub-menu'
                onChange={(val) => addSecondaryFilterDebounced('price_to', val)}
                value={additionalFilter.price_to}
                // value={filter.price_to || ''}
                // onBlur={handleBlurPrice}
              />
            </div>
          </div>

          <div className="number-fields">
            <span>Years</span>
            <div className="from-to-filter-wrapper">
              <CustomSelect value={yearFromTitle} labelStyle={{ width: '48%' }} o onChange={value => {
                  setYearFromTitle(value)
                  addSecondaryFilter('year_from', value)
                }}  
                showSearch values={YEARS} control={control} placeholder="From" name="car_year" />
              <CustomSelect value={yearToTitle} labelStyle={{ width: '48%' }} onChange={value => {
                setYearToTitle(value)
                addSecondaryFilter('year_to', value)
              }}  
              showSearch values={YEARS} control={control} placeholder="To" name="car_year" />
            </div>
          </div>
           
           <CustomSelect label="Generation" values={generationsData} control={control} placeholder="Choose a car generation from the list" name="generation_id" onChange={handleGenerationSelect} />

          <CustomSelect label="Series" values={seriesData} control={control} placeholder="Choose a serie from the list" name="serie_id" onChange={handleSerieSelect} />

          <CustomSelect label="Engine" values={trimsData} control={control} placeholder="Choose an engine from the list" name="trim_id" onChange={handleTrimSelect} />
          <div className="number-fields">
            <span>Odometer</span>
            <div className="from-to-filter-wrapper">
              <InputNumber
                placeholder="From"
                onChange={(val) => addSecondaryFilterDebounced('odometer_from', val)}
                className='sub-menu'
                value={additionalFilter.odometer_from}
                // value={filter.price_from || ''}
                // onBlur={handleBlurPrice}
              />
              <InputNumber
                placeholder="To"
                // onChange={handlePriceToChange}
                onChange={(val) => addSecondaryFilterDebounced('odometer_to', val)}
                className='sub-menu'
                value={additionalFilter.odometer_to}
                // value={filter.price_to || ''}
                // onBlur={handleBlurPrice}
              />
            </div>
          </div>
          <CustomSelect value={transmitionTitle} onChange={val => { 
                addSecondaryFilter('transmition_type', val) 
                setTransmitionTitle(val)
              }
            }  label="Transmission" control={control} placeholder="Choose a transmission type from the list" name="transmition">
          { transmition_types?.map(type => <Option value={type.key}>{type.key}</Option>) }
      </CustomSelect>

      <CustomSelect value={colorTitle} 
        onChange={val => { 
          setColorTitle(val)
          addSecondaryFilter('color', val) 
        }}  
        label="Colour" 
        control={control} 
        placeholder="Choose a colour" 
        name="color">
          { colors?.map(type => <Option value={type.key}>{type.key}</Option>) }
      </CustomSelect>

      <Button onClick={onClose} type="primary" size="large" style={{ width: '100%', marginTop: '10px', fontWeight: '600' }} >Get Results</Button>
      </form>
    </div>
      </Drawer>
  )
}

export default MobileFilter
