export default {
  GET_MAKES: 'GET_MAKES',
  GET_MAKES_SUCCESS: 'GET_MAKES_SUCCESS',
  GET_MAKES_ERROR: 'GET_MAKES_ERROR',
  GET_MODELS: 'GET_MODELS',
  GET_MODELS_SUCCESS: 'GET_MODELS_SUCCESS',
  GET_MODELS_ERROR: 'GET_MODELS_ERROR',
  GET_GENERATIONS: 'GET_GENERATIONS',
  GET_GENERATIONS_SUCCESS: 'GET_GENERATIONS_SUCCESS',
  GET_GENERATIONS_ERROR: 'GET_GENERATIONS_ERROR',
  GET_SERIES: 'GET_SERIES',
  GET_SERIES_SUCCESS: 'GET_SERIES_SUCCESS',
  GET_SERIES_ERROR: 'GET_SERIES_ERROR',
  GET_TRIMS: 'GET_TRIMS',
  GET_TRIMS_SUCCESS: 'GET_TRIMS_SUCCESS',
  GET_TRIMS_ERROR: 'GET_TRIMS_ERROR',
  FILTER_SELECT: 'FILTER_SELECT',
  CLEAR_FILTER: 'CLEAR_FILTER',
  SET_CAR_FILTER: 'ADD_CAR_FILTER'
};
