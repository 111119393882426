import { PROFILE_ACTIONS } from '@constants';

export const follow = (id) => ({
  type: PROFILE_ACTIONS.FOLLOW,
  payload: {
    id
  },
});

export const unfollow = (id) => ({
  type: PROFILE_ACTIONS.UNFOLLOW,
  payload: {
    id
  },
});

export const followSuccess = (id) => ({
  type: PROFILE_ACTIONS.FOLLOW_SUCCESS,
  payload: {
    id
  },
});

export const unfollowSuccess = (id) => ({
  type: PROFILE_ACTIONS.UNFOLLOW_SUCCESS,
  payload: {
    id
  },
});

export const followError = (error) => ({
  type: PROFILE_ACTIONS.FOLLOW_ERROR,
  payload: {
    error
  },
});

export const unfollowError = (error) => ({
  type: PROFILE_ACTIONS.UNFOLLOW_ERROR,
  payload: {
    error
  },
});

export const updateProfile = (user, register = false, popup = false) => {
  return {
    type: PROFILE_ACTIONS.UPDATE_PROFILE,
    payload: {
      user,
      register,
      popup
    },
  };
};

export const updateProfileSuccess = (profile) => {
  return {
    type: PROFILE_ACTIONS.UPDATE_PROFILE_SUCCESS,
    payload: {
      profile
    },
  };
};

export const updateProfileError = (error) => {
  return {
    type: PROFILE_ACTIONS.UPDATE_PROFILE_ERROR,
    payload: {
      error
    },
  };
};

export const updatePassword = (passwords, pToken) => {
  return {
    type: PROFILE_ACTIONS.UPDATE_PASSWORD,
    payload: {passwords, pToken},
  };
};

export const updatePasswordSuccess = () => {
  return {
    type: PROFILE_ACTIONS.UPDATE_PASSWORD_SUCCESS,
  };
};

export const updatePasswordError = (error) => {
  return {
    type: PROFILE_ACTIONS.UPDATE_PASSWORD_ERROR,
    payload: {error},
  };
};

export const getFollowers = (meta) => ({
  type: PROFILE_ACTIONS.GET_FOLLOWERS,
  payload: {meta},
});

export const getFollowersSuccess = (followers, total_pages) => ({
  type: PROFILE_ACTIONS.GET_FOLLOWERS_SUCCESS,
  payload: {followers, total_pages},
});

export const getFollowersNextPageSuccess = (followers, total_pages) => ({
  type: PROFILE_ACTIONS.GET_FOLLOWERS_NEXT_PAGE_SUCCESS,
  payload: {followers, total_pages},
});

export const getFollowersError = (error) => ({
  type: PROFILE_ACTIONS.GET_FOLLOWERS_ERROR,
  payload: {error},
});

export const getFollowing = (meta) => ({
  type: PROFILE_ACTIONS.GET_FOLLOWING,
  payload: {meta},
});

export const getFollowingSuccess = (following, total_pages) => ({
  type: PROFILE_ACTIONS.GET_FOLLOWING_SUCCESS,
  payload: {following, total_pages},
});

export const getFollowingNextPageSuccess = (following, total_pages) => ({
  type: PROFILE_ACTIONS.GET_FOLLOWING_NEXT_PAGE_SUCCESS,
  payload: {following, total_pages},
});

export const getFollowingError = (error) => ({
  type: PROFILE_ACTIONS.GET_FOLLOWING_ERROR,
  payload: {error},
});

export const reportUser = (reportable) => {
  return {
    type: PROFILE_ACTIONS.REPORT_USER,
    payload: {reportable},
  };
};

export const reportUserError = (error) => ({
  type: PROFILE_ACTIONS.REPORT_USER_ERROR,
  payload: {error},
});

export const reportUserSuccess = (id) => {
  return {
    type: PROFILE_ACTIONS.REPORT_USER_SUCCESS,
    payload: {id},
  };
};


export const block = (id) => ({
  type: PROFILE_ACTIONS.BLOCK,
  payload: {id},
});

export const blockSuccess = (id) => ({
  type: PROFILE_ACTIONS.BLOCK_SUCCESS,
  payload: {id}
});

export const unblock = (id) => ({
  type: PROFILE_ACTIONS.UNBLOCK,
  payload: {id},
});

export const unblockSuccess = (id) => ({
  type: PROFILE_ACTIONS.UNBLOCK_SUCCESS,
  payload: {id},
});

export const blockUnblockError = (error) => ({
  type: PROFILE_ACTIONS.BLOCK_UNBLOCK_ERROR,
  payload: {error},
});

export const getCurrentUser = () => ({
  type: PROFILE_ACTIONS.GET_CURRENT_USER,
  payload: {},
});

export const getCurrentUserSuccess = (user) => ({
  type: PROFILE_ACTIONS.GET_CURRENT_USER_SUCCESS,
  payload: {
    user
  },
});

export const getCurrentUserError = (error) => ({
  type: PROFILE_ACTIONS.GET_CURRENT_USER_ERROR,
  payload: {
    error
  },
});

export const getUserDetails = (user_id) => ({
  type: PROFILE_ACTIONS.GET_USER_DETAILS,
  payload: {
    user_id
  },
});

export const getUserDetailsSuccess = (user) => ({
  type: PROFILE_ACTIONS.GET_USER_DETAILS_SUCCESS,
  payload: {
    user
  },
});

export const getUserDetailsError = (error) => ({
  type: PROFILE_ACTIONS.GET_USER_DETAILS_ERROR,
  payload: {
    error
  },
});

export const getPetsRatings = (petsRatingUsersMeta) => ({
  type: PROFILE_ACTIONS.GET_PETS_RATINGS,
  payload: {petsRatingUsersMeta},
});

export const getServicesRatings = (servicesRatingUsersMeta) => ({
  type: PROFILE_ACTIONS.GET_SERVICES_RATINGS,
  payload: {servicesRatingUsersMeta},
});

export const getPetsRatingsSuccess = (ratingUsers, total_pages) => ({
  type: PROFILE_ACTIONS.GET_PETS_RATINGS_SUCCESS,
  payload: {ratingUsers, total_pages},
});

export const getPetsRatingsNextPageSuccess = (ratingUsers, total_pages) => ({
  type: PROFILE_ACTIONS.GET_PETS_RATINGS_NEXT_PAGE_SUCCESS,
  payload: {ratingUsers, total_pages},
});


export const getServicesRatingsSuccess = (ratingUsers, total_pages) => ({
  type: PROFILE_ACTIONS.GET_SERVICES_RATINGS_SUCCESS,
  payload: {ratingUsers, total_pages},
});

export const getServicesRatingsNextPageSuccess = (ratingUsers, total_pages) => ({
  type: PROFILE_ACTIONS.GET_SERVICES_RATINGS_NEXT_PAGE_SUCCESS,
  payload: {ratingUsers, total_pages},
});


export const getRatingsError = (error) => ({
  type: PROFILE_ACTIONS.GET_RATINGS_ERROR,
  payload: {error},
});

export const uploadAvatar = (avatar, user, register = false) => ({
  type: PROFILE_ACTIONS.UPLOAD_AVATAR,
  payload: {
    avatar,
    user,
    register,
  }
})

export const uploadAvatarError = (error) => ({
  type: PROFILE_ACTIONS.UPLOAD_AVATAR_ERROR,
  payload: {error}
})

export const getUsers = (usersMeta) => ({
  type: PROFILE_ACTIONS.GET_USERS,
  payload: {usersMeta},
});

export const getUserSuccess = (users, total_pages) => ({
  type: PROFILE_ACTIONS.GET_USERS_SUCCESS,
  payload: {users, total_pages},
});

export const getUsersNextPageSuccess = (users, total_pages) => ({
  type: PROFILE_ACTIONS.GET_USERS_NEXT_PAGE_SUCCESS,
  payload: {users, total_pages},
});

export const getUsersError = (error) => ({
  type: PROFILE_ACTIONS.GET_USERS_ERROR,
  payload: {error},
});

export const getBlackList = () => ({
  type: PROFILE_ACTIONS.GET_BLACK_LIST,
  // payload: {meta},
});

export const getBlackListSuccess = (blackList) => ({
  type: PROFILE_ACTIONS.GET_BLACK_LIST_SUCCESS,
  payload: {blackList},
});

export const getBlackListError = (error) => ({
  type: PROFILE_ACTIONS.GET_BLACK_LIST_ERROR,
  payload: {error},
});

export const saveBuyerRequest = buyer => ({
  type: PROFILE_ACTIONS.SAVE_BUYER_REQUEST,
  payload: {buyer},
});

export const saveBuyerSuccess = buyer => ({
  type: PROFILE_ACTIONS.SAVE_BUYER_SUCCESS,
  payload: {buyer},
});

export const saveBuyerError = error => ({
  type: PROFILE_ACTIONS.SAVE_BUYER_ERROR,
  payload: {error},
});

export const changePassword = passwords => ({
  type: PROFILE_ACTIONS.CHANGE_PASSWORD,
  payload: {passwords},
});

export const changePasswordSuccess = () => ({
  type: PROFILE_ACTIONS.CHANGE_PASSWORD_SUCCESS,
});

export const changePasswordError = error => ({
  type: PROFILE_ACTIONS.CHANGE_PASSWORD_ERROR,
  payload: {error},
});

export const changeEmail = emails => ({
  type: PROFILE_ACTIONS.CHANGE_EMAIL,
  payload: {emails},
});

export const changeEmailSuccess = () => ({
  type: PROFILE_ACTIONS.CHANGE_EMAIL_SUCCESS,
});

export const changeEmailError = error => ({
  type: PROFILE_ACTIONS.CHANGE_EMAIL_ERROR,
  payload: {error},
});
