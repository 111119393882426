import {MY_CARS_TABS_ACTIONS, INITIAL_STATE} from '../constants';

export const myCarsTabsReducer = (state = INITIAL_STATE.MyCarsTabsInitialState, action) => {
  const {type, payload} = action || {};
  const {selectedTab} = payload || {};

  switch (type) {
    case MY_CARS_TABS_ACTIONS.SELECT_TAB:
      return {
        ...state,
        selectedTab
      };
    default:
      return state;
  }
};
