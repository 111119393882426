import React from 'react'
import {Steps} from 'antd';
import {useWindowSize} from '@hooks/useWindowSize'
import './CreateProfile.scss'
import NewAccount from './NewAccount'
import DealerDetails from './DealerDetails'
import GarageDetails from "./GarageDetails";
import AddBuyers from "./AddBuyers";
import FinallySteps from "./FinallySteps";


const CreateProfile = () => {
  const size = useWindowSize()

  return (
    <div className="sell">
      {size.width > 1100 ?
        <Steps current={0} className="styled-steps">
          <Steps.Step title="Profile details"/>
          <Steps.Step title="Dealer details"/>
          <Steps.Step title="Garage details"/>
          <Steps.Step title="Add buyers"/>
        </Steps> :
        <Steps current={0} className="styled-steps">
          <Steps.Step/>
          <Steps.Step/>
          <Steps.Step/>
          <Steps.Step/>
        </Steps>
      }
      {/*<NewAccount />*/}
      {/*<DealerDetails/>*/}
      {/*<GarageDetails/>*/}
      {/*<AddBuyers/>*/}
      <FinallySteps/>
    </div>
  )
}

export default CreateProfile
