import React from 'react'
import Header from '@components/Header'
import Footer from '@components/Footer'
import ContentWrapper from '@components/Markup/ContentWrapper'

import { Link } from 'react-router-dom'
import { Button } from 'antd'

import sellCarImg from '@images/sell-car.png'
import sellCarImg2x from '@images/sell-car@2x.png'
import sellCarImg3x from '@images/sell-car@3x.png'

import buyCarImg from '@images/buy-car.png'
import buyCarImg2x from '@images/buy-car@2x.png'
import buyCarImg3x from '@images/buy-car@3x.png'

const RegisterSuccessPage = () => {
  return (
    <ContentWrapper contentClass="register">
      <Header />
      <div className="sell">
        <h1>You have successfully created an account</h1>
        <p style={{ color: '#000', fontSize: '18px', fontWeight: '500' }}>Choose your next step</p>
        <div className="reg-success__images">
          <Link to="/sell">
            <figure className="reg-success__image">
              <img src={sellCarImg} srcSet={`${sellCarImg2x} 2x, ${sellCarImg3x} 3x`}alt="sell car image" />
              <Button type='primary'>SELL CAR</Button>
            </figure>
          </Link>
          <Link to="/marketplace">
            <figure className="reg-success__image"> 
              <img src={buyCarImg} srcSet={`${buyCarImg2x} 2x, ${buyCarImg3x} 3x`}alt="buy car image" />
              <Button type='primary'>BUY CAR</Button>
            </figure>
          </Link>
        </div>
      </div>
      <ContentWrapper.Sticky>
        <Footer />
      </ContentWrapper.Sticky>
    </ContentWrapper>
  )
}

export default RegisterSuccessPage
