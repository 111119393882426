import {fork} from 'redux-saga/effects';
import {watchAuthSagas} from './authSagas';
import {watchGlobalSagas} from './globalSagas';
import {watchCarsSagas} from './carsSagas';
import {watchProfileSagas} from './profileSagas';
import {watchMessagingSagas} from './messagingSagas';
import {watchPaymentsSagas} from './paymentsSagas';
import {watchCarFilterSagas} from './carFilterSagas';
import {watchNotificationsSagas} from './notificationsSagas';

export default function* rootSaga() {
  yield fork(watchAuthSagas);
  yield fork(watchGlobalSagas);
  yield fork(watchCarsSagas);
  yield fork(watchProfileSagas);
  yield fork(watchMessagingSagas);
  yield fork(watchPaymentsSagas);
  yield fork(watchCarFilterSagas);
  yield fork(watchNotificationsSagas);
}
