import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom';
import { ROUTES } from '@router/routes';
import { carsActions, profileActions } from '@actions'
import { Upload, Avatar, Collapse } from 'antd'
import { config } from '@config'
import ImgCrop from 'antd-img-crop';
import moment from 'moment'

const UserInfo = () => {
  const { user } = useSelector(state => state.authReducer)
  const { buyersMeta, buyers } = useSelector(state => state.carsReducer)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(carsActions.getBuyers(buyersMeta))
  }, [])

  const handleUploadAvatar = (file) => {
    dispatch(profileActions.uploadAvatar(file, user, true))
  }

  return user ? (
    <div className="side-stats">
      <div className="side-stats__stat">
        <div className="change-avatar">
         { user.avatar_url ? <Avatar
            src={config.REACT_APP_IMAGE_URL + user.avatar_url}
            size={95}
          /> : <Avatar style={{ fontSize: '32px' }} size={95}>{user?.business_name?.[0].toUpperCase()}</Avatar> }
          <div className="change-avatar__actions">
            <Link to={ROUTES.EDIT_PROFILE}>Edit profile</Link>
            <ImgCrop shape='round' rotate style={{ cursor: 'pointer' }}>
              <Upload
                customRequest={({ onSuccess, onError, file }) => handleUploadAvatar(file)}
                name="avatar"
                showUploadList={false}
                multiple={false}
                accept=".jpg, .jpeg, .png"
                maxCount={1}
              >
                <button>Change photo</button>
              </Upload>
            </ImgCrop>
          </div>
        </div>
        <h1>Dealer details</h1>
        <div className="home__desc">
          <p>Official business name:</p>
          <h4>{user.business_name}</h4>
        </div>
        <div className="home__desc">
          <p>Eircode:</p>
          <h4>{user.eircode}</h4>
        </div>
        <div className="home__desc">
          <p>Garage Code:</p>
          <h4>{user.garage_code}</h4>
        </div>
        <div className="home__desc">
          <p>Registered:</p>
          <h4>{moment(user?.created_at).fromNow()}</h4>
        </div>
      </div>
      { buyers.length ? <div className="side-stats__stat">
        <h1>Extra Buyers details</h1>
        <Collapse ghost bordered={false} expandIconPosition="right" className="car-filter__collapse">
          { buyers?.map((buyer, i) => 
            <Collapse.Panel 
              header={
                <>
                  <p>Buyer:</p>
                  <h4>{buyer?.buyer}</h4>
                </>
              } 
              key={i}
              className="home__desc"
            >
              <p style={{ marginTop: '10px' }}>Title:</p>
              <h4 style={{ fontSize: '14px' }}>{buyer?.title}</h4>

              <p style={{ marginTop: '10px' }}>Phone:</p>
              <h4 style={{ fontSize: '14px' }}>{buyer?.phone_number}</h4>

              <p style={{ marginTop: '10px' }}>Email:</p>
              <h4 style={{ fontSize: '14px' }}>{buyer?.email}</h4>
            </Collapse.Panel>
          )}
        </Collapse>
        
      </div> : null }
      <div className="side-stats__stat">
        <h1>Contact info</h1>
        <div className="home__desc">
          <p>Email:</p>
          <h4>{user.email}</h4>
        </div>
        <div className="home__desc">
          <p>Address:</p>
          <h4>{user.address}</h4>
        </div>
      </div>
    </div>
  ) : null
}

export default UserInfo
