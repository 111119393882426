import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from "react-hook-form";
import { Button } from 'antd'
import { profileActions } from '@actions'

const ChangePassword = () => {
  const { user, error } = useSelector(state => state.authReducer)
  const { handleSubmit, register, formState: { errors } } = useForm({
    defaultValues: {
      email: user?.email || '',
    },
  });
  const dispatch = useDispatch()

  const onSubmit = (data) => {
    dispatch(profileActions.changePassword(data));
  }

  return (
    <form className="sell-form sell-main edit-profile-form" onSubmit={handleSubmit(onSubmit)}>
      <h1 style={{textAlign: 'center'}}>Change Password</h1>
      <label className="sell-form__label" style={{ marginTop: '24px' }} >
        Your current password
        <input { ...register('old_password', { required: true }) } type="password" className="sell-form__input" />
      </label>
      { errors.email && <p className="error">Current password is required</p> }
      <p className="dealer-details-text">New Password</p>
      <label className="sell-form__label" style={{ marginTop: '24px' }} >
        New password
        <input { ...register('password', { required: true }) } type="password" className="sell-form__input" />
      </label>
      { errors.email && <p className="error">New password is required</p> }
      <label className="sell-form__label" style={{ marginTop: '24px' }} >
        Repeat new password
        <input { ...register('password_confirmation', { required: true }) } type="password" className="sell-form__input" />
      </label>
      { errors.email && <p className="error">Repeat new password</p> }
      <Button style={{ width: '100%', marginTop: '16px'}} size="large" type="primary" htmlType="submit">Change</Button>
      { error && <p style={{ textAlign: 'center', marginTop: '12px' }} className="error">{error}</p> }
    </form>
  )
}

export default ChangePassword
