import { CARS_ACTIONS } from '@constants';

export const deleteCar = (id) => ({
  type: CARS_ACTIONS.DELETE_CAR,
  payload: { id }
})

export const deleteCarSuccess = (id) => ({
  type: CARS_ACTIONS.DELETE_CAR_SUCCESS,
  payload: { id }
})

export const deleteCarError = (error) => ({
  type: CARS_ACTIONS.DELETE_CAR,
  payload: { error }
})

export const getAuctions = (meta, replace) => ({
  type: CARS_ACTIONS.GET_AUCTIONS,
  payload: { meta, replace }
})

export const getAuctionsSuccess = (cars, total_pages, total_products, replace = false) => ({
  type: CARS_ACTIONS.GET_AUCTIONS_SUCCESS,
  payload: { cars, total_pages, total_products, replace }
})

export const getAuctionsError = (error) => ({
  type: CARS_ACTIONS.GET_AUCTIONS_ERROR,
  payload: { error }
})

export const createBuyer = (user) => ({
  type: CARS_ACTIONS.CREATE_BUYER,
  payload: { user }
})

export const createBuyerSuccess = buyer => ({
  type: CARS_ACTIONS.CREATE_BUYER_SUCCESS,
  payload: {buyer}
})

export const createBuyerError = (error) => ({
  type: CARS_ACTIONS.CREATE_BUYER_ERROR,
  payload: { error }
})

export const getBuyers = (meta) => ({
  type: CARS_ACTIONS.GET_BUYERS,
  payload: { meta }
})

export const getBuyersSuccess = (buyers) => ({
  type: CARS_ACTIONS.GET_BUYERS_SUCCESS,
  payload: { buyers }
})

export const getBuyersError = (error) => ({
  type: CARS_ACTIONS.GET_BUYERS_ERROR,
  payload: { error }
})

export const preBid = (amount, sale_id) => ({
  type: CARS_ACTIONS.PRE_BID,
  payload: {amount, sale_id}
})

export const preBidSuccess = (bid) => ({
  type: CARS_ACTIONS.PRE_BID_SUCCESS,
  payload: {bid}
})

export const preBidError = (error) => ({
  type: CARS_ACTIONS.PRE_BID_ERROR,
  payload: {error}
})

export const updateBid = (message) => ({
  type: CARS_ACTIONS.UPDATE_BID,
  payload: {message}
})

export const winBid = (win_bid) => ({
  type: CARS_ACTIONS.WIN_BID,
  payload: { win_bid }
})

export const acceptWin = (win_id) => ({
  type: CARS_ACTIONS.ACCEPT_WIN,
  payload: { win_id }
})

export const acceptWinSuccess = (win_id) => ({
  type: CARS_ACTIONS.ACCEPT_WIN_SUCCESS,
  payload: { win_id }
})

export const acceptWinError = (error) => ({
  type: CARS_ACTIONS.ACCEPT_WIN_ERROR,
  payload: { error }
})

export const rejectWin = (win_id) => ({
  type: CARS_ACTIONS.REJECT_WIN,
  payload: { win_id }
})

export const rejectWinSuccess = (win_id) => ({
  type: CARS_ACTIONS.REJECT_WIN_SUCCESS,
  payload: { win_id }
})

export const rejectWinError = (error) => ({
  type: CARS_ACTIONS.REJECT_WIN_ERROR,
  payload: { error }
})

export const getCars = (meta) => ({
  type: CARS_ACTIONS.GET_CARS,
  payload: {meta}
})

export const getCarsSuccess = (cars, total_pages, total_products) => ({
  type: CARS_ACTIONS.GET_CARS_SUCCESS,
  payload: {cars, total_pages, total_products}
})

export const getCarsError = (error) => ({
  type: CARS_ACTIONS.GET_CARS_ERROR,
  payload: {error}
})

export const getMyCars = (meta, replace = false) => ({
  type: CARS_ACTIONS.GET_MY_CARS,
  payload: {meta, replace}
})

export const getMyCarsSuccess = (cars, total_pages, replace = false) => ({
  type: CARS_ACTIONS.GET_MY_CARS_SUCCESS,
  payload: {cars, total_pages, replace}
})

export const getMyCarsError = (error) => ({
  type: CARS_ACTIONS.GET_MY_CARS_ERROR,
  payload: {error},
})

export const getFollowingCars = (meta, replace = false) => ({
  type: CARS_ACTIONS.GET_FOLLOWING_CARS,
  payload: {meta, replace}
})

export const getFollowingCarsSuccess = (cars, total_pages, replace = false) => ({
  type: CARS_ACTIONS.GET_FOLLOWING_CARS_SUCCESS,
  payload: {cars, total_pages, replace}
})

export const getFollowingCarsError = (error) => ({
  type: CARS_ACTIONS.GET_FOLLOWING_CARS_ERROR,
  payload: { error }
})

export const getPurchasedCars = (meta, replace = false) => ({
  type: CARS_ACTIONS.GET_PURCHASED_CARS,
  payload: {meta, replace}
})

export const getPurchasedCarsSuccess = (cars, total_pages, replace = false) => ({
  type: CARS_ACTIONS.GET_PURCHASED_CARS_SUCCESS,
  payload: {cars, total_pages, replace}
})

export const getPurchasedCarsError = (error) => ({
  type: CARS_ACTIONS.GET_PURCHASED_CARS_ERROR,
  payload: { error }
})

export const getArchivedCars = (meta, replace = false) => ({
  type: CARS_ACTIONS.GET_ARCHIVED_CARS,
  payload: {meta, replace}
})

export const getArchivedCarsSuccess = (cars, total_pages, replace = false) => ({
  type: CARS_ACTIONS.GET_ARCHIVED_CARS_SUCCESS,
  payload: {cars, total_pages, replace}
})

export const getArchivedCarsError = (error) => ({
  type: CARS_ACTIONS.GET_ARCHIVED_CARS_ERROR,
  payload: { error }
})

export const getRelatedCars = (meta, id, replace = false) => ({
  type: CARS_ACTIONS.GET_RELATED_CARS,
  payload: { meta, id, replace }
})

export const getRelatedCarsSuccess = (cars, total_pages, replace = false) => ({
  type: CARS_ACTIONS.GET_RELATED_CARS_SUCCESS,
  payload: {cars, total_pages, replace}
})

export const getRelatedCarsError = (error) => ({
  type: CARS_ACTIONS.GET_RELATED_CARS_ERROR,
  payload: { error }
})

export const cleanCarInfo = () => ({
	type: CARS_ACTIONS.CLEAN_CAR_INFO
})

export const getCarInfo = (id, isPurchased) => ({
  type: CARS_ACTIONS.GET_CAR_INFO,
  payload: {id, isPurchased}
})

export const getCarInfoSuccess = (car) => ({
  type: CARS_ACTIONS.GET_CAR_INFO_SUCCESS,
  payload: {car}
})

export const getCarInfoError = (error) => ({
  type: CARS_ACTIONS.GET_CAR_INFO_ERROR,
  payload: {error}
})

export const createCar = (car) => ({ 
  type: CARS_ACTIONS.CREATE_CAR,
  payload: {car}
})

export const createCarSuccess = (product) => ({
  type: CARS_ACTIONS.CREATE_CAR_SUCCESS,
  payload: {product}
})

export const createCarError = (error) => ({
  type: CARS_ACTIONS.CREATE_CAR_ERROR,
  payload: {error},
})

export const addCarSerivceHistory = (history) => ({
  type: CARS_ACTIONS.ADD_CAR_SERVICE_HISTORY,
  payload: {history}
})

export const addCarSerivceHistorySuccess = () => ({
  type: CARS_ACTIONS.ADD_CAR_SERIVCE_HISTORY_SUCCESS,
  payload: {}
})

export const addCarSerivceHistoryError = (error) => ({
  type: CARS_ACTIONS.ADD_CAR_SERIVCE_HISTORY_ERROR,
  payload: {error}
})

export const addCarPhoto = (photo, onSuccess, onError) => ({
  type: CARS_ACTIONS.ADD_CAR_PHOTO,
  payload: {photo, onSuccess, onError}
})

export const addCarPhotoSuccess = () => ({
  type: CARS_ACTIONS.ADD_CAR_PHOTO_SUCCESS,
  payload: {}
})

export const addCarPhotoError = (error) => ({
  type: CARS_ACTIONS.ADD_CAR_PHOTO_ERROR,
  payload: {error}
})

export const getAttachments = (id, replace) => ({
  type: CARS_ACTIONS.GET_ATTACHMENTS,
  payload: {id, replace}
})

export const getAttachmentsSuccess = (attachments, replace) => ({
  type: CARS_ACTIONS.GET_ATTACHMENTS_SUCCESS,
  payload: {attachments, replace}
})

export const getAttachmentsError = (error) => ({
  payload: {error}
})

export const removeAttachments = (id, product_id) => ({
  type: CARS_ACTIONS.REMOVE_ATTACHMENTS,
  payload: {id, product_id}
})

export const removeAttachmentsSuccess = (id) => ({
  type: CARS_ACTIONS.REMOVE_ATTACHMENTS_SUCCESS,
  payload: {id}
})

export const removeAttachmentsError = (error) => ({
  type: CARS_ACTIONS.REMOVE_ATTACHMENTS_ERROR,
  payload: {error}
})

export const createZone = (zone) => ({
  type: CARS_ACTIONS.CREATE_ZONE,
  payload: {zone}
})

export const createZoneSuccess = (zone) => ({
  type: CARS_ACTIONS.CREATE_ZONE_SUCCESS,
  payload: {zone}
})

export const createZoneError = (error) => ({
  type: CARS_ACTIONS.CREATE_ZONE_ERROR,
  payload: {error}
})

export const addCarSummary = (summary) => ({
  type: CARS_ACTIONS.ADD_CAR_SUMMARY,
  payload: { summary }
})

export const releaseCar = (id) => ({
  type: CARS_ACTIONS.RELEASE_CAR,
  payload: {id}
})

export const releaseCarSuccess = () => ({
  type: CARS_ACTIONS.RELEASE_CAR_SUCCESS,
  payload: {}
})

export const releaseCarError = (error) => ({
  type: CARS_ACTIONS.RELEASE_CAR_ERROR,
  payload: {error}
})

export const updateCar = (values) => ({
  type: CARS_ACTIONS.UPDATE_CAR,
  payload: { values }
});

export const updateCarSuccess = (data) => ({
  type: CARS_ACTIONS.UPDATE_CAR_SUCCCESS,
  payload: { data }
});

export const updateCarError = (error) => ({
  type: CARS_ACTIONS.UPDATE_CAR_ERROR,
  payload: { error }
});


export const getClutch = () => ({
  type: CARS_ACTIONS.GET_CLUTCH,
  payload: {}
})

export const getClutchSuccess = (clutch) => ({
  type: CARS_ACTIONS.GET_CLUTCH_SUCCESS,
  payload: {clutch}
})

export const getClutchError = (error) => ({
  type: CARS_ACTIONS.GET_CLUTCH_ERROR,
  type: {error}
})

export const getTyres = () => ({
  type: CARS_ACTIONS.GET_TYRES,
  payload: {}
})

export const getTyresSuccess = (tyres) => ({
  type: CARS_ACTIONS.GET_TYRES_SUCCESS,
  payload: {tyres}
})

export const getTyresError = (error) => ({
  type: CARS_ACTIONS.GET_TYRES_ERROR,
  payload: {error}
})

export const getDamage = () => ({
  type: CARS_ACTIONS.GET_DAMAGE
})

export const getDamageSuccess = (damage) => ({
  type: CARS_ACTIONS.GET_DAMAGE_SUCCESS,
  payload: {damage}
})

export const getDamageError = (error) => ({
  type: CARS_ACTIONS.GET_DAMAGE_ERROR,
  payload: {error}
})

export const getHistoryTypes = () => ({
  type: CARS_ACTIONS.GET_HISTORY_TYPES,
})

export const getHistoryTypesSuccess = (history_types) => ({
  type: CARS_ACTIONS.GET_HISTORY_TYPES_SUCCESS,
  payload: {history_types}
})

export const getHistoryTypesError = (error) => ({
  type: CARS_ACTIONS.GET_HISTORY_TYPES_ERROR,
  payload: {error}
})

export const getBodyTypes = () => ({
  type: CARS_ACTIONS.GET_BODY_TYPES
})

export const getBodyTypesSuccess = (body_types) => ({
  type: CARS_ACTIONS.GET_BODY_TYPES_SUCCESS,
  payload: { body_types }
})

export const getBodyTypesError = (error) => ({
  type: CARS_ACTIONS.GET_BODY_TYPES_ERROR,
  payload: { error }
})

export const getFuelTypes = () => ({
  type: CARS_ACTIONS.GET_FUEL_TYPES
})

export const getFuelTypesSuccess = (fuel_types) => ({
  type: CARS_ACTIONS.GET_FUEL_TYPES_SUCCESS,
  payload: { fuel_types }
})

export const getFuelTypesError = (error) => ({
  type: CARS_ACTIONS.GET_FUEL_TYPES_ERROR,
  payload: { error }
})

export const getTransmitionTypes = () => ({
  type: CARS_ACTIONS.GET_TRANSMITION_TYPES
})

export const getTransmitionTypesSuccess = (transmition_types) => ({
  type: CARS_ACTIONS.GET_TRANSMITION_TYPES_SUCCESS,
  payload: { transmition_types }
})

export const getTransmitionTypesError = (error) => ({
  type: CARS_ACTIONS.GET_TRANSMITION_TYPES_ERROR,
  payload: { error }
})

export const getColors = () => ({
  type: CARS_ACTIONS.GET_COLORS
})

export const getColorsSuccess = (colors) => ({
  type: CARS_ACTIONS.GET_COLORS_SUCCESS,
  payload: { colors }
})

export const getColorsError = (error) => ({
  type: CARS_ACTIONS.GET_ARCHIVED_COLORS_ERROR,
  payload: { error }
})

export const getFeeInvoice = (win_bid_id, ref) => ({
  type: CARS_ACTIONS.GET_FEE_INVOICE,
  payload: { win_bid_id, ref }
})

export const getFeeInvoiceSuccess = () => ({
  type: CARS_ACTIONS.GET_FEE_INVOICE_SUCCESS,
  payload: {  }
})

export const getFeeInvoiceError = () => ({
  type: CARS_ACTIONS.GET_FEE_INVOICE_ERROR,
  payload: {  }
})

export const getInvoice = (win_bid_id, ref) => ({
  type: CARS_ACTIONS.GET_INVOICE,
  payload: { win_bid_id, ref }
})


export const getInvoiceSuccess = () => ({
  type: CARS_ACTIONS.GET_INVOICE_SUCCESS,
  payload: {}
})

export const getInvoiceError = (error) => ({
  type: CARS_ACTIONS.GET_INVOICE_ERROR,
  payload: { error }
})


export const addStep = () => ({
  type: CARS_ACTIONS.ADD_STEP
})

export const setStep = (step) => ({
  type: CARS_ACTIONS.SET_STEP,
  payload: {step}
})

export const getPosts = (meta) => ({
  type: CARS_ACTIONS.GET_POSTS,
  payload: {meta},
});

export const getPost = (id) => ({
  type: CARS_ACTIONS.GET_POST,
  payload: {id},
});

export const getPostsSuccess = (posts, total_pages) => ({
  type: CARS_ACTIONS.GET_POSTS_SUCCESS,
  payload: {posts, total_pages},
}); 

export const getPostSuccess = (post) => ({
  type: CARS_ACTIONS.GET_POST_SUCCESS,
  payload: {post},
});

export const likePost = (id) => ({
  type: CARS_ACTIONS.LIKE_POST,
  payload: {id},
});

export const likePostSuccess = (id, likes_count) => ({
  type: CARS_ACTIONS.LIKE_POST_SUCCESS,
  payload: {id, likes_count},
});

export const getPostsError = (error) => ({
  type: CARS_ACTIONS.GET_POSTS_ERROR,
  payload: {error},
});

export const getPostError = (error) => ({
  type: CARS_ACTIONS.GET_POST_ERROR,
  payload: {error},
});

export const likePostError = (error) => ({
  type: CARS_ACTIONS.GET_POSTS_ERROR,
  payload: {error},
});

export const sendView = (id) => ({
  type: CARS_ACTIONS.SEND_VIEW,
  payload: {id},
});

export const sendViewSuccess = (id) => ({
  type: CARS_ACTIONS.SEND_VIEW_SUCCESS,
  payload: {id},
});

export const deletePost = (id) => ({
  type: CARS_ACTIONS.DELETE_POST,
  payload: {id},
});

export const deletePostSuccess = (id) => ({
  type: CARS_ACTIONS.DELETE_POST_SUCCESS,
  payload: {id},
});

export const createReply = (body, id) => ({
  type: CARS_ACTIONS.CREATE_REPLY,
  payload: {body, id},
});

export const likeReply = (reply) => ({
  type: CARS_ACTIONS.LIKE_REPLY,
  payload: {reply},
})

export const deleteReply = (id,replyId) => ({
  type: CARS_ACTIONS.DELETE_REPLY,
  payload: {id, replyId},
});


export const reportPost = (reportable) => {
  return {
    type: CARS_ACTIONS.REPORT_POST,
    payload: {reportable},
  };
};

export const reportPostSuccess = (post) => {
  return {
    type: CARS_ACTIONS.REPORT_POST_SUCCESS,
    payload: {post},
  };
};

export const reportPostError = (error) => {
  return {
    type: CARS_ACTIONS.REPORT_POST_ERROR,
    payload: {error},
  };
};


export const reportReply = reportable => {
  return {
    type: CARS_ACTIONS.REPORT_REPLY,
    payload: { reportable }
  };
}

export const reportReplySuccess = reply => {
  return {
    type: CARS_ACTIONS.REPORT_REPLY_SUCCESS,
    payload: { reply }
  };
}

export const reportReplyError = error => {
  return {
    type: CARS_ACTIONS.REPORT_REPLY_ERROR,
    payload: { error }
  };
}

export const createReplySuccess = (reply) => ({
  type: CARS_ACTIONS.CREATE_REPLY_SUCCESS,
  payload: {reply},
});

export const likeReplySuccess = (reply, likes_count) => ({
  type: CARS_ACTIONS.LIKE_REPLY_SUCCESS,
  payload: {reply, likes_count},
})

export const deleteReplySuccess = (id, replyId) => ({
  type: CARS_ACTIONS.DELETE_REPLY_SUCCESS,
  payload: {id, replyId},
});

export const createReplyError = (error) => ({
  type: CARS_ACTIONS.CREATE_REPLY_ERROR,
  payload: {error},
});

export const likeReplyError = (error) => ({
  type: CARS_ACTIONS.LIKE_REPLY_ERROR,
  payload: {error},
})

export const deleteReplyError = (error) => ({
  type: CARS_ACTIONS.DELETE_REPLY_ERROR,
  payload: {error},
});

export const getUserPosts = (meta, id) => ({
  type: CARS_ACTIONS.GET_USER_POSTS,
  payload: {meta, id},
});

export const getUserPostsSuccess = (userPosts, total_pages) => ({
  type: CARS_ACTIONS.GET_USER_POSTS_SUCCESS,
  payload: {userPosts, total_pages}
});

export const getUserPostsError = (error) => ({
  type: CARS_ACTIONS.GET_USER_POSTS_ERROR,
  payload: {error}
})

export const getMyPosts = (meta) => ({
  type: CARS_ACTIONS.GET_MY_POSTS,
  payload: {meta},
});

export const getMotorCheckInfo = product_id => ({
  type: CARS_ACTIONS.GET_MOTOR_CHECK_INFO,
  payload: {product_id},
});

export const getMotorCheckInfoSuccess = motor_check_info => ({
  type: CARS_ACTIONS.GET_MOTOR_CHECK_INFO_SUCCESS,
  payload: {motor_check_info},
});

export const getMotorCheckInfoError = error => ({
  type: CARS_ACTIONS.GET_MOTOR_CHECK_INFO_ERROR,
  payload: {error},
});

export const toggleMotorCheckModal = isMotorCheckModalOpen => ({
  type: CARS_ACTIONS.TOGGLE_MOTOR_CHECK_MODAL,
  payload: {isMotorCheckModalOpen},
});
