import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { carsActions } from '@actions';
import { useParams, useHistory } from 'react-router';
import CarCard from '../Marketplace/CarCard';

const RelatedCars = () => {
  const { relatedCars, relatedCarsMeta } = useSelector(state => state.carsReducer)
  const { id } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(carsActions.getRelatedCars(relatedCarsMeta, id, true))
  }, [id])

  const redirect = (id) => {
    history.push(`/cars/${id}`)
  }

  return relatedCars?.length ? (
    <div className="related-cars">
        <div className="related-cars__header">
          <h2>More dealer cars</h2>
          {/* <h2>All dealer cars</h2> */}
          {/* <span>Watch all</span> */}
        </div>
        <div className="car-list--sm">
          { relatedCars?.map(car => <CarCard hideFollow onClick={() => redirect(car.id)} key={car.id} { ...car } />) }
        </div>
      </div>
  ) : null
}

export default RelatedCars
