import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { ROUTES } from './routes';

const NotConfirmedRoute = (props) => {
  const {user} = useSelector(({authReducer}) => authReducer);
  const isConfirmed = user ? user.user_type === 'manager' && user.confirmed_user : true;

  return (
    isConfirmed ? <Route {...props } /> : <Redirect to={ROUTES.CONFIRM_EMAIL} />
  )
}

export default NotConfirmedRoute
