import { PAYMENTS_ACTIONS } from '@constants';

export const buyTicket = (payment) => ({
  type: PAYMENTS_ACTIONS.BUY_TICKET,
  payload: {payment},
});

export const buyTicketSuccess = () => ({
  type: PAYMENTS_ACTIONS.BUY_TICKET_SUCCESS,
  payload: {payment: null},
});

export const buyTicketError = (error) => ({
  type: PAYMENTS_ACTIONS.BUY_TICKET_ERROR,
  payload: {error},
});

export const donate = (payment) => ({
  type: PAYMENTS_ACTIONS.DONATE,
  payload: {payment}
})

export const donateSuccess = () => ({
  type: PAYMENTS_ACTIONS.DONATE_SUCCESS,
  payload: {}
})

export const donateError = (error) => ({
  type: PAYMENTS_ACTIONS.DONATE_ERROR,
  payload: {error}
})

export const toggleDonateModal = (user) => ({
  type: PAYMENTS_ACTIONS.TOGGLE_DONATE_MODAL,
  payload: {user}
})

export const toggleIsProccessing = (proccessing) => ({
  type: PAYMENTS_ACTIONS.TOGGLE_IS_PROCCESSING,
  payload: {proccessing}
})

