import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { authActions } from '@actions'
import { useForm } from "react-hook-form";
import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import './Login.scss'
import Field from '@components/Field'
import { Link } from 'react-router-dom'
import { getToken } from '@services/LocalStorage';

const Login = () => {
  const { error } = useSelector(state => state.authReducer)
  const { handleSubmit, formState: { errors }, control } = useForm();
  const dispatch = useDispatch()

  const onSubmit = (data) => {
    dispatch(authActions.login(data.email, data.password, data.garage_code))
  }

  return (
    <div className="login">
      <div className="login-content">
        <h1>Log in to the account</h1>
        <form className="login__form" onSubmit={handleSubmit(onSubmit)}>
          
          <Field name="email" label="Email" type="email" control={control} />
          <Field name="garage_code" label="Garage code" control={control} />
          
          <Field 
            className="hide-extra-margin" 
            name="password" 
            label="Password" 
            type="password" 
            control={control}
            suffix={
              <Tooltip title="This field is case-sensitive. Be careful with uppercase ">
                <InfoCircleOutlined style={{ color: 'rgba(0,129,112, 0.8)' }} />
              </Tooltip>
            } 
          />

          <Button style={{ width: '100%', marginTop: '16px'}} size="large" type="primary" htmlType="submit">Login</Button>
          { error && <p style={{ textAlign: 'center', marginTop: '12px' }} className="error">{error}</p> }
          <Link to='/register'><p style={{ color: '#008170', fontSize: '16px', marginTop: '16px', textAlign: 'center', fontWeight: '500' }}>I don’t have an account.</p></Link>
          <Link to='/forgot-password'><p style={{ color: '#008170', fontSize: '16px', marginTop: '16px', textAlign: 'center', fontWeight: '500' }}>Forgot Password?</p></Link>
        </form>
      </div>
    </div>
  )
}

export default Login
