import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Tabs } from 'antd'

import MyTab from './MyTab'
import FollowingTab from './FollowingTab'
import PurchasedTab from './PurchasedTab'
import ArchivedTab from './ArchivedTab'

import {MY_CARS_TABS} from '../../redux/constants';

import {myCarsTabsActions} from '../../redux/actions';

const HomeTabs = () => {
  const dispatch = useDispatch();
  const {selectedTab} = useSelector(({myCarsTabsReducer}) => myCarsTabsReducer);

  const onChange = key => {
    dispatch(myCarsTabsActions.selectTab(key));
  };

  return (
    <Tabs style={{ width: '100%' }} defaultActiveKey={selectedTab} type="card" onChange={onChange}>
      <Tabs.TabPane tab="TRACKED" key={MY_CARS_TABS.tracked}>
        <FollowingTab />
      </Tabs.TabPane>
      <Tabs.TabPane tab="MY CARS" key={MY_CARS_TABS.cars}>
        <MyTab />
      </Tabs.TabPane>
      <Tabs.TabPane tab="MY PURCHASES" key={MY_CARS_TABS.purchases}>
        <PurchasedTab />
      </Tabs.TabPane>
      <Tabs.TabPane tab="ARCHIVE" key={MY_CARS_TABS.archive}>
        <ArchivedTab />
      </Tabs.TabPane>
    </Tabs>
  )
}

export default HomeTabs
