import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { carsActions } from '@actions'
import { Pagination } from 'antd';
import TabCard from './TabCard'
import { scrollToTheTop } from '@helpers'

import {MY_CARS_TABS} from '../../redux/constants';

const ArchivedTab = () => {
  const { archivedCars, archivedCarsMeta } = useSelector(state => state.carsReducer)
  const {selectedTab} = useSelector(({myCarsTabsReducer}) => myCarsTabsReducer);
  const dispatch = useDispatch()

  useEffect(() => {
    if (MY_CARS_TABS.archive === selectedTab)
      dispatch(carsActions.getArchivedCars(archivedCarsMeta, true))
  }, [selectedTab])

  const handlePageChange = (page) => {
    dispatch(carsActions.getArchivedCars({ ...archivedCarsMeta, page: page }, true))
    scrollToTheTop()
  }

  return (
    <div className="tab-cards">
      { archivedCars.length && archivedCars.map(car => {
          const { attributes: attr } = car
          return <TabCard 
                    key={car.id}
                    car={attr}
                  />
        })}

        { (archivedCarsMeta.total_pages &&  archivedCarsMeta.total_pages > 1) && <Pagination style={{ alignSelf: 'flex-start' }} className="home__pagination" onChange={handlePageChange} defaultCurrent={1} total={archivedCarsMeta.total_pages} pageSize={1} /> }
    </div>
  )
}

export default ArchivedTab
