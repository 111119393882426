import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { authActions } from '@actions'
import { useForm } from "react-hook-form";
import { Button } from 'antd'
import Field from '@components/Field'
import './ForgotPassword.scss'

const ForgotPassword = () => {
  const { error } = useSelector(state => state.authReducer)
  const { handleSubmit, control } = useForm();
  const dispatch = useDispatch()

  const onSubmit = (data) => {
    dispatch(authActions.forgotPassword(data.email));
  }

  return (
    <div className="login">
      <div className="login-content">
        <h1>Forgot Password</h1>
        <p>Enter your email, we will send you a link, you will follow it and be able to change your password</p>
        <form className="login__form" onSubmit={handleSubmit(onSubmit)}>
          
          <Field name="email" label="Email" type="email" control={control} />

          <Button style={{ width: '100%', marginTop: '16px'}} size="large" type="primary" htmlType="submit">SEND</Button>
          { error && <p style={{ textAlign: 'center', marginTop: '12px' }} className="error">{error}</p> }
        </form>
      </div>
    </div>
  )
}

export default ForgotPassword
