export default {
  GET_AUCTIONS: 'GET_AUCTIONS',
  GET_AUCTIONS_SUCCESS: 'GET_AUCTIONS_SUCCESS',
  GET_AUCTIONS_ERROR: 'GET_AUCTIONS_ERROR',
  CREATE_BUYER: 'CREATE_BUYER',
  CREATE_BUYER_SUCCESS: 'CREATE_BUYER_SUCCESS',
  CREATE_BUYER_ERROR: 'CREATE_BUYER_ERROR',
  GET_BUYERS: 'GET_BUYERS',
  GET_BUYERS_SUCCESS: 'GET_BUYERS_SUCCESS',
  GET_BUYERS_ERROR: 'GET_BUYERS_ERROR',
  PRE_BID: 'PRE_BID',
  PRE_BID_SUCCESS: 'PRE_BID_SUCCESS',
  PRE_BID_ERROR: 'PRE_BID_ERROR',
  UPDATE_BID: 'UPDATE_BID',
  UPDATE_CAR: 'UPDATE_CAR',
  UPDATE_CAR_SUCCESS: 'UPDATE_CAR_SUCCESS',
  UPDATE_CAR_ERROR: 'UPDATE_CAR_ERROR',
  WIN_BID: 'WIN_BID',
  ACCEPT_WIN: 'ACCEPT_WIN',
  ACCEPT_WIN_SUCCESS: 'ACCEPT_WIN_SUCCESS',
  ACCEPT_WIN_ERROR: 'ACCEPT_WIN_ERROR',
  REJECT_WIN: 'REJECT_WIN',
  REJECT_WIN_SUCCESS: 'REJECT_WIN_SUCCESS',
  REJECT_WIN_ERROR: 'REJECT_WIN_ERROR',
  GET_CARS : 'GET_CARS',
  GET_CARS_SUCCESS : 'GET_CARS_SUCCESS',
  GET_CARS_ERROR : 'GET_CARS_ERROR',
  GET_MY_CARS: 'GET_MY_CARS',
  GET_MY_CARS_SUCCESS: 'GET_MY_CARS_SUCCESS',
  GET_MY_CARS_ERROR: 'GET_MY_CARS_ERROR',
  GET_FOLLOWING_CARS: 'GET_FOLLOWING_CARS',
  GET_FOLLOWING_CARS_SUCCESS: 'GET_FOLLOWING_CARS_SUCCESS',
  GET_FOLLOWING_CARS_ERROR: 'GET_FOLLOWING_CARS_ERROR',
  GET_PURCHASED_CARS: 'GET_PURCHASED_CARS',
  GET_PURCHASED_CARS_SUCCESS: 'GET_PURCHASED_CARS_SUCCESS',
  GET_PURCHASED_CARS_ERROR: 'GET_PURCHASED_CARS_ERROR',
  GET_ARCHIVED_CARS: 'GET_ARCHIVED_CARS',
  GET_ARCHIVED_CARS_SUCCESS: 'GET_ARCHIVED_CARS_SUCCESS',
  GET_ARCHIVED_CARS_ERROR: 'GET_ARCHIVED_CARS_ERROR',
  GET_RELATED_CARS: 'GET_RELATED_CARS',
  GET_RELATED_CARS_SUCCESS: 'GET_RELATED_CARS_SUCCESS',
  GET_RELATED_CARS_ERROR: 'GET_RELATED_CARS_ERROR',
  CLEAN_CAR_INFO: 'CLEAN_CAR_INFO',
  GET_CAR_INFO: 'GET_CAR_INFO',
  GET_CAR_INFO_SUCCESS : 'GET_CAR_INFO_SUCCESS',
  GET_CAR_INFO_ERROR : 'GET_CAR_INFO_ERROR',
  CREATE_CAR: 'CREATE_CAR',
  CREATE_CAR_SUCCESS : 'CREATE_CAR_SUCCESS',
  CREATE_CAR_ERROR : 'CREATE_CAR_ERROR',
  ADD_CAR_SERVICE_HISTORY: 'ADD_CAR_SERVICE_HISTORY',
  ADD_CAR_SERVICE_HISTORY_SUCCESS: 'ADD_CAR_SERVICE_HISTORY_SUCCESS',
  ADD_CAR_SERVICE_HISTORY_ERROR: 'ADD_CAR_SERVICE_HISTORY_ERROR',
  ADD_CAR_PHOTO: 'ADD_CAR_PHOTO',
  ADD_CAR_PHOTO_SUCCESS: 'ADD_CAR_PHOTO_SUCCESS',
  ADD_CAR_PHOTO_ERROR: 'ADD_CAR_PHOTO_ERROR',
  GET_ATTACHMENTS: 'GET_ATTACHMENTS',
  GET_ATTACHMENTS_SUCCESS: 'GET_ATTACHMENTS_SUCCESS',
  GET_ATTACHMENTS_ERROR: 'GET_ATTACHMENTS_ERROR',
  REMOVE_ATTACHMENTS: 'REMOVE_ATTACHMENTS',
  REMOVE_ATTACHMENTS_SUCCESS: 'REMOVE_ATTACHMENTS_SUCCESS',
  REMOVE_ATTACHMENTS_ERROR: 'REMOVE_ATTACHMENTS_ERROR',
  GET_CLUTCH: 'GET_CLUTCH',
  GET_CLUTCH_SUCCESS: 'GET_CLUTCH_SUCCESS',
  GET_CLUTCH_ERROR: 'GET_CLUTCH_ERROR',
  GET_TYRES: 'GET_TYRES',
  GET_TYRES_SUCCESS: 'GET_TYRES_SUCCESS',
  GET_TYRES_ERROR: 'GET_TYRES_ERROR',
  GET_DAMAGE: 'GET_DAMAGE',
  GET_DAMAGE_SUCCESS: 'GET_DAMAGE_SUCCESS',
  GET_DAMAGE_ERROR: 'GET_DAMAGE_ERROR',
  GET_HISTORY_TYPES: 'GET_HISTORY_TYPES',
  GET_HISTORY_TYPES_SUCCESS: 'GET_HISTORY_TYPES_SUCCESS',
  GET_HISTORY_TYPES_ERROR: 'GET_HISTORY_TYPES_ERROR',
  GET_BODY_TYPES: 'GET_BODY_TYPES',
  GET_BODY_TYPES_SUCCESS: 'GET_BODY_TYPES_SUCCESS',
  GET_BODY_TYPES_ERROR: 'GET_BODY_TYPES_ERROR',
  GET_FUEL_TYPES: 'GET_FUEL_TYPES',
  GET_FUEL_TYPES_SUCCESS: 'GET_FUEL_TYPES_SUCCESS',
  GET_FUEL_TYPES_ERROR: 'GET_FUEL_TYPES_ERROR',
  GET_TRANSMITION_TYPES: 'GET_TRANSMITION_TYPES',
  GET_TRANSMITION_TYPES_SUCCESS: 'GET_TRANSMITION_TYPES_SUCCESS',
  GET_TRANSMITION_TYPES_ERROR: 'GET_TRANSMITION_TYPES_ERROR',
  GET_COLORS: 'GET_COLORS',
  GET_COLORS_SUCCESS: 'GET_COLORS_SUCCESS',
  GET_COLORS_ERROR: 'GET_COLORS_ERROR',
  CREATE_ZONE: 'CREATE_ZONE',
  CREATE_ZONE_SUCCESS: 'CREATE_ZONE_SUCCESS',
  CREATE_ZONE_ERROR: 'CREATE_ZONE_ERROR',
  ADD_CAR_SUMMARY: 'ADD_CAR_SUMMARY',
  ADD_CAR_SUMMARY_SUCCESS: 'ADD_CAR_SUMMARY_SUCCESS',
  ADD_CAR_SUMMARY_ERROR: 'ADD_CAR_SUMMARY_ERROR',
  RELEASE_CAR: 'RELEASE_CAR',
  RELEASE_CAR_SUCCESS: 'RELEASE_CAR_SUCCESS',
  RELEASE_CAR_ERROR: 'RELEASE_CAR_ERROR',
  GET_INVOICE: 'GET_INVOICE',
  GET_INVOICE_SUCCESS: 'GET_INVOICE_SUCCESS',
  GET_INVOICE_ERROR: 'GET_INVOICE_ERROR',
  GET_FEE_INVOICE: 'GET_FEE_INVOICE',
  GET_FEE_INVOICE_SUCCESS: 'GET_FEE_INVOICE_SUCCESS',
  GET_FEE_INVOICE_ERROR: 'GET_FEE_INVOICE_ERROR',
  ADD_STEP: 'ADD_STEP',
  SET_STEP: 'SET_STEP',
  GET_POST : 'GET_POST',
  GET_POST_SUCCESS : 'GET_POST_SUCCESS',
  GET_POST_ERROR : 'GET_POST_ERROR',
  GET_USER_CARS : 'GET_USER_CARS',
  GET_USER_CARS_SUCCESS: 'GET_USER_CARS_SUCCESS',
  GET_USER_CARS_ERROR : 'GET_USER_CARS_ERROR',
  FOLLOW_SUCCESS : 'FOLLOW_SUCCESS',
  UNFOLLOW_SUCCESS : 'UNFOLLOW_SUCCESS',
  LIKE_POST_SUCCESS : 'LIKE_POST_SUCCESS',
  LIKE_POST_ERROR : 'LIKE_POST_ERROR',
  LIKE_POST : 'LIKE_POST',
  REPORT_POST : 'REPORT_POST',
  REPORT_POST_SUCCESS : 'REPORT_POST_SUCCESS',
  REPORT_POST_ERROR : 'REPORT_POST_ERROR',
  SEND_VIEW : 'SEND_VIEW',
  SEND_VIEW_SUCCESS : 'SEND_VIEW_SUCCESS',
  DELETE_POST : 'DELETE_POST',
  DELETE_POST_SUCCESS : 'DELETE_POST_SUCCESS',
  CREATE_REPLY : 'CREATE_REPLY',
  CREATE_REPLY_SUCCESS : 'CREATE_REPLY_SUCCESS',
  CREATE_REPLY_ERROR : 'CREATE_REPLY_ERROR',
  LIKE_REPLY: 'LIKE_REPLY',
  LIKE_REPLY_SUCCESS : 'LIKE_REPLY_SUCCESS',
  LIKE_REPLY_ERROR : 'LIKE_REPLY_ERROR',
  DELETE_REPLY : 'DELETE_REPLY',
  DELETE_REPLY_SUCCESS : 'DELETE_REPLY_SUCCESS',
  DELETE_REPLY_ERROR : 'DELETE_REPLY_ERROR',
  REPORT_REPLY : 'REPORT_REPLY',
  REPORT_REPLY_SUCCESS : 'REPORT_REPLY_SUCCESS',
  REPORT_REPLY_ERROR : 'REPORT_REPLY_ERROR',
  BLOCK_SUCCESS : 'BLOCK_SUCCESS',
  GET_USER_CARS : 'GET_USER_CARS',
  GET_MY_CARS : 'GET_MY_CARS',
  DELETE_CAR: 'DELETE_CAR',
  DELETE_CAR_SUCCESS: 'DELETE_CAR_SUCCESS',
  DELETE_CAR_ERROR: 'DELETE_CAR_ERROR', 
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  GET_MOTOR_CHECK_INFO: 'GET_MOTOR_CHECK_INFO',
  GET_MOTOR_CHECK_INFO_SUCCESS: 'GET_MOTOR_CHECK_INFO_SUCCESS',
  GET_MOTOR_CHECK_INFO_ERROR: 'GET_MOTOR_CHECK_INFO_ERROR',
  TOGGLE_MOTOR_CHECK_MODAL: 'TOGGLE_MOTOR_CHECK_MODAL',
}