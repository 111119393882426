import {NOTIFICATIONS_ACTIONS, LOCAL_STORAGE} from '@constants';
import {notificationsActions, authActions} from '@actions';
import {processRequest} from '@services/Api';
import { put, call, takeEvery } from 'redux-saga/effects'

function* handleGetNotifications(action) {
  try {
    const {payload} = action || {};
    const {meta} = payload || {};
    const {page, per_page} = meta || {};
    const getNotificationsSuccess = page === 1 ? notificationsActions.getNotificationsSuccess : notificationsActions.getNotificationsNextPageSuccess;
    const {data} = yield call(processRequest, `/feeds?page=${page}&per_page=${per_page}`, 'GET');
    if (data.feeds) {
      const notifications = data.feeds.data.map(notification => notification.attributes);
      yield put(getNotificationsSuccess(notifications));
    } else {
      yield put(notificationsActions.getNotificationsError(data));
    }
  } catch (e) {
    const {data, status, statusText} = e || {};
    const {error_messages, error, error_message} = data || {};

    if (status === 400) {
      let message = '';
      if (error_message) message = error_message;
      else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];

        message = error_messages && `${keys[0]} ${errorMessage}`;
      }

      yield put(notificationsActions.getNotificationsError(message));
    } else if (status === 401) {
      yield put(authActions.forceLogout());
    } else if (status === 500) {
      yield put(notificationsActions.getNotificationsError(statusText));
    } else if (e.message) {
      yield put(notificationsActions.getNotificationsError(e.message));
    } else {
      yield put(notificationsActions.getNotificationsError('Internal server error.'));
    }
  }
}

function* handleRemovetNotifications(action) {
  try {
    const {payload} = action || {};
    const {id} = payload || {};
    const {data} = yield call(processRequest, `/feeds/${id}`, 'DELETE');
    if (data.message === 'Success') {
      yield put(notificationsActions.removeNotificationSuccess(id));
    } else {
      yield put(notificationsActions.removeNotificationError(data));
    }
  } catch (e) {
    const {data, status, statusText} = e || {};
    const {error_messages, error, error_message} = data || {};

    if (status === 400) {
      let message = '';
      if (error_message) message = error_message;
      else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];

        message = error_messages && `${keys[0]} ${errorMessage}`;
      }

      yield put(notificationsActions.removeNotificationError(message));
    } else if (status === 401) {
      yield put(authActions.forceLogout());
    } else if (status === 500) {
      yield put(notificationsActions.removeNotificationError(statusText));
    } else if (e.message) {
      yield put(notificationsActions.removeNotificationError(e.message));
    } else {
      yield put(notificationsActions.removeNotificationError('Internal server error.'));
    }
  }
}

export function* watchNotificationsSagas() {
  yield takeEvery(NOTIFICATIONS_ACTIONS.GET_NOTIFICATIONS, handleGetNotifications);
  yield takeEvery(NOTIFICATIONS_ACTIONS.REMOVE_NOTIFICATION, handleRemovetNotifications);
}
