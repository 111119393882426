import React from 'react'
import Routes from './router';
import './App.less'
import './App.scss'

import { Provider } from 'react-redux'
import { store } from '@redux/store'
import { ConnectedRouter } from 'connected-react-router'
import { appHistory } from '@services/HistoryConfig'

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { config } from '@config'

function App() {
  const stripePromise = loadStripe(config.STRIPE_API_KEY);

  return (
    <Provider store={store}>
      <ConnectedRouter history={appHistory}>
        <Elements stripe={stripePromise}>
          <Routes />
        </Elements>
      </ConnectedRouter>
    </Provider>
  )
}

export default App;
