import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { globalActions } from '../redux/actions';
import { push } from 'connected-react-router';
import { Switch, Route, Redirect} from "react-router-dom";
import { ROUTES } from './routes';
import PrivateRoute from './PrivateRoute';
import NotConfirmedRoute from './NotConfirmedRoute';

import PageWrapper from '@components/Markup/PageWrapper'
import MarketplacePage from '@pages/MarketplacePage';
import AuctionsPage from '@pages/AuctionsPage';
import LandingPage from '@pages/LandingPage';
import CarDetailPage from '@pages/CarDetailPage';
import SellCarPage from '@pages/SellCarPage';
import HomePage from '@pages/HomePage';
import NotificationsPage from '@pages/NotificationsPage';
import LoginPage from '@pages/LoginPage';
import RegisterPage from '@pages/RegisterPage';
import RegisterSuccessPage from '@pages/RegisterSuccessPage';
import CreateProfilePage from '@pages/CreateProfilePage'
import ContactUsPage from '@pages/ContactUsPage'
import HowItWorksPage from '@pages/HowItWorksPage';
import FaqPage from '@pages/FaqPage';
import EditCarPage from '@pages/EditCarPage';
import JoinTheGaragePage from '@pages/JoinTheGaragePage';
import EditProfilePage from '@pages/EditProfilePage';
import ForgotPasswordPage from '@pages/ForgotPasswordPage';
import ChangePasswordPage from '@pages/ChangePasswordPage';
import TermsPage from '@pages/TermsPage';
import PrivacyPolicyPage from '@pages/PrivacyPolicyPage';
import AboutUsPage from '@pages/AboutUsPage';
import UserConfirmationPage from '@pages/UserConfirmationPage';
import ConfirmEmailPage from '@pages/ConfirmEmailPage';

const Routes = () => {
	const dispatch = useDispatch();

	const getTimeFromNet = async () => {
		const time = [
			new Date((await fetch("https://worldtimeapi.org/api/ip").then(res => res.json())).datetime),
			new Date()
		]
		dispatch(globalActions.setTime(time))
	}
	useEffect(() => {

		getTimeFromNet()
	}, []);
  return (
    <>
        <PageWrapper>
          <Switch>
            <PrivateRoute exact path={ROUTES.HOME} component={HomePage} />
            <PrivateRoute exact path={ROUTES.AUCTIONS} component={AuctionsPage} />
            <PrivateRoute exact path={ROUTES.CARS} component={CarDetailPage} />
            <PrivateRoute exact path={ROUTES.SELL} component={SellCarPage} />
            <PrivateRoute exact path={ROUTES.NOTIFICATIONS} component={NotificationsPage} />
            <PrivateRoute exact path={ROUTES.EDIT_CAR} component={EditCarPage} />
            <PrivateRoute exact path={ROUTES.EDIT_PROFILE} component={EditProfilePage} />
            <PrivateRoute exact path={ROUTES.REGISTER_SUCCESS} component={RegisterSuccessPage} />
            <Route exact path={`${ROUTES.CHANGE_PASSWORD}/:pToken`} component={ChangePasswordPage} />
            <Route exact path={ROUTES.MARKETPLACE} component={MarketplacePage} />
            <Route exact path={ROUTES.LOGIN} component={LoginPage} />
            <NotConfirmedRoute exact path={ROUTES.REGISTER} component={RegisterPage} />
            <Route exact path={ROUTES.CREATE_PROFILE} component={CreateProfilePage} />
            <Route exact path={ROUTES.CONTACT} component={ContactUsPage}/>
            <Route exact path={ROUTES.HOW_IT_WORKS} component={HowItWorksPage}/>
            <Route exact path={ROUTES.ABOUT_US} component={AboutUsPage}/>
            <Route exact path={ROUTES.LANDING} component={LandingPage}/>
            <Route exact path={ROUTES.FAQ} component={FaqPage} />
            <Route exact path={ROUTES.LANDING} component={LandingPage} />
            <Route exact path={ROUTES.JOIN_THE_GARAGE} component={JoinTheGaragePage} />
            <Route exact path={ROUTES.FORGOT_PASSWORD} component={ForgotPasswordPage} />
            <Route exact path={ROUTES.TERMS} component={TermsPage} />
            <Route exact path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicyPage} />
            <Route exact path={ROUTES.USER_CONFIRMATION} component={UserConfirmationPage} />
            <Route exact path={ROUTES.CONFIRM_EMAIL} component={ConfirmEmailPage} />
            <Redirect to={ROUTES.HOME} />
          </Switch>
        </PageWrapper>
      </>
  )
}

export default Routes
