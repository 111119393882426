import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { carsActions } from '@actions'
import HomeTabs from './HomeTabs';
import UserInfo from './UserInfo';
import './Home.scss'

const Home = () => {
  const { myCarsMeta } = useSelector(state => state.carsReducer)
  const dispatch = useDispatch()

  // useEffect(() => {
  //   dispatch(carsActions.getMyCars(myCarsMeta))
  // }, [])

  const handlePageChange = (page) => {
    dispatch(carsActions.getMyCars({ ...myCarsMeta, page: page }, true))
  }

  return (
    <div className="home">
      <UserInfo />
      <div className="home__content">
        <div className="home__tabs styled-tabs">
        <HomeTabs />
      </div>
      {/* { myCarsMeta.total_pages && <Pagination className="home__pagination" onChange={handlePageChange} defaultCurrent={1} total={myCarsMeta.total_pages} pageSize={1} /> } */}
      </div>
    </div>
  )
}

export default Home
