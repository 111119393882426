import {NOTIFICATIONS_ACTIONS, INITIAL_STATE} from '../constants';

const notificationsReducer = (state = INITIAL_STATE.NOTIFICATIONS_STORE, action) => {
  const {type, payload} = action || {};
  const {meta, notifications, id, error} = payload || {};

  switch(type) {
    case NOTIFICATIONS_ACTIONS.GET_NOTIFICATIONS:
      return {
        ...state,
        meta,
      };
    case NOTIFICATIONS_ACTIONS.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications,
      };
    case NOTIFICATIONS_ACTIONS.GET_NOTIFICATIONS_NEXT_PAGE_SUCCESS:
      return {
        ...state,
        notifications: [...state.notifications, ...notifications],
      };
    case NOTIFICATIONS_ACTIONS.REMOVE_NOTIFICATION_SUCCESS: {
      const allNotifications = [...state.notifications];
      const removeIndex = allNotifications.map(notification => notification.id).indexOf(id);
      allNotifications.splice(removeIndex, 1);
      return {
        ...state,
        notifications: allNotifications,
      };
    }
    case NOTIFICATIONS_ACTIONS.GET_NOTIFICATIONS_ERROR:
    case NOTIFICATIONS_ACTIONS.REMOVE_NOTIFICATION_ERROR:
      return {
        ...state,
        error,
      };
    default:
      return state;
  }
};

export {notificationsReducer};
