import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { carsActions } from '@actions'
import AuctionCard from './AuctionsCard'
import InfiniteScroll from 'react-infinite-scroll-component';
import './Auctions.scss'

const Auctions = () => {
  const { auctionsMeta, total_sales, auctions } = useSelector(state => state.carsReducer)
  const dispatch = useDispatch()

  useEffect(() => {
    if(auctionsMeta.page == 1) {
      dispatch(carsActions.getAuctions(auctionsMeta, true))
    }
  }, [])

  const getMoreAuctions = () => {
    dispatch(carsActions.getAuctions({ ...auctionsMeta, page: auctionsMeta.page + 1 }))
  }

  console.log(auctionsMeta?.page < auctionsMeta?.total_pages)

  return (
    <div className="auctions-page">
      <h1>Today's Cars</h1>
      <InfiniteScroll 
          next={getMoreAuctions}
          hasMore={auctionsMeta?.page < auctionsMeta?.total_pages} 
          // className="post__scroller" 
          dataLength={auctions?.length} >
            { auctions?.map(auction => { 
            const car = auction?.product?.data?.attributes
              return <AuctionCard {...auction} {...car} />
            }) }
          </InfiniteScroll>
    </div>
  )
}

export default Auctions
