import React from 'react'
import './Terms.scss'

const Terms = () => {
  return (
    <div className="terms">
      <h2 class="p1"><strong>Terms and Conditions</strong></h2>
      <p class="p2">&nbsp;</p>
      <p class="p3"><span class="s1"><strong>MotoBids.ie</strong></span></p>
      <p class="p4">&nbsp;</p>
      <p>The terms and conditions set out in this document (the &ldquo;<strong>Terms and Conditions</strong>&rdquo;) create a binding legal agreement between you, your business, your representatives (collectively, &ldquo;<strong>you</strong>&rdquo; &ldquo;<strong>your</strong>&rdquo; or &ldquo;<strong>Customer</strong>&rdquo;) and MotoBids Limited, trading as MotoBids (&ldquo;<strong>we</strong>&rdquo;, &ldquo;<strong>our</strong>&rdquo; or &ldquo;<strong>MotoBids</strong>&rdquo;).</p>
			<p>The Terms and Conditions govern your access our System and services and are implied terms and conditions of every agreement that you enter into with us and with every bidder, buyer and seller of vehicles using our System or services. We may amend these Terms and Conditions at any time by posting the amendments on our website at <a href="http://www.MotoBids.ie/">www.MotoBids.ie</a>. You will be notified of any changes to the Terms and Conditions when you log on to our System. By using or accessing our System, you agree to be bound by the then current Terms and Conditions.</p>
			<p>&nbsp;</p>
      <p><strong>GENERAL&nbsp;TERMS</strong></p>
			<ol>
				<li><em>We</em> provide a live bidding online auction service for licensed motor traders/garages to list, sell, bid on and buy motor vehicles (&ldquo;<strong>Vehicles</strong>&rdquo;). By participating in the Auction or accessing our System you agree to be bound by these Terms and Conditions as may be amended on the MotoBids.ie website from time to time. Breach of these Terms and Conditions may result in the temporary or permanent suspension of your access to our System.</li>
				<li><strong><em>Definitions</em></strong>. In addition to terms defined elsewhere in these Terms and Conditions, the following definitions apply:</li>
			</ol>
			<p><strong>&ldquo;<em>Instant&nbsp;Pay</em>&rdquo;</strong> means the arrangement whereby the &ldquo;Buyer&rdquo; pays the &ldquo;Seller&rdquo; bank account by close of business on the &ldquo;Purchase Day&rdquo; and emails a copy of the electronic fund transfer (EFT) to MotoBids.ie.&nbsp;</p>
			<p><strong>&ldquo;Purchase Day&rdquo;</strong> means the day of the Auction whereby a &ldquo;Buyer&rdquo; has successfully won the bidding on the vehicle.</p>
			<p><strong>&ldquo;<em>Authorised User</em>&rdquo;</strong> means the individuals designated by you to list, bid on and/or buy Vehicles on your behalf via the MotoBids.ie System.</p>
			<p><strong>&ldquo;<em>Buyer</em>&rdquo;</strong> means the Customer who purchases a Vehicle from a &ldquo;Seller&rdquo; via the MotoBids.ie System.</p>
			<p><strong>&ldquo;<em>Seller</em>&rdquo;</strong> means the Customer who lists a Vehicle for sale via the MotoBids.ie System.</p>
			<p><strong>&ldquo;<em>Web Information</em>&rdquo;</strong> means all information and materials displayed on or accessible via links from the MotoBids websites including, without limitation.</p>
			<ol>
				<li><em>&ldquo;</em><strong><em>Buyer&rdquo;</em></strong><em>&nbsp;Eligibility</em>. Our Auction sales are intended for professional Motor Trade professionals only. You represent and warrant that you are a registered retail or wholesale dealer of Vehicle(s) and/or a business that is oth<em>erwise legally&nbsp;eligible to purchase or sell Vehicles at wholesale auto auctions. To register as a &ldquo;Buyer&rdquo; you must have a Garage Code. You agree that these Terms and Conditions shall apply to all transactions conducted by you and your Authorised Users on our System. We reserve the right to refuse access to our System or any service or feature provided by us to anyone who breaches these Terms and Conditions or otherwise interferes with or violates our rights or the rights of others.</em></li>
				<li><strong><em>Reserve Auction</em>.</strong> Unless otherwise indicated, all Vehicles offered for sale are subject to a reserve price. If bidding does not reach the reserve price, the Seller may elect to decline all bids and/or withdraw the Vehicle listing from our System.</li>
				<li><strong><em>Registration</em>.</strong> MotoBids.ie reserves the right to restrict Customer&rsquo;s access to our System and to withhold access for Customers that have not completed our registration. You and your Authorised Users will be issue with usernames and passwords to access our System. You are liable and responsible for all actions, omissions and any failure to act of your Authorised Users. You represent and warrant that all information that you provide to us is accurate and complete and that you will notify us in writing of any changes to the information provided. You agree not to give anyone access to our System other than your Authorised Users.</li>
				<li><strong><em>Data</em>.</strong> You agree that all information and records, whether oral, written, visual, electronic, digital or tangible transmitted, uploaded, received or stored on our System or using the systems, equipment, computers, servers, or premises of MotoBids, including without limitation, vehicle condition reports, photographic images and videos (collectively &ldquo;<strong>Data</strong>&rdquo;) is the property of MotoBids. You represent and warrant that you own all r<em>ight, ownership</em> and interest in Data which you upload to our System and you assign to MotoBids all such righ<em>t, ownership</em> and interest including, without limitation, all related copyright, moral right and other intellectual property rights. You agree that such intellectual property rights include our right to receive, use, disseminate, control access to, aggregate, modify, package, derive benefit from, remove, destroy or sell Data in whole or in part. Notwithstanding the foregoing, Data that contains personally identifiable information will only be used and disclosed in accordance with our Privacy Policy.</li>
				<li><strong><em>Ownership</em></strong>. You acknowledge and agree that our System, related services, Data and Web Information (as defined below), including all copyright and rights to patent, industrial design and trade mark protection, trade secrets and all other intellectual property rights (collectively, the &ldquo;<strong>MotoBids IP</strong>&rdquo;) are owned by MotoBids or its licensors and are protected by Irish and international intellectual property laws and treaties. Subject to the limited rights of access expressly granted hereunder, we reserve all rights, title and interest in and to the MotoBids IP. No rights are granted to you other than the limited rights expressly set forth in these Terms and Conditions. You acknowledge&nbsp;and&nbsp;agree&nbsp;that&nbsp;you&nbsp;do&nbsp;not&nbsp;own,&nbsp;and shall not acquire, any right, title or interest in the MotoBids IP and that, if necessary, you agree to perform any acts that may be reasonably necessary to transfer or confirm ownership of any right, title, and interest in or to the MotoBids IP.</li>
				<li><strong><em>Licenses</em>.</strong> It is your responsibility to acquire, provide and maintain at your own expense licenses for all software, hardware, equipment, connections, facilities, services and other supplies necessary for you to access and use our System.</li>
				<li><strong><em>Permitted Use</em>.</strong> You agree that you will use the our System solely for your internal business purposes and will not: (i) license, sublicense, sell, resell, rent, lease, transfer, assign, distribute, time share or otherwise commercially exploit or make our System available to any third party, other than to your Authorised Users or as otherwise expressly permitted by these Terms and Conditions; (ii) send spam or otherwise duplicative or unsolicited messages in violation of applicable laws; (iii) send or store infringing, obscene, threatening, libelous, or otherwise unlawful or tortious material, including material that violates third party privacy rights; (iv) send or store malicious code or viruses; <em>(v) interfere with or disrupt the integrity or performance of our System or the Data contained therein; or (vi) attempt to gain unauthorised access to our System or its related systems or networks. You further agree that you will not (i) modify, copy or create derivative works based on our System ; (ii) frame or mirror any content forming part of our System; (iii) reverse engineer or decompile our System ; (iv) access our System in order to (A) build a competitive product or service, or (B) copy any ideas, features, functions or graphics of our System ; or (v) permit or authorise any third party to do any of the foregoing. You agree that all Data transmitted by you through our System will be free from malicious software, including viruses, worms, Trojans, software that bypasses normal authentication mechanisms or exploits or attacks software security or&nbsp;any software or data designed to disable, modify or damage our System. You will not use any device, software or routine to interfere or attempt to interfere with the proper working of our System. You will not take any action that imposes an unreasonably or disproportionately large load on our information technology infrastructure.</em></li>
				<li><strong><em>Sharing of Username/Password</em></strong>. Sharing or lending of usernames or passwords is strictly prohibited. You are responsible for maintaining the confidentiality and security of the usernames and passwords issued to you and your Authorised Users. You will be liable and responsible for all actions, omissions, failures to act, and transactions conducted with the username and password issued to you or any of your Authorised Users. You will immediately notify us in writing of any unauthorised use of a username and password.</li>
				<li><strong><em>Compliance</em>.</strong> You represent and warrant to us that: (a) you and your Authorised Representatives are in compliance with and shall comply with all laws and regulations that apply to your business; and (b) you and your Authorised Representatives have secured and will maintain all permits, licenses and governmental approvals required to operate your business including, without limitation, as required to access and participate in our System. We reserve the right to temporarily or permanently suspend your access rights and/or the access rights of any of your Authorised Users at any time. Breach of these Terms and Conditions may result in the permanent or temporary suspension of your right to access our System.</li>
				<li><strong><em>Electronic Signatures</em></strong>. You will permit MotoBids to capture your signature in electronic or digital form. You hereby authorise us to apply your electronic signature to documents necessary or incidental to your use of our services, including, without limitation bills of sale, odometer disclosure statements, invoices, acknowledgements, approvals, and title documents. You agree that your electronic signature is intended to authenticate the document to which it is applied and shall have the same force and effect as a manual signature.</li>
				<li><strong><em>Motobids Role</em></strong>. We provide an online auction service. We do not own the Vehicles listed for sale via our System. We are neither the Buyer nor the Seller of the Vehicles listed for sale. We provide software services to facilitate the exchange of Vehicles between Buyers and Sellers. We do not guarantee the accuracy of information regarding Vehicles listed for sale via the our System. As a sophisticated commercial&nbsp;party,&nbsp;you&nbsp;are&nbsp;expected&nbsp;to&nbsp;satisfy&nbsp;yourself&nbsp;regarding the condition and value of the Vehicle and the terms of sale before bidding. We are not a party to the Vehicle sale contract, but are a third party beneficiary of the contract, entitled to the parties&rsquo; performance and to seek legal remedies for the parties&rsquo; breach. You agree that any legal claims arising out of a transaction will be between you and the other party (either Buyer or Seller) in the transaction and not against MotoBids. We make no representation, warranty or guarantee as to the accuracy or completeness of the appraisal, description, equipment, warranty, service policy, title, history, odometer reading or disclosures pertaining to Vehicles offered for sale via our System.</li>
				<li><strong><em>Fees</em>.&nbsp;</strong>In consideration of access to our System, you agree to pay MotoBids all fees and charges assessed by us&nbsp;for access to our System (collectively, the &ldquo;<strong>Fees</strong>&rdquo;). Fees are subject to change without notice.&nbsp;</li>
			</ol>
			<p>&nbsp;</p>
			<p><strong>SALE&nbsp;PROCESS</strong></p>
			<p>All Vehicles listed for sale on our System are used vehicles. Most used vehicles have defects, flaws and/or wear and tear. Please allow for this in your bidding. Buyers should review all Vehicle information made available to them before bidding including appraisals, photographs, videos, Seller&rsquo;s notes, and Vehicle History&nbsp;Reports. It is the Buyer&rsquo;s responsibility to verify the condition and history of the Vehicle within the applicable arbitration period.</p>
			<p><em>MotoBids.ie</em> assumes no liability for loss or damage to Vehicles listed on our System. We do not provide insurance cover for vehicles.</p>
			<p><strong><em>Collection:</em></strong><em>&nbsp;all Vehicles sold through our System, can be collected from the &ldquo;Seller&rdquo; once cleared funds have been received. Do not attempt to collect a car until you have agreement to collect. Liaise with the nominated contact at the &ldquo;Seller&rdquo; Dealership to agree time for collection. This ensures that you get you&rsquo;re the auctioned vehicle at the earliest opportunity.</em></p>
			<p>Risk of loss for a Vehicle remains with the &ldquo;Seller&rdquo; until the &ldquo;Buyer&rdquo; picks up the Vehicle from the&nbsp;Seller&rsquo;s&nbsp;location,&nbsp;at&nbsp;which&nbsp;point&nbsp;the&nbsp;risk of loss for the Vehicle is transferred to the Buyer.</p>
			<p><strong><em>Vehicle History Reports</em>.</strong> Vehicle history reports from an accepted industry source regarding the history and condition of a vehicle (&ldquo;<strong>Vehicle History Reports</strong>&rdquo;) are required on all Vehicles listed for sale on our System. We are not responsible for the accuracy of Vehicle History Reports.&nbsp;</p>
			<p>&nbsp;<strong><em>Void/Cancelled Auction</em>:&nbsp;</strong>In the event of a website crash/outage (a total loss of service), the live auctions and associated bids at the time of the outage will be voided and rescheduled for the next available auction.</p>
			<p><strong>SELLER&nbsp;OBLIGATIONS</strong></p>
			<p><strong><em>Seller Responsibilities</em></strong>. A Seller will build confidence in its product and credibility with Buyers by disclosing all material facts about the history and condition of Vehicles offered for sale. It is Seller&rsquo;s obligation to fairly represent its Vehicle and to correct any errors made regarding disclosed conditions. Seller is responsible for the accuracy and completeness of all disclosures regardless of whether Seller has relied on third party resources (e.g. vehicle listing service, electronic data vehicle history report, registration lookup, etc.).</p>
			<p><strong><em>Online Photographs and Video</em></strong>. Photographs and video must accurately and fairly represent the condition of the Vehicle. Photographs/video of damage or other conditions that could materially affect the value of a Vehicle should be provided. This helps ensure a smooth transaction and limits complaints regarding vehicle condition.</p>
			<p><strong><em>Disclosures</em></strong>. A Seller who lists a Vehicle for sale on our System is required to disclose any faults or information that would depreciate the vehicle when filling in their auction display ad. In so doing, again this limits any complaints after the sale. If the Seller fails to disclose items that are deemed depreciable to the vehicles trade valuation, then this will reflect in our Arbitration process. As these vehicles are Trade Sales, very minor items are not necessarily noteworthy, however items that will cost the Buyer to have the vehicle at a standard that is acceptable for sale, must be disclosed.</p>
			<p><strong><em>Vehicle Sales.</em></strong></p>
			<ol>
				<ol>
					<li><em>Vehicles that are put into our system for Auction, must at all times be under the lawful possession, custody and control of the Seller.</em></li>
					<li>Seller shall not release a Vehicle to the Buyer or its representative until the Buyer has paid the Seller for the Vehicle and cleared funds are in the Sellers bank. We do not guarantee payment from the Buyer. The Seller will be liable for all Losses due to the unauthorised or premature release of a Vehicle.&nbsp;</li>
					<li>We reserve the right to remove a Vehicle posted on our System at any time. &nbsp;</li>
				</ol>
			</ol>
			<p><br /></p>
			<p><em>ARBITRATION&nbsp;POLICY</em></p>
			<p><strong><em>Arbitration Policies</em></strong>. We offer an arbitration service to resolve disputes between Buyers and Sellers. Our&nbsp;arbitration&nbsp;service&nbsp;is&nbsp;governed by the terms and conditions (collectively, the &ldquo;<strong>MotoBids Arbitration Policy</strong>&rdquo;). The arbitration process is designed to be fair, impartial, quick, and economical.</p>
			<p><strong><em>Acknowledgement</em>.</strong> By accessing our System or otherwise using our services, you acknowledge and agree that any dispute arising due to the purchase or sale of a Vehicle will be resolved in accordance with the MotoBids Arbitration Policy. You hereby appoint MotoBids to serve as Arbitrator and empower it to render a final, binding decision in settlement of all Claims submitted for arbitration.</p>
			<p><strong><em>Definitions</em></strong>. In addition to the terms defined elsewhere in these Terms and Conditions, the following definitions apply:</p>
			<p><strong>&ldquo;<em>Arbitrator</em>&rdquo;</strong> means the MotoBids personnel responsible for adjudicating disputes and managing the dispute resolution process contemplated in this Policy.</p>
			<p>&ldquo;<strong>Trade Sale</strong>&rdquo; means the Vehicle is being sold with limited arbitration rights. &ldquo;<em>Claim</em>&rdquo; means a claim submitted by the Buyer of a Vehicle for arbitration.</p>
			<p><strong>&ldquo;<em>Major Deception</em>&rdquo;</strong> means an act, representation or omission that materially affects the value of the Vehicle and, having regard to all of the circumstances, would reasonably be regarded as misleading, unprofessional, or unethical.</p>
			<p>&ldquo;<strong><em>Policy</em>&rdquo;</strong> or &ldquo;<strong><em>Policies</em></strong>&rdquo; mean the MotoBids Arbitration Policy.</p>
			<p><strong><em>Buyer Obligations</em></strong>. Before bidding on a Vehicle, Buyer is required to know the arbitration rules in place for the Vehicle. In addition, Buyer should carefully review all disclosed information including descriptions, photos, videos, condition reports and vehicle history reports. To preserve arbitration rights, Buyers are strongly encouraged to be diligent in their inspection and research of Vehicle purchases within the relevant arbitration period.</p>
			<p>Making an Arbitration Claim</p>
			<ol>
				<ol>
					<li><strong><em>Start Arbitration Claim</em>.</strong> Before starting a Claim, Buyer must pay all amounts owing for the Vehicle. Buyer starts arbitration Claim by submitting appropriate information and documentation online to MotoBids before the expiry of the arbitration period (48hrs from collection). Buyer submits the following information: Buyer&rsquo;s name and telephone number; contact person and telephone number; Vehicle Registration, model and year; Collection Time; and a description of the issue(s) to be arbitrated.<br />&nbsp;</li>
					<li><strong><em>Submit Supporting Documentation</em></strong><em>. No later than 2 business days after starting the Claim, Buyer is required to submit supporting documentation including but not limited to: (i) the original online description of the Vehicle (condition report, Vehicle detail page, etc.) (ii) digital photographs of the defect(s) underlying the Claim, (iii) diagnostic reporting and wholesale (not retail) repair estimates from an independent and reputable source or evidence of an appointment scheduled in order to obtain such repair estimates; Seller has two (2) business days to respond to arbitrator&rsquo;s inquiries and preliminary findings. If Seller fails to respond within the time limit, the arbitrator may decide the arbitration claim without further input from Seller. If the arbitration results in cancellation of the sale or other remedy in favour of Buyer, the Seller is required to reimburse Buyer its transportation costs and up to &euro;100 of costs incurred (verified by invoice and before taxes) of obtaining repair estimates. Seller is required to pay any award amount that is determined by the arbitrator to be payable within three (3) business days.</em></li>
				</ol>
			</ol>
			<p><strong><em>Buyer&rsquo;s Obligations</em></strong>. Buyer will not use any Vehicle that is subject to an arbitration Claim. Buyer may lose the right to make or continue a Claim if Buyer drives Vehicle other than for test/driving purposes (not to exceed 100 kms). Pending a final decision on the Claim, Buyer must, at its own expense, take reasonable steps to care for, preserve, secure and&nbsp;store&nbsp;the&nbsp;Vehicle&nbsp;until&nbsp;the&nbsp;Vehicle&nbsp;is&nbsp;properly returned. Buyer is not entitled to charge any parking, marshalling or other&nbsp;fees&nbsp;in&nbsp;connection&nbsp;with&nbsp;its&nbsp;preservation&nbsp;of the Vehicle Buyer will be charged the cost of damage repair and/or assessed a penalty fee for any breach of this provision.<br />&nbsp;</p>
			<p><strong><em>Arbitration Periods.</em></strong><em>&nbsp;Depending on the nature of the Claim, the Arbitration period will either be Regular or Extended. The arbitration period is two (2) business days after the Vehicle is picked up by the Buyer.</em></p>
			<p><strong><em>Vehicle&nbsp;History&nbsp;Report&nbsp;Disclosures</em></strong>.&nbsp;Each&nbsp;Vehicle&nbsp;is&nbsp;accompanied&nbsp;by a Vehicle History Report. Arbitration is not available for disclosures in a Vehicle History Report. Please ensure that you review the Vehicle History Report carefully before bidding.<br />&nbsp;</p>
			<p><strong><em>Lengthening the Arbitration Period</em></strong><em>. We reserve the right to lengthen the arbitration period when, in our opinion it would be fair and reasonable to do so. Although the Seller is required to make all applicable Disclosures, the Buyer is also required to be diligent in their inspection and research of their Vehicle purchases. Thus, if, by exercising reasonable diligence, the Buyer could have discovered a potential arbitration issue within the applicable time period, it is unlikely that an exception to the arbitration time limits will be made.</em></p>
			<p><strong><em>Investigation</em>.</strong> The Arbitrator will review only issues identified in the initial Claim. The Arbitrator may, but is not required to, consult with the Buyer, Seller or both parties to gain a better understanding of the issue(s) under <em>arbitration. Depending on the issue, the Arbitrator may also seek the advice of mechanics, trained vehicle inspectors or other automotive professionals. When appropriate, the Arbitrator may refer the Vehicle to an external resource such as a specialty repairer for consultation.</em></p>
			<p><strong><em>Decision</em></strong>. After completing the investigation, the Arbitrator will decide whether the arbitration Claim is valid. Before deciding what the remedy should be, the Arbitrator may attempt to mediate a resolution between the Buyer and the Seller. If agreement cannot be reached, the Arbitrator will decide the appropriate remedy, if any.<br />&nbsp;</p>
			<p><strong><em>Remedies</em></strong><em>. The Arbitrator has a broad discretion to grant any remedy or relief that he/she considers to be fair and reasonable in the circumstances. The purpose of the arbitration remedy is to ensure that the Buyer is fairly compensated for its loss. In some circumstances, such as where a Disclosure does not affect the value of the particular Vehicle, there may be no loss and thus no remedy. Remedies could involve: cancellation of the sale and refund of the purchase price, reimbursement of costs and/or expenses incurred including transportation costs, partial refund of the purchase price, repair of defect at Seller&rsquo;s expense, repair of defect with the costs apportioned between the Buyer and the Seller, no financial or other compensation, or similar remedies.</em><br />&nbsp;</p>
			<p><strong><em>Arbitration Fees and Costs.</em></strong><em>&nbsp;We reserve the right to assess an arbitration Fee against the Buyer and/or Seller. If we incur costs as part of an investigation (e.g. referral to an external resource, towing, transportation, etc.) the Arbitrator, as part of the remedy imposed, will determine liability to pay the costs incurred. Arbitrations that result in the cancellation of a Vehicle sale may be subject to a void Fee at our discretion.</em><br />&nbsp;</p>
			<p><strong><em>Finality.</em></strong><em>&nbsp;The decision of the Arbitrator and the remedy imposed is final and binding on the Buyer and Seller. By accessing our System, the Buyer and Seller agree to participate in our arbitration process and to observe, perform and be bound by the decision of the Arbitrator. Upon fulfilling the Arbitrator&rsquo;s decision, the Buyer and Seller are deemed to release each other from all claims and demands in respect of the matters referred to arbitration.</em></p>
			<p><strong><em>Limitations&nbsp;on&nbsp;the&nbsp;Availability&nbsp;of&nbsp;Arbitration</em></strong>.&nbsp;Although&nbsp;there&nbsp;may&nbsp;be&nbsp;limitations&nbsp;on&nbsp;the availability&nbsp;of arbitration, Sellers are cautioned that we do not tolerate deceptive selling practices. Accordingly, we reserve the right to allow for arbitration notwithstanding the entries in the table below if we determine that the Seller has engaged in Major Deception. Buyers should note that arbitration for antique vehicles, recreational vehicles, motor homes or motorcycles, arbitration is generally limited to cases of Major Deception.</p>
			<p><strong><em>Arbitration is not available for the following:</em></strong></p>
			<ol>
				<li>Matters that were properly disclosed as Seller Disclosures.</li>
				<li>Matters disclosed in a Vehicle History Report, Vehicle appraisal, Vehicle detail page, condition report or similar source made available to the Buyer via the MotoBids Limited before the Vehicle sale is finalised.</li>
				<li>General Reconditioning Items and Normal Wear and Tear.</li>
				<li>Vehicles that sell for less than &euro;2,000 (all listing categories). <strong>EXCEPT:</strong> Vehicles that sell for less than &euro;2,000 (all listing categories)</li>
				<li>Matters raised outside of the applicable arbitration period. <strong>EXCEPT:</strong> At MotoBids discretion</li>
			</ol>
			<p>&nbsp;</p>
			<p><strong><em>Customers</em>.</strong> Arbitration is a service available to MotoBids Buyers and Sellers only and not to their customers. The service is not available if the Buyer no longer has possession or ownership of the Vehicle.</p>
			<p><strong><em>Legal Proceedings</em></strong></p>
			<ol>
				<ol>
					<li>No action or legal proceedings will be commenced or prosecuted by the Buyer or the Seller against the other concerning: (i) Any matter that is or was the subject of arbitration unless to enforce the terms or conditions of the Arbitrator&rsquo;s decision; or (ii) Any matter for which arbitration is not available under the Terms and Conditions&nbsp;unless&nbsp;arbitration&nbsp;is&nbsp;not available because and only because the time limit for submitting the matter to arbitration including any extension of the time period has expired.</li>
					<li>No action or legal proceeding will be commenced or prosecuted by the Buyer or the Seller against MotoBids for any matter, cause or thing directly or indirectly related to our System or services, including but not limited to arbitration services. Unless otherwise prohibited by law, Buyer and the Seller release and waive all rights, remedies, claims, and causes of action against MotoBids from any&nbsp;claim&nbsp;or&nbsp;remedy&nbsp;whatsoever,&nbsp;whether known or unknown, for or by reason of using our System or services including but not limited to arbitration services.</li>
				</ol>
			</ol>
			<p><strong><em>Please Note</em>.</strong> The arbitration process is not a tool for price reduction or for dealing with &ldquo;Buyer&rsquo;s remorse&rdquo;. Buyers are cautioned to avoid impulse buying and to review all available information thoroughly before bidding. All used Vehicles generally have flaws and may require repairs. Buyers should allow for this in their bidding. Arbitrations are regularly monitored by MotoBids. Sellers who repeatedly fail to properly represent their Vehicles or Buyers who repeatedly submit questionable arbitration Claims, may have their rights of access to the our System and services temporarily or permanently suspended.</p>
			<p>&nbsp;</p>
			<p><strong>ADDITIONAL&nbsp;TERMS&nbsp;AND&nbsp;CONDITIONS</strong></p>
			<p><strong>DISCLAIMER OF WARRANTIES</strong>. MOTOBIDS PROVIDE OUR SYSTEM AND ALL RELATED SERVICES TO CUSTOMER ON AN<em>&nbsp;&ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo;&nbsp;</em>BASIS, AND HEREBY DISCLAIM ALL EXPRESS AND IMPLIED WARRANTIES RELATED TO CUSTOMER&rsquo;S AND/OR AUTHORISED USER&rsquo;S USE OF AND ACCESS TO OUR SYSTEM AND ANY RELATED SERVICES, INCLUDING ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON INFRINGEMENT, OR ANY WARRANTIES WHICH MAY BE CREATED THROUGH COURSE OF DEALING OR USAGE OF TRADE. CUSTOMER UNDERSTANDS THAT THERE MAY BE INTERRUPTIONS, DELAYS, INACCURACIES, OMISSIONS, AND/OR OTHER PROBLEMS WITH OUR SYSTEM, INFORMATION, AND/OR VEHICLE LISTINGS PUBLISHED ON THE MOTOBIDS SYSTEM, AND THAT WE WILL NOT BE LIABLE TO CUSTOMER, AUTHORISED USERS OR TO ANY THIRD PARTY THEREFOR. WE DO NOT WARRANT THAT OUR SYSTEM WILL BE ERROR FREE, CONTINUOUSLY AVAILABLE, FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR MEET CUSTOMER&rsquo;S OR ANY THIRD PARTY&rsquo;S REQUIREMENTS.</p>
			<p><strong>EXCLUSION OF CERTAIN LIABILITIES</strong>. UNDER NO CIRCUMSTANCES SHALL MOTOBIDS, EMPLOYEES, OFFICERS, DIRECTORS, AGENTS, LICENSORS, INDEPENDENT&nbsp;CONTRACTORS,&nbsp;OR&nbsp;SUPPLIERS&nbsp;(THE&nbsp;&quot;<strong>MOTOBIDS&nbsp;PARTIES</strong>&quot;)&nbsp;HAVE&nbsp;ANY&nbsp;LIABILITY TO CUSTOMER OR AUTHORISED USERS FOR ANY DIRECT, CONSEQUENTIAL (INCLUDING LOST PROFITS), EXEMPLARY, INCIDENTAL, INDIRECT OR SPECIAL DAMAGES OR COSTS RESULTING FROM ANY CLAIM (WHETHER IN CONTRACT, TORT, EQUITY, NEGLIGENCE, OR STRICT LIABILITY) RELATED TO OR ARISING OUT OF OUR SYSTEM, ANY RELATED SERVICES, THESE TERMS AND CONDITIONS, INCLUDING THE PERFORMANCE OR BREACH THEREOF OR THE USE OR INABILITY TO USE, OR<em>&nbsp;PERFORMANCE OR NON PERFORMANCE OF OUR SYSTEM OR ANY COMPONENT THEREOF, EVEN IF THEY HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</em></p>
			<p>&nbsp;</p>
			<p>LIMITATIONS OF LIABILITY</p>
			<ol>
				<ol>
					<li>THE LIABILITY OF ANY MOTOBIDS PARTIES FOR ANY BREACH(ES) OF THIS AGREEMENT SHALL BE LIMITED TO ACTUAL, DIRECT DAMAGES INCURRED BY CUSTOMER AS A RESULT OF SUCH BREACH(ES), AND ITS AGGREGATE LIABILITY FOR SUCH ACTUAL DAMAGES SHALL NOT EXCEED, UNDER ANY CIRCUMSTANCES, THE FEES PAID BY CUSTOMER TO MOTOBIDS OR THE APPLICABLE MOTOBIDS PARTNER IN RESPECT OF THE TRANSACTION OR SERVICE O<em>R &euro;500,</em> WHICHEVER IS LESS. MULTIPLE CLAIMS SHALL NOT EXPAND THESE LIMITATIONS.</li>
					<li>CUSTOMER AGREES THAT THE MOTOBIDS PARTIES SHALL HAVE NO LIABILITY WHATSOEVER, ACTUAL OR OTHERWISE, TO CUSTOMER OR AUTHORISED USERS BASED ON ANY OF THE FOLLOWING: (i) ANY DELAY, INTERRUPTION IN USE OF, FAILURE IN OR BREAKDOWN OF THE OUR SYSTEM OR ERRORS OR DEFECTS IN TRANSMISSION OCCURRING IN THE COURSE OF ACCESSING OR USING OUR SYSTEM; (ii) ANY UNLAWFUL OR UNAUTHORISED USE OF OUR SYSTEM; (iii) ANY LOSS OF OR DAMAGE TO CUSTOMER&apos;S RECORDS OR INFORMATION; OR (iv) ANY CLAIM RESULTING FROM THE TERMINATION OF ACCESS TO OUR SYSTEM OR ANY RELATED SERVICES.</li>
				</ol>
			</ol>
			<p><strong><em>Indemnity</em></strong>. Customer hereby agrees to indemnify, defend and save harmless the MotoBids Parties from and against all liabilities, losses, suits, claims, demands, costs, fines and actions of any kind or nature whatsoever to which a MotoBids Party shall or may become liable, or which a MotoBids Party may suffer (each an &ldquo;<strong>Indemnified Claim</strong>&rdquo;) by reason of (i) Customer&rsquo;s breach of the Terms and Conditions or any other obligation hereunder or any agreement between Customer and a MotoBids Party; (ii) Customer&rsquo;s negligence or wilful misconduct; (iii) Transportation Losses; (iv) any personal injury or property damage that Customer or any person for whom Customer is responsible causes to a MotoBids Party; (v) Customer&rsquo;s use of our &nbsp;System or any services or products provided by a MotoBids Party;</p>
			<p><strong><em>Export Transactions</em>.</strong> If a Vehicle is to be exported, Buyer is responsible for verifying that it satisfies all export requirements of the originating country and all import requirements of the destination country.MotoBids is not the<em>&nbsp;importer or exporter, and is not responsible for supplying export or import documentation. Arbitration is not available for Vehicles that leave the Republic of Ireland.</em></p>
			<p><strong><em>Investigations</em>.</strong> We cooperate fully with all investigations conducted by regulatory, government and police authorities (collectively &ldquo;<strong>Investigative Authority</strong>&rdquo;). By doing business at MotoBids, you authorise us to comply with all reasonable requests from any Investigative Authority for information and/or documents concerning you, your business, and/or transaction history.&nbsp;</p>
			<p><strong><em>Web Information</em></strong>. All Web Information is provided as a courtesy and for informational purposes only. Although we try to ensure that Web Information is accurate, errors may occur. In addition, some Web Information may be dependent on subjective interpretation or opinion. Neither MotoBids nor any of our Partners warrants or guarantees the accuracy or reliability of Web Information.</p>
			<p><strong><em>Self Dealing</em>.</strong> Self dealing is strictly prohibited. &ldquo;Self dealing&rdquo; means activities by any person or entity selling or purchasing via our System in which both the Seller and Buyer are the same or related entities, and/or share Authorised Representatives or principals, where MotoBids determines in its sole discretion that the intent and/or the result of such dealing is an artificial manipulation of the MotoBids process to create an unfair advantage or disadvantage or to impose undue risk upon MotoBids or others.</p>
			<p><strong><em>Price Boosting.</em></strong><em>&nbsp;</em>Price boosting or any other activity in which a Customer on its own or with others attempts to artificially inflate or decrease the selling price of a Vehicle, is strictly prohibited.</p>
			<p><strong><em>Conducting Business</em>.</strong> MotoBids: (i) may rely and act upon any purported signature whether oral, written, or electronic and other communication in connection with our System or services purportedly sent by Customer and/or any Authorised User or person purporting to be an agent or employee of Customer, and (ii) has no obligation to scrutinise, inquire, or confirm any signature or communication with Customer, any Authorised User or other person purporting to be an agent or employee of Customer. We may conduct business with Customer through the (non-exclusive) use of electronic, computer, digital, or other paperless means, including the good faith reliance on electronic mail, facsimile transmittal, telephonic or other usual and regular forms of communication without confirmation or authentication of the communication by receipt of an original signature, document, paper or otherwise.</p>
			<p><strong><em>Termination</em></strong>. You have the right to cease doing business with us whenever you wish and we have the right to cease doing business with you whenever we wish.&nbsp;</p>
			<p><strong><em>Governing Law</em>.</strong> These Terms and Conditions will be governed by, interpreted under, construed and enforced in accordance with the laws of the Republic of Ireland and the federal laws of Ireland.&nbsp;</p>
			<p><strong><em>Governing Language</em>.&nbsp;</strong>The parties have agreed that the governing language for these Terms and Conditions shall be English, regardless of whether there is any current or future translation of this Agreement and regardless of whether multiple language versions of this Agreement have been executed. All documents and communications contemplated thereby or relating thereto be drawn up in the English language.</p>
			<p><strong><em>Miscellaneous</em></strong>. No waiver of the provisions hereof shall be effective unless in writing and signed by an authorised representative of MotoBids. If any term or section of these Terms and Conditions is held invalid or unenforceable, under any statute or court decision, or any governmental rule or regulation, the remainder of these Terms and Conditions shall remain effective. These Terms and Conditions shall bind the respective heirs, executors, administrators, successors and assigns of Customer and inure to the benefit of MotoBids and its successors, assigns and subrogees.</p>
			<p>&nbsp;</p>
			<p>&nbsp;</p>
			<p><strong>ACCEPTANCE</strong></p>
			<p>By accessing or using the MotoBids System, you agree to abide by these Terms and Conditions.</p>
			<p><br /></p>
    </div>
  )
}

export default Terms
