import React from 'react'
import HowItWorks from "@components/HowItWorks";
import Header from '@components/Header'
import Footer from '@components/Footer'
import ContentWrapper from '@components/Markup/ContentWrapper'

const HowItWorksPage = () => {
  return (
    <ContentWrapper>
      <Header />
      <HowItWorks />
      <ContentWrapper.Sticky>
        <Footer />
      </ContentWrapper.Sticky>
    </ContentWrapper>
  )
}

export default HowItWorksPage
