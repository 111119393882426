import React from 'react'
import { useSelector } from 'react-redux'
import { useForm } from "react-hook-form"
import { Button } from 'antd'

const AddBuyer = ({ onSubmit, number, disabled = false }) => {
  const { handleSubmit, register,  formState: { errors }, control } = useForm();
  const { buyersForm, buyers } = useSelector(({carsReducer}) => carsReducer)

  return (
    <div onSubmit={handleSubmit(onSubmit)}>
      <h2 style={{ color: '#008170', fontSize: '24px', marginBottom: '0', marginTop: '10px' }}>Extra Buyer {number}</h2>
      <label className="sell-form__label" style={{ marginTop: '0' }} >
        Buyer
        <input { ...register('buyer', { required: true }) } disabled={disabled} type="text" className="sell-form__input"/>
      </label>
      { errors.buyer && <p className="error">Buyer name is required</p> }
      <label className="sell-form__label" >
        Title
        <input { ...register('title', { required: true }) } type="text" disabled={disabled} className="sell-form__input"/>
      </label>
      { errors.title && <p className="error">Title is required</p> }
      <label className="sell-form__label" >
        Mobile Number
        <input { ...register('phone_number', { required: true }) } disabled={disabled} type="text" className="sell-form__input"/>
      </label>
      { errors.phone_number && <p className="error">Mobile Number is required</p> }
      <label className="sell-form__label" >
        Email Address
        <input { ...register('email', { required: true }) } type="email" disabled={disabled} className="sell-form__input"/>
      </label>
      { errors.email && <p className="error">Mobile Number is required</p> }
      {/* { !disabled && <Button onClick={handleSubmit(onSubmit)} style={{ padding: '10px 40px', width: '100%', marginBottom: '32px', lineHeight: '1', fontSize: '14px' }} className="sell__submit" size="large">{ buyersForm.length > 1 || buyers.length > 1 ? 'Add one more buyer' : 'Add buyer' }</Button> } */}
      { !disabled && <Button onClick={handleSubmit(onSubmit)} style={{ padding: '10px 40px', width: '100%', marginBottom: '32px', lineHeight: '1', fontSize: '14px' }} className="sell__submit" size="large">{ 'Save' }</Button> }
    </div>
  )
}

export default AddBuyer
