import React from 'react'
import { useWindowSize } from '@hooks/useWindowSize'
import DesktopNav from './DesktopNav'
import MobileNav from './MobileNav'
import './Header.scss'

// TODO: correct distance between logo and nav, add description to avatar

const Header = () => {
  const size = useWindowSize()

  return (
    <header className="header" id="header-target">
      { size.width <= 1100 ? <MobileNav /> : <DesktopNav /> }
    </header>
  )
}

export default Header
