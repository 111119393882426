import React from 'react';

const Buyer = ({buyer}) => {
  return (
    <div className="buyer-wrapper">
      <div className="buyer-info-wrapper">
        <span className="buyer-title">Extra Buyer:</span>
        <p className="buyer-text">{buyer.buyer}</p>
      </div>
      <div className="buyer-info-wrapper">
        <span className="buyer-title">Title:</span>
        <p className="buyer-text">{buyer.title}</p>
      </div>
      <div className="buyer-info-wrapper">
        <span className="buyer-title">Phone:</span>
        <p className="buyer-text">{buyer.phone_number}</p>
      </div>
      <div className="buyer-info-wrapper">
        <span className="buyer-title">Email:</span>
        <p className="buyer-text">{buyer.email}</p>
      </div>
    </div>
  );
};

export default Buyer;
