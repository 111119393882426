export const LOCATION_TYPES = [{
  title: "Munster",
  value: 0,
}, {
  title: "Connaught",
  value: 1,
}, {
  title: "Ulster",
  value: 2, 
}, {
  title: "Leinster",
  value: 3,
}];
