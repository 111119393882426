export default {
  IS_AUTHENTICATED: 'IS_AUTHENTICATED',
  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  SIGN_UP : 'SIGN_UP',
  SIGN_UP_SUCCESS : 'SIGN_UP_SUCCESS',
  SIGN_UP_ERROR : 'SIGN_UP_ERROR',
  LOGOUT: 'LOGOUT',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_ERROR: 'LOGOUT_ERROR',
  SIGN_UP: 'SIGN_UP',
  SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
  SIGN_UP_ERROR: 'SIGN_UP_ERROR',
  STEP_SET: 'STEP_SET',
  STEP_ADD: 'STEP_ADD',
  STEP_DECREMENT: 'STEP_DECREMENT',
  BLOCK_SUCCESS: 'BLOCK_SUCCESS',
  UNBLOCK_SUCCESS: 'UNBLOCK_SUCCESS',
  BUYER_CONFIRM_REQUEST: 'BUYER_CONFIRM_REQUEST',
  BUYER_CONFIRM_SUCCESS: 'BUYER_CONFIRM_SUCCESS',
  BUYER_CONFIRM_ERROR: 'BUYER_CONFIRM_ERROR',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_ERROR: 'FORGOT_PASSWORD_ERROR',
}