import { filterBlackList } from '@helpers';
import { INITIAL_STATE, CARS_ACTIONS, PAYMENTS_ACTIONS } from '@constants';

const carsReducer = (state = INITIAL_STATE.CarsInitialState, action) => {
  const {type, payload} = action || {};
  const {
    meta,
    cars,
    car,
    buyers,
    posts = [],
    post,
    error,
    total_pages,
    total_products,
    id = 0,
    likes_count,
    replyId,
    reply,
    userPosts,
    attachments,
    clutch,
    tyres,
    damage,
    body_types,
    fuel_types,
    transmition_types,
    colors,
    product,
    history_types,
    message,
    step,
    win_bid,
    win_id,
    replace = false,
    buyer,
    summary,
    isMotorCheckModalOpen,
  } = payload || {};
  switch (type) {
    case CARS_ACTIONS.GET_AUCTIONS: {
      const newMeta = meta || state.auctionsMeta;
      return {...state, auctionsMeta: newMeta};
    }

    case CARS_ACTIONS.GET_AUCTIONS_SUCCESS: {
      const {page} = state.meta || {};
      const newCars = page === 1 ? cars : [...state.auctions, ...cars];
      if(replace) {
        return { 
          ...state,
          auctions: [ ...cars],
          auctionsMeta: {
            ...state.auctionsMeta,
            total_pages: total_pages
          },
          total_sales: total_products,
        };
      } else {
        return { 
          ...state,
          auctions: newCars,
          auctionsMeta: {
            ...state.auctionsMeta,
            total_pages: total_pages
          },
          total_sales: total_products,
        };
      }
    }

    case CARS_ACTIONS.GET_MY_CARS: {
      const newMeta = meta || state.myCarsMeta;
      const newCars = newMeta.page === 1 ? [] : state.myCars
      return {...state, myCarsMeta: newMeta, myCars: newCars};
    }

    case CARS_ACTIONS.GET_MY_CARS_SUCCESS: {
      const {page} = state.meta || {};
      const newCars = page === 1 ? cars : [...state.myCars, ...cars];
      if(replace) {
        return { 
          ...state,
          myCars: [ ...cars],
          myCarsMeta: {
            ...state.myCarsMeta,
            total_pages: total_pages
          }
        };
      } else {
        return { 
          ...state,
          myCars: newCars,
          myCarsMeta: {
            ...state.myCarsMeta,
            total_pages: total_pages
          }
        };
      }
    }

    case CARS_ACTIONS.GET_FOLLOWING_CARS_SUCCESS: {
      const {page} = state.meta || {};
      const newCars = page === 1 ? cars : [...state.followingCars, ...cars];
      if(replace) {
        return { 
          ...state,
          followingCars: [ ...cars],
          followingCarsMeta: {
            ...state.followingCarsMeta,
            total_pages: total_pages
          }
        };
      } else {
        return { 
          ...state,
          followingCars: newCars,
          followingCarsMeta: {
            ...state.followingCarsMeta,
            total_pages: total_pages
          }
        };
      }
    }

    case CARS_ACTIONS.GET_PURCHASED_CARS_SUCCESS: {
      const {page} = state.meta || {};
      const newCars = page === 1 ? cars : [...state.purchasedCars, ...cars];
      if(replace) {
        return { 
          ...state,
          purchasedCars: [ ...cars],
          purchasedCarsMeta: {
            ...state.purchasedCarsMeta,
            total_pages: total_pages
          }
        };
      } else {
        return { 
          ...state,
          purchasedCars: newCars,
          purchasedCarsMeta: {
            ...state.purchasedCarsMeta,
            total_pages: total_pages
          }
        };
      }
    }

    case CARS_ACTIONS.GET_ARCHIVED_CARS_SUCCESS: {
      const {page} = state.meta || {};
      const newCars = page === 1 ? cars : [...state.archivedCars, ...cars];
      if(replace) {
        return { 
          ...state,
          archivedCars: [ ...cars],
          archivedCarsMeta: {
            ...state.archivedCarsMeta,
            total_pages: total_pages
          }
        };
      } else {
        return { 
          ...state,
          archivedCars: newCars,
          archivedCarsMeta: {
            ...state.archivedCarsMeta,
            total_pages: total_pages
          }
        };
      }
    }

    case CARS_ACTIONS.GET_RELATED_CARS_SUCCESS: {
      const {page} = state.meta || {};
      const newCars = page === 1 ? cars : [...state.relatedCars, ...cars];
      if(replace) {
        return { 
          ...state,
          relatedCars: [ ...cars],
          relatedCarsMeta: {
            ...state.relatedCarsMeta,
            total_pages: total_pages
          }
        };
      } else {
        return { 
          ...state,
          relatedCars: newCars,
          relatedCarsMeta: {
            ...state.relatedCarsMeta,
            total_pages: total_pages
          }
        };
      }
    }

    case CARS_ACTIONS.GET_CARS: {
      const newMeta = meta || state.carsMeta;
      return {...state, carsMeta: newMeta};
    }

    case CARS_ACTIONS.GET_CARS_SUCCESS: {
        return { 
          ...state,
          cars: [ ...cars],
          carsMeta: {
            ...state.carsMeta,
            total_pages: total_pages,
          },
          total_products: total_products,
        };
    }

	case CARS_ACTIONS.CLEAN_CAR_INFO: {
		return { ...state, car: null}
	}

    case CARS_ACTIONS.GET_CAR_INFO_SUCCESS: {
      return { ...state, car: car }
    }

    case CARS_ACTIONS.UPDATE_BID: {
      console.log(state.car, message, state.car && state.car.sale && state.car.sale.data && state.car.sale.data.attributes.id === message.sale_id)
      if(state.car && state.car.sale && state.car.sale.data && state.car.sale.data.attributes.id === message.sale_id) {
        const newCar = { ...state.car }
        console.log(newCar)
        if(message.amount >= state.car.sale.data.attributes.current_live_bid && 
         message.sale_id === state.car.sale.data.attributes.id) {
          if(message?.bid_type === 'live') {
            newCar.sale.data.attributes.current_live_bid = message?.amount
            newCar.sale.data.attributes.current_live_bid_user_id = message?.bider?.data?.attributes?.id
            newCar.sale.data.attributes.created_at = message?.created_at
          }
          if(message?.bid_type === 'max_price') {
            newCar.sale.data.attributes.current_max_price = message?.amount
            newCar.sale.data.attributes.current_max_price_user_id = message?.bider?.data?.attributes?.id
            newCar.sale.data.attributes.created_at = message?.created_at
          }
        }
        if(message.sale_end_date) newCar.sale.data.attributes.end_date = message.sale_end_date
        console.log(newCar.sale.data.attributes, message)
        state = { ...state, car: newCar }
      }
      const followingIndex = state.followingCars.findIndex(item => item.attributes?.sale?.data?.id == message.sale_id)
      if(followingIndex > -1) {
        if(message.amount >= state.followingCars[followingIndex].attributes.sale.data.attributes.current_live_bid && message.sale_id === state.followingCars[followingIndex].attributes.sale.data.attributes.id) {
          if(message?.bid_type === 'live') {
            state.followingCars[followingIndex].attributes.sale.data.attributes.current_live_bid = message?.amount
            state.followingCars[followingIndex].attributes.sale.data.attributes.current_live_bid_user_id = message?.bider?.data?.attributes?.id
            state.followingCars[followingIndex].attributes.sale.data.attributes.created_at = message?.created_at
          }
          if(message?.bid_type === 'max_price') {
            state.followingCars[followingIndex].attributes.sale.data.attributes.current_max_price = message?.amount
            state.followingCars[followingIndex].attributes.sale.data.attributes.current_max_price_user_id = message?.bider?.data?.attributes?.id
            state.followingCars[followingIndex].attributes.sale.data.attributes.created_at = message?.created_at
          }
        }
        if(message.sale_end_date) state.followingCars[followingIndex].attributes.sale.data.attributes.end_date = message.sale_end_date
      }
      const auctionsIndex = state.auctions.findIndex(item => item.id === message.sale_id)
      if(auctionsIndex > -1) {
        if(message.amount >= state.auctions[auctionsIndex].current_live_bid && message.sale_id === state.auctions[auctionsIndex].id) {
          if(message?.bid_type === 'live') {
            state.auctions[auctionsIndex].current_live_bid = message?.amount
            state.auctions[auctionsIndex].current_live_bid_user_id = message?.bider?.data?.attributes?.id
            state.auctions[auctionsIndex].created_at = message?.created_at
          }
          if(message?.bid_type === 'max_price') {
            state.auctions[auctionsIndex].current_max_price = message?.amount
            state.auctions[auctionsIndex].current_max_price_user_id = message?.bider?.data?.attributes?.id
            state.auctions[auctionsIndex].created_at = message?.created_at
          }
        }
        if(message.sale_end_date) state.auctions[auctionsIndex].end_date = message.sale_end_date
      }
      return state
    }

    case CARS_ACTIONS.WIN_BID: {
      if(state.car && state.car.id) {
        const newCar = win_bid.data.attributes.product_id === state.car.id ? 
        { ...state.car, win_bid: win_bid, has_winner: true } : { ...state.car }

        return { ...state, car: newCar }
      } else {
        const index = state.followingCars.findIndex(item => item.attributes.id === win_bid.data.attributes.product_id)
        if(index > -1) state.followingCars[index].attributes.win_bid = win_bid
        if(index > -1) state.followingCars[index].attributes.has_winner = true

        return { ...state }
      }
    }

    case CARS_ACTIONS.ACCEPT_WIN_SUCCESS: {
      const newMyCars = [ ...state.myCars ]
      newMyCars.forEach((car, i) => {
        if(car?.attributes?.win_bid?.data?.id == win_id) {
          newMyCars[i].attributes.win_bid.data.attributes.is_accepted = true
        }
      })

      return { ...state, myCars: newMyCars }
    }

    case CARS_ACTIONS.REJECT_WIN_SUCCESS: {
      const newMyCars = [ ...state.myCars ]

      for (let i = 0; i < newMyCars.length; i++) {
        const car = newMyCars[i];
        
        if(car?.attributes?.win_bid?.data?.id == win_id) {
          newMyCars[i].attributes.win_bid.data = null
          break;
        }
      }

      return { ...state, myCars: newMyCars }
    }

    case CARS_ACTIONS.CREATE_CAR_SUCCESS: {
      if(product) {
        return { ...state, tempCar: product?.attributes, product_id: product?.id }
      }
      return state
    }

    case CARS_ACTIONS.GET_ATTACHMENTS_SUCCESS: {
      const newAttachments = {};
      console.log({attachments})

      attachments.forEach(attch => {
        newAttachments[attch.title.split(' ').join('_')] = attch;
      })

       if(replace) {
        return { ...state, createdAttachments: {...newAttachments} }

      } {
        return { ...state, createdAttachments: {...state.createdAttachments, ...newAttachments} }

      }
    }

    case CARS_ACTIONS.GET_CLUTCH_SUCCESS: {
      const keys = Object.keys(clutch)
      const clutchTypes = keys.map(key => ({ value: clutch[key], key: key }))
      return { ...state, clutch_types: clutchTypes }
    }

    case CARS_ACTIONS.GET_TYRES_SUCCESS: {
      const keys = Object.keys(tyres)
      const tyresTypes = keys.map(key => ({ value: tyres[key], key: key }))
      return { ...state, tyres_types: tyresTypes }
    }

    case CARS_ACTIONS.GET_DAMAGE_SUCCESS: {
      const keys = Object.keys(damage)
      const damagesTypes = keys.map(key => ({ value: damage[key], key: key }))
      return { ...state, damages_types: damagesTypes }
    }

    case CARS_ACTIONS.GET_HISTORY_TYPES_SUCCESS: {
      const keys = Object.keys(history_types)
      const historyTypes = keys.map(key => ({ value: history_types[key], key: key }))
      return { ...state, history_types: historyTypes }
    }

    case CARS_ACTIONS.GET_BODY_TYPES_SUCCESS: {
      const keys = Object.keys(body_types)
      const bodyTypes = keys.map(key => ({ value: body_types[key], key: key }))
      return { ...state, body_types: bodyTypes }
    }

    case CARS_ACTIONS.GET_FUEL_TYPES_SUCCESS: {
      const keys = Object.keys(fuel_types)
      const fuelTypes = keys.map(key => ({ value: fuel_types[key], key: key }))
      return { ...state, fuel_types: fuelTypes }
    }

    case CARS_ACTIONS.GET_TRANSMITION_TYPES_SUCCESS: {
      const keys = Object.keys(transmition_types)
      const transmitionTypes = keys.map(key => ({ value: transmition_types[key], key: key }))
      return { ...state, transmition_types: transmitionTypes }
    }

    case CARS_ACTIONS.GET_COLORS_SUCCESS: {
      const keys = Object.keys(colors)
      const colorTypes = keys.map(key => ({ value: colors[key], key: key }))
      return { ...state, colors: colorTypes }
    }

    case CARS_ACTIONS.ADD_STEP: {
      if(state.formStep >= 0 && state.formStep < 3) {
        return { ...state, formStep: state.formStep + 1}
      }
      return { ...state }
    }

    case CARS_ACTIONS.SET_STEP: {
      if(step > -1 && step < 3) {
        return { ...state, formStep: step}
      }
    }

    case CARS_ACTIONS.GET_POSTS: {
      const newMeta = meta || state.meta;
      const newPosts = newMeta.page === 1 ? []:state.posts;
      return {...state, meta: newMeta, posts: newPosts};
    }
    case CARS_ACTIONS.GET_POSTS_SUCCESS: {
      const {page} = state.meta || {};
      const newPosts = page === 1 ? posts : [...state.posts, ...posts];
      return {
        ...state,
        posts: newPosts,
        meta: {
          ...state.meta, 
          total_pages: total_pages
        }
      };
    }
    case CARS_ACTIONS.GET_POST_SUCCESS: {
      const newPosts = [...state.posts];
      const i = newPosts.findIndex((el) => el.id === post?.id);
      if (i !== -1 && post) newPosts[i] = post;
      return {
        ...state,
        post: post || null,
        posts: newPosts,
      };
    }
    case CARS_ACTIONS.GET_USER_POSTS:
      return {
        ...state,
        userPostsMeta: meta,
      }
    case CARS_ACTIONS.GET_USER_POSTS_SUCCESS: {
      const newPosts = state.userPostsMeta.page === 1? userPosts:[...state.userPosts, ...userPosts]
      return {
        ...state,
        userPosts: newPosts,
        userPostsMeta: {
          ...state.userPostsMeta,
          total_pages
        }
      }
      
    }
    case CARS_ACTIONS.FOLLOW_SUCCESS: {
      const newCars = [...state.cars];
      
      newCars.forEach((item, index) => {
        if (item.id === id) newCars[index].is_followed = true;
      });

      const newCar = {...state.car};

      if(newCar.id == id) newCar.is_followed = true;

      const newFollowingCars = [...state.followingCars]
      newFollowingCars.forEach((item, index) => {
        if (item.id == id) newFollowingCars[index].attributes.is_followed = true;
      });

      console.log(newFollowingCars)

      return {
        ...state,
        car: newCar,
        cars: newCars,
        followingCars: newFollowingCars
      };
    }

    case CARS_ACTIONS.UNFOLLOW_SUCCESS: {
      const newCars = [...state.cars];

      newCars.forEach((item, index) => {
        if (item.id === id) newCars[index].is_followed = false;
      });

      const newCar = {...state.car};
      if(newCar.id == id) newCar.is_followed = false;


      const newFollowingCars = [...state.followingCars]
      newFollowingCars.forEach((item, index) => {
        console.log(item)
        if (item.id == id) newFollowingCars[index].attributes.is_followed = false;
      });

      console.log(newFollowingCars)

      return {
        ...state,
        car: newCar,
        cars: newCars,
        followingCars: newFollowingCars,
      };
    }

    case CARS_ACTIONS.LIKE_POST_SUCCESS: {
      const newPosts = [...state.posts];
      const i = newPosts.findIndex((el) => el.id === id);
      newPosts[i].is_liked = !newPosts[i].is_liked;
      newPosts[i].likes_count = likes_count || 0;
      const newPost = state.post || null;
      if (newPost) {
        newPost.is_liked = !newPost.is_liked;
        newPost.likes_count = likes_count || 0;
      }
      return {
        ...state,
        post: newPost,
        posts: newPosts,
      };
    }
    case CARS_ACTIONS.LIKE_REPLY_SUCCESS: {
      const newPosts = [...state.posts];
      const postIndex = newPosts.findIndex(el => el.id === reply.post_id);

      const newReplies = newPosts[postIndex].replies;
      console.log({post: newPosts[postIndex]})
      const i = newReplies.findIndex((el) => el.id === reply.id);
      console.log({i})
      // const pi = newPostsReplies.findIndex((el) => el.id === id);
      newReplies[i].is_liked = !newReplies[i].is_liked;
      newReplies[i].likes_count = newReplies[i].is_liked ? newReplies[i].likes_count + 1 : newReplies[i].likes_count - 1;
      newPosts[postIndex].replies = newReplies;
      return {
        ...state,
        posts: newPosts,
        post: {
          ...state.post,
          replies: newReplies
        }
      }
    }
    case CARS_ACTIONS.SEND_VIEW_SUCCESS: {
      const newPosts = [...state.posts];
      const newPost = state.post;
      const i = newPosts.findIndex((el) => el.id === id);
      newPosts[i].views_count += 1;
      if (newPost) newPost.views_count += 1;
      return {...state, post: newPost, posts: newPosts};
    }
    case CARS_ACTIONS.DELETE_POST_SUCCESS: {
      const newPosts = [...state.posts].filter((el) => el.id !== id);
      return {
        ...state,
        posts: newPosts,
      };
    }
    case CARS_ACTIONS.LIKE_REPLY_ERROR:
    case CARS_ACTIONS.GET_POST_ERROR:
    case CARS_ACTIONS.GET_POSTS_ERROR:
      return {
        ...state,
        error: error || '',
      };
    case CARS_ACTIONS.CREATE_REPLY_SUCCESS: {
      const newPosts = [...state.posts];
      const newPost = {...state.post};
      const post_id = reply?.post_id || 0;
      // console.log(reply)
      // console.log(post_id)
      if (newPost && newPost.id === post_id && reply) {
        newPost.replies = newPost.replies
          ? ([reply, ...newPost.replies])
          : [reply];
        newPost.replies_count += 1;
      }
      const postIndex = newPosts.findIndex((el) => el?.id === post_id);
      if (postIndex !== -1 && reply) {
        const replies = newPosts[postIndex].replies || [];
        replies.unshift(reply);
        newPosts[postIndex].replies = replies;
        newPosts[postIndex].replies_count += 1;
      }
      return {...state, post: newPost, posts: newPosts};
      // return {...state, posts: newPosts};
    }
    case CARS_ACTIONS.DELETE_REPLY_SUCCESS: {
      const newPosts = [...state.posts];
      const newPost = state.post;
      if (newPost && newPost?.id === id)
      {
        newPost.replies = newPost.replies?.filter((el) => el.id !== replyId);
        console.log(12121)
      }
      const postIndex = newPosts.findIndex((el) => el?.id === id);
      const replyIndex =
        newPosts[postIndex]?.replies?.findIndex((el) => el.id === replyId)
      if (replyIndex !== -1) {
        newPosts[postIndex]?.replies?.splice(replyIndex, 1);
        newPosts[postIndex].replies_count -= 1;
      }
      return {...state, post: newPost, posts: newPosts};
    }
    case CARS_ACTIONS.BLOCK_SUCCESS: {
      const blackList = [Number(id)];
      const newPosts = filterBlackList({
        posts: state.posts,
        blackList,
        blockedMe: [],
        skipComments: false,
      });
      let newPost = state.post;
      if (newPost)
        newPost =
          (filterBlackList({
            posts: [newPost],
            blackList,
            blockedMe: [],
            skipComments: false,
          })?.[0]) || null;
      const newUserPosts = filterBlackList({
        posts: state.userPosts,
        blackList,
        blockedMe: [],
        skipComments: false,
      });
      return {
        ...state,
        post: newPost,
        userPosts: newUserPosts,
        posts: newPosts,
      };
    }

    case CARS_ACTIONS.CREATE_BUYER_SUCCESS: {
      const newBuyersForm = [ ...state.buyersForm ]
      newBuyersForm.push(newBuyersForm[newBuyersForm.length - 1] + 1)
      const newBuyer = [...state.buyers];
      newBuyer.push(buyer);
      return {
        ...state,
        buyersForm: [ ...newBuyersForm ],
        buyers: [...newBuyer],
      }
    }

    case CARS_ACTIONS.GET_BUYERS_SUCCESS: {
      const users = buyers?.users?.data?.map(user => user.attributes)
      // const newBuyers = users.length ? [ ...state.buyers, ...users ] : [ ...state.buyers ]
      const newBuyers = [...users ]

      return {
        ...state,
        buyers: [ ...newBuyers ],
        buyersMeta: {
          ...state.buyersMeta,
          total_pages: buyers?.total_pages
        }

      }
    }
    
    case CARS_ACTIONS.RELEASE_CAR_SUCCESS: {
      return {
        ...state,
        createdAttachments: {},
        photosStep: false,
        seviceStep: false,
      }
    }

    case CARS_ACTIONS.ADD_CAR_SUMMARY: {
      return {
        ...state,
        carSummary: summary,
        photosStep: true,
      }
    }

    case CARS_ACTIONS.DELETE_CAR_SUCCESS: {
      console.log('deeeleting')
      return {
        ...state,
        myCars: [ ...state.myCars].filter(car => car?.attributes.id !== id)
      }
    }

    case CARS_ACTIONS.ADD_CAR_SERIVCE_HISTORY_SUCCESS: {
      return {
        ...state,
        serviceStep: true,
      }
    }

    case PAYMENTS_ACTIONS.DONATE_SUCCESS: {
      const newCar = { ...state.car }

      if(newCar?.win_bid?.data?.attributes?.is_paid === false) {
        newCar.win_bid.data.attributes.is_paid = true
      }

      return { 
        ...state,
        car: { ...newCar }
      }
    }

    case CARS_ACTIONS.GET_MOTOR_CHECK_INFO:
      return {
        ...state,
        error: null,
      };

    case CARS_ACTIONS.GET_MOTOR_CHECK_INFO_ERROR:
      return {
        ...state,
        error,
      };

    case CARS_ACTIONS.TOGGLE_MOTOR_CHECK_MODAL:
      return {
        ...state,
        isMotorCheckModalOpen,
      };

    case CARS_ACTIONS.GET_MOTOR_CHECK_INFO_SUCCESS:
      return {
        ...state,
        car: {
          ...state.car,
          motor_check_info: payload.motor_check_info
        }
      }
    default:
      return state;
  }
};

export {carsReducer};