import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector} from 'react-redux'
import { carsActions } from '@actions'
import { Button } from 'antd';
import Photo from './Photo'
import { config } from '@config'
import { useForm } from "react-hook-form";

const Photos = () => {
  const [additionalPhotos, setAdditionalPhotos] = useState([ 1 ])
  const dispatch = useDispatch()
  const { createdAttachments, product_id } = useSelector(state => state.carsReducer)
  const { handleSubmit, register } = useForm();

  useEffect(() => {
    dispatch(carsActions.getAttachments(product_id, true))
  }, [product_id])

  const toDefaultFiles = (files) => {
    return files?.id ? [{
      uid: files.id,
      name: files.image_url.split('/')[files.image_url.split('/').length - 1],
      status: 'done',
      url: config.REACT_APP_IMAGE_URL + files.image_url
    }] : []
  }

  const checkAttachments = () => {
    const imgsRequired = ['drivers_side', 'front_side', 'drivers_seat', 'steering_wheel', 'rear']
    imgsRequired.forEach(img => {
      if(!(img in createdAttachments)) {
        console.log(img)
        return false
      }
    })

    for (let i = 0; i < imgsRequired.length; i++) {
      if(!(imgsRequired[i] in createdAttachments)) {
        return false
      }
    }
    return true
  }

  const check = checkAttachments()

  const onSubmit = data => {
    
    if(checkAttachments()) {
      dispatch(carsActions.addCarSummary(data.summarise_damage_info))
      dispatch(carsActions.addStep())
    }
  }

  const addAdditionalPhoto = () => setAdditionalPhotos(prevAdditionalPhoto => [...prevAdditionalPhoto, prevAdditionalPhoto[prevAdditionalPhoto.length - 1] + 1 ])

  return product_id ? (
    <form onSubmit={handleSubmit(onSubmit)} className="sell__form" style={{ width: '100%', display: 'flex', flexDirection: 'column'}}>
      <h1>Photos</h1>
      <p style={{ textAlign: 'center'}}>Please, upload required photos of your car below</p>
      {

      }
      <div className="photos">
        <Photo headline="Front & Drivers Side" title="front side" product_id={product_id} attr={toDefaultFiles(createdAttachments?.front_side)} />
        <Photo title="drivers side" product_id={product_id} attr={toDefaultFiles(createdAttachments?.drivers_side)} />
        <Photo title="drivers seat" product_id={product_id} attr={toDefaultFiles(createdAttachments?.drivers_seat)} />
        <Photo title="steering wheel" product_id={product_id} attr={toDefaultFiles(createdAttachments?.steering_wheel)} />
        <Photo headline="Rear" title="rear" product_id={product_id} attr={toDefaultFiles(createdAttachments?.rear)} />
        <Photo headline="Passenger Side" title="passenger side" product_id={product_id} attr={toDefaultFiles(createdAttachments?.passenger_side)} />
        <Photo headline="Alloy" title="alloy" product_id={product_id} attr={toDefaultFiles(createdAttachments?.alloy)} />
        { additionalPhotos.map((photo, i) => {
          return (
            <Photo title={`additional photo ${photo}`} addAdditionalPhoto={addAdditionalPhoto} additional={i ===  additionalPhotos.length - 1} key={i} product_id={product_id} attr={toDefaultFiles(createdAttachments?.[`additional_photo_${photo}`])} />
        )})}
      </div>
      <div className="zone-summary">
      <p style={{ color: '#000', fontWeight: '500', fontSize: '14px', textAlign: 'center' }}>Type in any other interesting info to help sale such as panoramic sunroof, leather upholstery, upgraded alloys, etc</p>
      <label className="sell-form__label">
        Options/Accessories
        <input { ...register("summarise_damage_info") } type="text" className="sell-form__input" placeholder="Leather, Panoramic Sunroof, SatNav" />
      </label>
    </div>
      <Button htmlType="submit" disabled={!check} style={{ alignSelf: 'center'}} className="sell__submit" size="large" type="primary">NEXT STEP</Button>
      
    </form>
  ) : <p>loading...</p>
}

export default Photos
