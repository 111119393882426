import React from 'react'
// import { Card } from 'antd'
import { Button, Divider, Typography } from 'antd';
import { config } from '@config'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { profileActions } from '@actions';

import moment from 'moment'
import isDayLeft from '../../helpers/isDayLeft';
import Countdown from 'react-countdown';
import customCountdownRenderer from '../../helpers/customCountdownRenderer';
import useTimeLeft from '../../hooks/useTimeLeft';

const CarCard = ({ 
  id,
  start_bid,
  current_live_bid,
  reserved_price,
  make, model,
  car_year,
  buyer, 
  odometer, 
  generation,
  trim, 
  front_image_url,
  is_owner,
  is_followed,
  sale_date,
  fuel_type,
  engine,
  end_date,
  variant = '',
  sale: saleData,
  hideFollow = false,
  time
}) => {
  
  const history = useHistory()
  const dispatch = useDispatch()

  const handleRedirect = () => { 
    history.push(`/cars/${id}`)
  }

  const handleFollow = e => {
    e.stopPropagation()
    dispatch(profileActions.follow(id))
  }

  const handleUnfollow = e => {
    e.stopPropagation()
    dispatch(profileActions.unfollow(id))
  }

  const makeName = make?.data?.attributes?.title
  const modelName = model?.data?.attributes?.title  
  const trimName = trim?.data?.attributes?.name  
  const sale = saleData.data?.attributes
  const timeLeft = useTimeLeft(sale?.end_date)

  const title = `${makeName} ${modelName} ${variant} ${engine} ${fuel_type}`

  return (
    <div className="card adaptive" style={{ cursor: 'pointer' }} onClick={handleRedirect}>
      <div className="card__image" style={{ backgroundImage: `url(${config.REACT_APP_IMAGE_URL + front_image_url})` }}></div>
      <div className="card__content">
        <div className="card__header">
          <div className="card__headline">
            <span>{sale?.current_live_bid ? 'Current' : 'Start'} Bid: € {sale?.current_live_bid || reserved_price}</span>
            <h3>
              {title}
            </h3>
          </div>
           { (!is_owner && !hideFollow )  && <Button onClick={ !is_followed ? handleFollow : handleUnfollow } size="large" style={{ borderColor: '#008170', color: is_followed ? '#fff' : '#008170'  }} type={is_followed ? 'primary' : null} >{ !is_followed ? 'Track' : 'Tracked' }</Button> }
        </div>
        <Divider style={{ margin: '8px 0 16px 0' }} />
        <ul className="card__properties">
          <li className="card--required"><strong>Year:</strong> {car_year}</li>
          {/* <li><strong>Dealer:</strong> {buyer}</li> */}
          <li className="card--required"><strong>Odometer:</strong> {odometer}</li>
          <li><strong>Lot:</strong> {sale?.lot}</li>
          <li className="card--required"><strong>Engine:</strong> {engine}</li>
        </ul>
        {isDayLeft(sale?.end_date) ? <div className='countdownWrapper'><p>Auction finishes in:</p><div className="countdown"><Countdown date={timeLeft} controlled renderer={customCountdownRenderer} /></div></div> : <><strong>End time:</strong> {moment(sale?.end_date).format('DD/MM/YYYY [at] hh:mm a')}</>}
      </div>
    </div>
  )
}

export default CarCard
