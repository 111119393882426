import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { profileActions } from '@actions'
import moment from 'moment'
import { Button, Divider } from 'antd'

function objectMap(object) {
  if(!object) return null
  return Object.keys(object).map(value => value !== 'history' && object[value] && <li className="desc-box__stat"><strong>{value.split('_').join(' ')}:</strong>{object[value]}</li>)
}

const returnBoolean = data => {
  if(!data) return false
  else if(data === 'No') return false
  else if(typeof data === 'number') return !!data
  else return true
}

const Img = ({
  type,
  data
}) => (
  <div className={`motorcheckimg ${type ? 'good' : 'bad'}`}>
    <span>{type ? '✅' : '❌' }</span>
    {data && <p>{data}</p>}
  </div>
)

const CarInfo = ({
  car,
  sale,
  getCheckInfo,
  detailedModal,
  handleMotorCheckDetailedModal,
  isMotorCheckModalOpen,
  loading,
}) => {

  const dispatch = useDispatch()
  const [modal, setModal] = useState(false)

  const handleMotorCheckModal = () => setModal(prev => !prev)

  const { is_followed, is_owner, id, lot } = car
  const makeName = car?.make?.data?.attributes?.title
  const modelName = car?.model?.data?.attributes?.title
  
  const handleFollow = e => {
    e.stopPropagation()
    dispatch(profileActions.follow(id))
  }

  useEffect(() => {
    if(isMotorCheckModalOpen) setModal(true)
  }, [isMotorCheckModalOpen])

  const handleUnfollow = e => {
    e.stopPropagation()
    dispatch(profileActions.unfollow(id))
  }

  const getMotorCheckInfo = () => {
    if (!car.motor_check_info || car.motor_check_info?.response?.error_message) {
      getCheckInfo()
    } else handleMotorCheckModal()
  };

  console.log(car)

  const { fuel_type, variant = '', engine = '' } = car;

  return (
    <>
      <div className="desc-box__header">
        <div className="desc-box-headline">
          <span>Lot: {sale?.lot || lot || ''}</span>
          <h1> {makeName} {modelName} {variant} {engine} {fuel_type}</h1>
        </div>
        { !is_owner && 
          <Button 
            onClick={ !is_followed ? handleFollow : handleUnfollow } 
            size="large" 
            style={{ borderColor: '#008170', color: is_followed ? '#fff' : '#008170'  }} 
            type={is_followed ? 'primary' : null} >
              { !is_followed ? 'Track' : 'Tracked' }
          </Button> 
        }
      </div>
      <Divider style={{ marginTop: '16px', marginBottom: '6px' }} />
      <ul className="desc-box__stats">
        <li className="desc-box__stat"><strong>Year:</strong> {car.car_year}</li>
        <li className="desc-box__stat"><strong>Fuel Type:</strong> {car.fuel_type}</li>
        <li className="desc-box__stat"><strong>Odometer:</strong> {car.odometer}</li>
        <li className="desc-box__stat"><strong>Body type:</strong> {car.body_type}</li>
        <li className="desc-box__stat"><strong>Engine:</strong> {car.engine}</li>
        <li className="desc-box__stat"><strong>NCT:</strong> {moment(car.nct_date).format('M/YY')}</li>
        <li className="desc-box__stat"><strong>Transmission:</strong> {car.transmition}</li>
        <li className="desc-box__stat"><strong>Colour:</strong> {car.color}</li>
      </ul>
      <Divider style={{ marginTop: '16px', marginBottom: '10px' }} />
      <ul className='desc-box__stats'>
        <li className="desc-box__stat"><strong>Dealer Type:</strong> {car.user_dealer_type}</li>
        <li className="desc-box__stat"><strong>Dealer Location:</strong> {car.user_location}</li>
      </ul>
      <Divider style={{ marginTop: '16px', marginBottom: '10px' }} />
        { !is_owner &&
          <Button
            onClick={getMotorCheckInfo}
            size="large"
            disabled={loading}
            style={{ borderColor: '#008170', color: is_followed ? '#fff' : '#008170'  }}
            type={is_followed ? 'primary' : null}>
              {'Motor Check'}
          </Button>
        }
        <div className={`motorcheck_modal ${modal ? 'active' : ''}`} onClick={handleMotorCheckModal}>
          <div className='card' onClick={e => e.stopPropagation()}>
            <div className='upper'>
              <p>Car History Check <strong>{car?.reg_number}</strong></p>
              <p className='car'>{car?.make?.data?.attributes?.title} {car?.model?.data?.attributes?.title}</p>
            </div>
            <ul className="desc-box__stats">
              <li className="desc-box__stat"><strong>"Write-Off" Check:</strong><Img type={!returnBoolean(car?.motor_check_info?.Response.Condition.WriteOff.WriteOff)} /></li>
              <li className="desc-box__stat"><strong>Odometer Check:</strong><Img type={true} /></li>
              <li className="desc-box__stat"><strong>"Taxi / Hackney" Check:</strong><Img type={!returnBoolean(car?.motor_check_info?.Response.UsageStatus.PrevTaxiHackney)} /></li>
              <li className="desc-box__stat"><strong>Stolen Check:</strong><Img type={!returnBoolean(car?.motor_check_info?.Response.Stolen.Stolen)} /></li>
              <li className="desc-box__stat"><strong>Finance Check:</strong><Img type={car?.motor_check_info?.Finance?.result === 'NO' || returnBoolean(car?.motor_check_info?.Finance?.details?.numeric_key)} /></li>
            </ul>
            {/* <p className='link'>Report Link: <a onClick={handleMotorCheckDetailedModal}>Click here to view your report</a></p> */}
          </div>
        </div>
        <div className={`motorcheck_modal motorcheck_modal-detailed ${detailedModal ? 'active' : ''}`} onClick={handleMotorCheckDetailedModal}>
          <div className='card' onClick={e => e.stopPropagation()}>
            <div className='upper'>
              <p>Detailed History for <strong>{car.reg_number}</strong></p>
              <p className='car'>{car?.motor_check_info?.Response.Vehicle.Description}</p>
            </div>
            <ul className="desc-box__stats">
              {/* <li className="desc-box__stat"><strong>"Write-Off" Check:</strong><Img type={returnBoolean(car?.motor_check_info?.Response.Condition.WriteOff.WriteOff)} /></li>
              <li className="desc-box__stat"><strong>Odometer Check:</strong><Img type={returnBoolean(car?.motor_check_info?.Response.Odometer.Records)} /></li>
              <li className="desc-box__stat"><strong>"Taxi / Hackney" Check:</strong><Img type={returnBoolean(car?.motor_check_info?.Response.UsageStatus.PrevTaxiHackney)} /></li>
              <li className="desc-box__stat"><strong>Stolen Check:</strong><Img type={returnBoolean(car?.motor_check_info?.Response.Stolen.Stolen)} /></li> */}
              <li className="desc-box__stat"><strong>NCT Expiry Date:</strong>{car?.motor_check_info?.Response.Vehicle.NCTExpiryDate}</li>
              <li className="desc-box__stat"><strong>Engine №:</strong>{car?.motor_check_info?.Response.Identification.EngineNo}</li>
              <li className="desc-box__stat"><strong>Chassis №:</strong>{car?.motor_check_info?.Response.Identification.ChassisNo}</li>
              <li className="desc-box__stat"><strong>Number of owners:</strong>{car?.motor_check_info?.Response.Owners.NoOfOwners}</li>
              <li className="desc-box__stat"><strong>Imported:</strong>{car?.motor_check_info?.Response.Origin.Imported}</li>
              <li className="desc-box__stat"><strong>Tax expiry date:</strong>{car?.motor_check_info?.Response.RoadTax.TaxExpiryDate || <Img />}</li>
              {objectMap(car?.motor_check_info?.Finance?.details?.numeric_key)}
            </ul>
          </div>
        </div>
    </>
  )
}

export default CarInfo
