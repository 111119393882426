import React from 'react'
import Header from '@components/Header'
import Footer from '@components/Footer'
import ContentWrapper from '@components/Markup/ContentWrapper'
import EditCar from '@components/EditCar'

const EditCarPage = () => {
  return (
    <ContentWrapper>
      <Header />
      <EditCar />
      <ContentWrapper.Sticky>
        <Footer />
      </ContentWrapper.Sticky>
    </ContentWrapper>
  )
}

export default EditCarPage
