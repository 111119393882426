import React from 'react'
import Modal from '@components/Modal'
import { useSelector, useDispatch } from 'react-redux'
import { paymentsActions } from '@actions'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { ArrowLeftOutlined } from '@ant-design/icons';

import { Button } from 'antd'

import './CardModal.scss'

const CardModal = () => {
  const stripe = useStripe();
  const elements = useElements(CardElement);
  const { car } = useSelector(state => state.carsReducer)
  const { donateModalVisible, isProcessing } = useSelector(state => state.paymentsReducer)
  const win_bid = car?.win_bid?.data?.attributes
  const dispatch = useDispatch()

  const euros = (win_bid?.fee / 100).toLocaleString("en-US", {style:"currency", currency:"EUR"});

  const style = {
    base: {
      fontSize: '18px',
      color: '#424770',
      lineHeight: '40px',
      borderRadius: '10px',
      '::placeholder': {
        color: '#aab7c4',
      },
      backgroundColor: '#f1f3f6',
      padding: '10% 10%',
    },
    invalid: {
      color: '#9e2146',
    },
  }

  const handlePay = (e) => {
    e.preventDefault()

    const billingDetails = {
      payment: {
        win_bid_id: win_bid.id
      }
    }

    const cardElement = elements.getElement(CardElement);

    dispatch(paymentsActions.donate({ billingDetails , cardElement, stripe }))
  }

  const handleCloseModal = () => dispatch(paymentsActions.toggleDonateModal())

  return (
    <Modal open={donateModalVisible} handleClose={handleCloseModal}>
      <div className="card-modal">
        <ArrowLeftOutlined onClick={handleCloseModal} style={{ fontSize: '20px' }} />
        <h1>Pay a fee</h1>
        <div  className="card-wrapper">
          <CardElement options={{ style, hidePostalCode: true }} />
        </div>
        <Button htmlType="submit" onClick={handlePay} style={{ marginTop: '10px', width: '100%', fontWeight: '600' }} disabled={isProcessing} size="large" type="primary" > {isProcessing ? 'Processing...' :  `Pay ${euros}`}</Button>
      </div>
    </Modal>
  )
}

export default CardModal