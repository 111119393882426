import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { carsActions } from '@actions'
import { Steps } from 'antd'
import { useWindowSize } from '@hooks/useWindowSize'

import ReConZone from '../SellCar/ReConZone';
import Photos from '../SellCar/Photos';
import ServiceHistory from '../SellCar/ServiceHistory';

const EditCar = () => {
  const { formStep } = useSelector(state => state.carsReducer)
  const { product_id } = useParams();
  const steps = [<ServiceHistory />, <Photos />, <ReConZone />]
  const size = useWindowSize()
  const dispatch = useDispatch()


  useEffect(() => {
    dispatch(carsActions.createCarSuccess({ id: product_id }))
  }, []);
  

  return (
    <div className="sell">
      { size.width > 1100 ?
        <Steps current={formStep} className="styled-steps">
          <Steps.Step title="Service History" />
          <Steps.Step title="Photos" />
          <Steps.Step title="ReCon Zone"  />
        </Steps> :
        <Steps current={formStep} className="styled-steps">
          <Steps.Step />
          <Steps.Step />
          <Steps.Step />
        </Steps>
      }
      {steps[formStep]}
    </div>
  )
}

export default EditCar
