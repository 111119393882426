import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from "react-hook-form"
import { carsActions } from '@actions'
import { Button } from 'antd'
import { useHistory } from 'react-router'
import BuyerForm from './BuyerForm'

const AddBuyers = () => {
  const { buyersForm } = useSelector(state => state.carsReducer)
  const { error } = useSelector(state => state.globalReducer)
  // const {formState} = useForm();
  const dispatch = useDispatch()

  const history = useHistory()

  const handleFinish = () => {
    history.push('/home')
  }

  const onSubmit = data => {
    dispatch(carsActions.createBuyer(data))
    // console.log(formState)
  }

  return (
    <div className="sell-form sell-main" >
      <h1>Add Extra Buyers</h1>
      <p style={{ textAlign: 'center', width: '95%', color: '#525252', margin: 'auto' }}>Fill in all the necessary information about your sellers. They will receive a link and will be able to join your garage.</p>

      { buyersForm?.map(num => (
        <BuyerForm onSubmit={onSubmit} number={num} disabled={num !== buyersForm.length} />
      )) }

      { error && <p className="error" style={{ textAlign: 'center' }}>{error}</p> }
      <Button onClick={handleFinish} style={{ padding: '10px 40px', lineHeight: '1', fontSize: '14px', fontWeight: '600', textTransform: 'uppercase' }} className="sell__submit" size="large" type="primary">FINISH</Button>
    </div>
  )
}

export default AddBuyers
