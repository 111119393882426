import React from 'react'
import './AboutUs.scss'
import '../header.scss'
import '../ContactUs/ContactUs.scss'
import midSizeCity1 from '@images/mid-size-city-1.png'
import ContactUs1 from "@images/ContactUs1.png"
import ContactUs1Mob from "@images/ContactUs1Mob.png"
import landingPageMob from '@images/landingPageMob.png'

const AboutUsPage = () => {
  return (
    <>
      <div className="how-it-works-title">About Us</div>
      {/* <img className="block-contact-us-img-mob" src={ContactUs1Mob} alt="block contact us img"/>
      <img className="block-info-right-img" src={ContactUs1} alt="block info right img"/> */}
      <div className="block-info">

      <div className="about-us">
        <div className="about-us__content">
        {/* <img className="img-landing" src={landingPageMob} alt="landing image"/> */}
        <h2 className="about-us__title">Who We Are ?</h2>
        <div className="block-about-us">
          <div className="description">
            <p>
            We are Motor trade. We have decades of experience working in Dealerships, running Main Dealerships, Used Car Dealerships, Trading Cars and dealing with Auction Houses both here and in the UK. So to say we understand the Trade from your perspective, would be spot on.
            </p>
            <h2 className="about-us__title" style={{ marginTop: '18px' }} >What We Do ?</h2>
            <p>Provide a platform to sell your trade cars and for you to buy cars if they fit your profile. We envision Dealers doing both. All cars stay with the selling Dealer until sold and paid for, to you, speeding up the whole process and minimising costs. The Buyer pays the Seller directly and collects the car once cleared funds are received. It’s faster and safer for everyone. For the Buyer, it gives access to Dealer stock across the Country from your mobile or desktop.</p>

          </div>
        </div>
        </div>
      </div>
      </div>
      <img className="block-info-left-img" src={midSizeCity1} alt="block info left img"/>
    </>
  )
}
export default AboutUsPage