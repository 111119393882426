import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { carsActions, carFilterActions } from '@actions'
import { Button } from 'antd';
import { useForm } from "react-hook-form";
import { getYears } from '@helpers';
import CustomSelect from '@components/CustomSelect';

const MainDetails = () => {
  const { body_types, tempCar, fuel_types, transmition_types, colors } = useSelector(state => state.carsReducer)
  const {makes, models, generations, series, trims } = useSelector(({carFilterReducer}) => carFilterReducer);

  const [selectedMake, setSelectedMake] = useState('');
  const [selectedModel, setSelectedModel] = useState('');
  const [selectedGeneration, setSelectedGeneration] = useState('');
  const [selectedSerie, setSelectedSerie] = useState('');
  const [selectedTrim, setSelectedTrim] = useState('');

  const { handleSubmit, register,  formState: { errors }, control } = useForm();
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(carFilterActions.getMakes());
  }, []);

  const handleMakeClick = value => {
    dispatch(carFilterActions.getModels(value));
  };

  const handleModelClick = value => {
    dispatch(carFilterActions.getGenerations(value));
  }

  const handleGenerationClick = value => {
    dispatch(carFilterActions.getSeries(selectedModel, value));
  }
 
  const handleSerieClick = value => {
    dispatch(carFilterActions.getTrims(selectedModel, value));
  }

  useEffect(() => {
    dispatch(carsActions.getBodyTypes())
    dispatch(carsActions.getFuelTypes())
    dispatch(carsActions.getTransmitionTypes())
    dispatch(carsActions.getColors())
  }, [])

  const handleMakeSelect = value => {
    console.log(value);
    const make_id = makes.filter(make => make.title === value)[0].id;
    handleMakeClick(make_id);
    setSelectedMake(make_id);
  }

  const handleModelSelect = value => {
    console.log(value);
    const model_id = models.filter(model => model.title === value)[0].id;
    handleModelClick(model_id);
    setSelectedModel(model_id);
  }

  const YEARS = getYears()
  const NCT_YEARS = getYears(2)
  const makesData = makes.map(make => make.title).sort();
  const modelsData = models.map(model => model.title).sort();

  const onSubmit = data => {
    console.log(data)
      dispatch(carsActions.createCar({
        ...data,
        make_id: selectedMake,
        model_id: selectedModel,
        nct_date: `${data.nct_month}/${data.nct_year}`,
        reg_number: data.reg_number.replace(/\s+/g, '').toLocaleUpperCase(),
      }))
  }

  return (
    <form className="sell-form sell-main" onSubmit={handleSubmit(onSubmit)}>
      <h1>Sell A Car</h1>
      <p style={{ textAlign: 'center' }}>Fill in all important info to help sell your car</p>

      <CustomSelect label="Make" control={control} placeholder="Choose a make from the list" name="make_id"   values={makesData}  onChange={handleMakeSelect} showSearch />

      <CustomSelect label="Model" control={control} placeholder="Choose the model from the list" name="model_id" values={modelsData} onChange={handleModelSelect} showSearch />

      <label className="sell-form__label">
        Variant
        <input { ...register('variant', { required: true }) } type="text" className="sell-form__input" placeholder="Comfortline, Signature, Elite, AMG Premium, Etc" style={{ fontSize: '14px', padding: '6px 10px' }} />
      </label>

      <label className="sell-form__label">
        Reg number
        <input { ...register('reg_number', { required: true }) } type="text" className="sell-form__input" placeholder="11DE819" style={{ fontSize: '14px', padding: '6px 10px', textTransform: 'uppercase' }} />
      </label>

      <div className="sell-form__label-wrapper"> 
        <CustomSelect label="Year" showSearch values={YEARS} control={control} placeholder="2000" name="car_year" />

        <CustomSelect label="Kms/Miles" values={[ 'kms', 'miles' ]} control={control} placeholder="miles" name="unit_type" />

        <label className="sell-form__label">
          Odometer
          <input  { ...register('odometer', { required: true }) } type="text" className="sell-form__input" placeholder="Odometer" style={{ fontSize: '14px', padding: '6px 10px' }} />
        </label>

      </div>

      <label className="sell-form__label">
        Engine
        <input { ...register('engine', { required: true }) } type="text" className="sell-form__input" placeholder="1.0/1.1/1.2/1.3/Etc" style={{ fontSize: '14px', padding: '6px 10px' }} />
      </label>

      <CustomSelect  label="Body type" control={control} placeholder="Choose a body type from the list" name="body_type">
        { body_types?.map(type => <Option value={type.key}>{type.key}</Option>) }
      </CustomSelect>


      {/* <CustomSelect label="Body type" values={seriesData} control={control} placeholder="Choose a body type from the list" name="serie_id" onChange={handleSerieSelect} /> */}

      {/* <CustomSelect label="Body type" values={seriesData} control={control} placeholder="Choose a body type from the list" name="serie_id" onChange={handleSerieSelect} />

      <CustomSelect label="Engine" values={trimsData} control={control} placeholder="Choose an engine from the list" name="trim_id" onChange={handleTrimSelect} /> */}

      {/* <CustomSelect label="Engine" values={[ 'Fuel', 'Disel', 'Electric', 'Hybrid' ]} control={control} placeholder="Choose the engine from the list" name="engine" /> */}

      {/* <CustomSelect label="Body type" name="body_type" control={control} placeholder="Choose the body type from the list"  >
          { body_types?.map(type => <Option value={type.key}>{type.key}</Option>) }
      </CustomSelect> */}


      <CustomSelect label="Fuel Type"control={control} placeholder="Choose a fuel type from the list" name="fuel_type">
        { fuel_types?.map(type => <Option value={type.key}>{type.key}</Option>) }
      </CustomSelect>


      <CustomSelect label="Transmission" control={control} placeholder="Choose a transmission type from the list" name="transmition">
        { transmition_types?.map(type => <Option value={type.key}>{type.key}</Option>) }
      </CustomSelect>

      <div className="sell-form__label-wrapper">
        <CustomSelect width="49%" label="Colour" control={control} placeholder="Choose a color" name="color">
          { colors?.map(type => <Option value={type.key}>{type.key}</Option>) }
        </CustomSelect>
        <label className="sell-form__label" style={{ width: '49%' }}>
          Reserve price
          <input { ...register('start_bid', { required: true }) } type="text" className="sell-form__input" placeholder="Reserve price" style={{ fontSize: '14px', padding: '6px 10px' }} />
        </label>
      </div>

      <div className="sell-form__label-wrapper"> 
        <CustomSelect width="49%" label="NCT Month" values={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]} control={control} placeholder="Month" name="nct_month" />
        <CustomSelect width="49%" label="NCT Year" showSearch values={NCT_YEARS} control={control} placeholder="2000" name="nct_year" />
      </div>
      <Button htmlType="submit" style={{ padding: '0 50px' }} className="sell__submit" size="large" type="primary">NEXT STEP</Button>
    </form>
  )
}

export default MainDetails
