import React from 'react'
import Home from '@components/Home'
import Header from '@components/Header'
import Footer from '@components/Footer'
import ContentWrapper from '@components/Markup/ContentWrapper'

const HomePage = () => {
  return (
    <ContentWrapper>
      <Header />
      <Home />
      <ContentWrapper.Sticky>
        <Footer />
      </ContentWrapper.Sticky>
    </ContentWrapper>
  )
}

export default HomePage
