import CARS_ACTIONS from './actions/cars';
import PROFILE_ACTIONS from './actions/profile';
import AUTH_ACTIONS from './actions/auth';
import GLOBAL_ACTIONS from './actions/global';
import MESSAGING_ACTIONS from './actions/messagingActions'
import PAYMENTS_ACTIONS from './actions/payments'
import CAR_FILTER_ACTIONS from './actions/carFilter';
import NOTIFICATIONS_ACTIONS from './actions/notifications';
import MY_CARS_TABS_ACTIONS from './actions/myCarsTabs';
import * as INITIAL_STATE from './initialState';

export const LOCAL_STORAGE = {
  AUTH_TOKEN: 'motobids_token',
  REFRESH_TOKEN: 'refresh_token',
  USER: 'motobids_user',
};

export const MY_CARS_TABS = {
  tracked: 'tracked',
  cars: 'cars',
  purchases: 'purchases',
  archive: 'archive',
};

export {
  CARS_ACTIONS,
  PROFILE_ACTIONS,
  AUTH_ACTIONS,
  GLOBAL_ACTIONS,
  MESSAGING_ACTIONS,
  PAYMENTS_ACTIONS,
  INITIAL_STATE,
  CAR_FILTER_ACTIONS,
  NOTIFICATIONS_ACTIONS,
  MY_CARS_TABS_ACTIONS,
};