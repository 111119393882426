import React from 'react'
import './HowItWorks.scss'
import '../header.scss'
import '../ContactUs/ContactUs.scss'
import midSizeCity1 from '@images/mid-size-city-1.png'
import ContactUs1 from "@images/ContactUs1.png"
import ContactUs1Mob from "@images/ContactUs1Mob.png"

const HowItWorks = () => {
  return (
    <>
      <div className="how-it-works-title">How it works</div>
      <img className="block-contact-us-img-mob" src={ContactUs1Mob} alt="block contact us img"/>
      <img className="block-info-right-img" src={ContactUs1} alt="block info right img"/>
      <div className="block-info">

        <div className="block-info-left">
          <div className="index-number">1</div>
          <div className="block-info-title">Create an account</div>
          <div className="block-info-description-top">You need to fill in all the necessary information <br></br>
            - Your email address and password.<br></br>
            - Your Garage Code.<br></br>
            {/* - Your Garage VAT Number & Bank Details.<br></br> */}
            - Your Authorised Employees To Use Our System.<br></br>
            You can always add a new buyer or remove one from your list.
          </div>

          <div className="index-number">2</div>
          <div className="block-info-title">Add your cars</div>
          <div className="block-info-description-top">
            Fill in as many details as possible about the car you want to sell.<br></br>
            - Upload your photos. Each photo box has its own description to ensure that all angles of the car are shown. You can then add in more photos to highlight extra features (panoramic roof, alloys, leather sports seats, etc.)<br></br>
            - All damage/repairs needed must be indicated on the Recon Zone page. This limits any complaints after purchase.
          </div>
        {/* </div> */}

        {/* <div className="block-info-right"> */}
          <div className="index-number">3</div>
          <div className="block-info-title">Auction</div>
          <div className="block-info-description-top">
          Irrespective of the car numbers for Auction, we hold three auctions per week, Monday, Wednesday and Friday, ending every two minutes from 10.30am. This shortens the SELL time from when you trade in a car. Once it’s entered by Midnight on the day before an Auction, it will be in the next days Auction.<br></br>
            - Cars once listed, are viewable for Buyers and the Bidding is live immediately.
          </div>
          <div className="index-number">4</div>
          <div className="block-info-title">Sale</div>
          <div className="block-info-description-top">
            Once a Buyer has successfully bid on a car, they pay MotoBids fee and pay the Seller directly for the car. Again this speeds up the SELL process for the Seller and Buyer shortening the time needed to have cleared funds in the sellers bank account and therefore “go ahead” to collect the car.<br></br>

            We have tried to keep the process as Simple, Fast, Safe and Easy as possible for both Sellers and Buyers. We are Trade, we have done this for years and know the ins & outs, up & downs of this business. 
          </div>

          {/* <div className="index-number-desc"> */}
            <div className="index-number">5</div>
            <div className="block-info-title">Advantages</div>
            <div className="block-info-description-top">
              - Easy to load ads direct from your mobile <br></br>
              - Three auctions per week to limit the wait time to sell your car<br></br>
              - Buyer pays Seller directly so safety of money for both and speed !<br></br>
              - No enforced transport usage. Arrange collection whatever way is quickest and easiest for you, the Buyer. Saves time and money for both Seller and Buyer.<br></br>
              - Example of car traded in on a Saturday. Quick wash, photos on mobile, Ad up from mobile in minutes, car listed for sale in Auction on Monday, sold at Auction on Monday, money EFT’d Monday, arriving Monday or Tuesday. Cleared Funds in your bank account. Ring Buyer to arrange collection on Monday or Tuesday !<br></br>
              - Simple, Safe, Fast & Easy !<br></br>
            </div>
          {/* </div> */}

          <div className="index-number">6</div>
          <div className="block-info-title">Buyers Fees &amp; Payment Terms:</div>
          <div className="block-info-description-top">
            Level 1 Up to sale price of €8,000- Fee €250 <br></br>
            Level 2 From sale price of €8,001 to €20,000-Fee €350<br></br>
            Level 3 From sale price of €20,001 to €50,000-Fee €450<br></br>
            Level 4 Any sale above €50,001-Fee €550<br></br>
            Payment by EFT only, on the same day as the Auction. All fees are plus vat.
          </div>

          {/* <div className="index-number-desc"> */}
            <div className="index-number">7</div>
            <div className="block-info-title">Sellers Fees &amp; Payment Terms:</div>
            <div className="block-info-description-top">
              No Fees ! No Cost ! No Risk !<br></br>
              The idea behind MotoBids is to make our platform easy to use, cost effective for Sellers and Buyers and to become the preferred way to sell your trade cars.<br></br>
              - Monies paid rapidly directly to the Seller ensuring 100% payment to the business.<br></br>
              - Vehicles only released when cleared funds are in your bank account.<br></br>
              - Fastest, safe clearance of trade cars from your forecourt.<br></br>
              - Access for Dealers nationwide to Dealers stock.<br></br>
              - If you need to remove a Car from the Auction, it can only be done by our administration. There is a small charge to cover our costs of €10 plus vat. Email us and we will organise it.<br></br>
            </div>
          {/* </div> */}

          {/* <div className="index-number-mob">
            <div className="index-number">5</div>
            <div className="block-info-title">Advantages</div>
            <div className="block-info-description-top">
              - Easy to load ads direct from your mobile <br></br>
              - Three auctions per week to limit the wait time to sell your car<br></br>
              - Buyer pays Seller directly so safety of money for both and speed !<br></br>
              - No enforced transport usage. Arrange collection whatever way is quickest and easiest for you, the     Buyer. Saves time and money for both Seller and Buyer.<br></br>
              - Example of car traded in on a Saturday. Quick wash, photos on mobile, Ad up from mobile in minutes, car listed for sale in Auction on Monday, sold at Auction on Monday, money EFT’d Monday, arriving Monday or Tuesday. Cleared Funds in your bank account. Ring Buyer to arrange collection on Monday or Tuesday !<br></br>
              - Simple, Safe, Fast & Easy !<br></br>
            </div>
          </div> */}

        </div>
      </div>
      <img className="block-info-left-img" src={midSizeCity1} alt="block info left img"/>
    </>
  )
}
export default HowItWorks;
