export const ROUTES = {
  LANDING: '/',
  MARKETPLACE: '/marketplace',
  LOGIN: '/login',
  REGISTER: '/register',
  PROFILE: '/profile',
  CARS: '/cars/:id',
  ADMIN_PANEL: '/admin',
  SELL: '/sell',
  HOME: '/home',
  CREATE_PROFILE:'/create_profile',
  HOW_IT_WORKS:'/how-it-works',
  CONTACT:'/contact',
  REGISTER_SUCCESS: '/register-success',
  AUCTIONS: '/auctions',
  NOTIFICATIONS: '/notifications',
  FAQ: '/faq',
  EDIT_CAR: '/edit/:product_id',
  JOIN_THE_GARAGE: '/join-the-garage',
  EDIT_PROFILE: '/edit-profile',
  FORGOT_PASSWORD: '/forgot-password',
  CHANGE_PASSWORD: '/passwords',
  TERMS: '/terms-of-use',
  PRIVACY_POLICY: '/privacy-policy',
  ABOUT_US: '/aboutus',
  USER_CONFIRMATION: '/user_confirmation/:confirm_token',
  CONFIRM_EMAIL: '/confirm-email',
}
