import moment from 'moment';

export const getYears = (plusYears) => {
  const years = [];
  const dateStart = moment().set('year', 1900);
  const dateEnd = plusYears ? moment().add(plusYears, 'years') : moment() 
  while (dateEnd.diff(dateStart, 'years') >= 0) {
    years.push(dateStart.format('YYYY'));
    dateStart.add(1, 'year');
  }
  return years.reverse();
};
