import { filterBlackList } from '@helpers';
import { INITIAL_STATE, PROFILE_ACTIONS } from '@constants';

const profileReducer = (state = INITIAL_STATE.ProfileInitialState, action) => {
  const {type, payload} = action || {};
  const {
    users = [], 
    user, 
    id, 
    followers,
    following,
    error, 
    total_pages, 
    usersMeta, 
    meta, 
    ratingUsers, 
    petsRatingUsersMeta, 
    servicesRatingUsersMeta, 
    user_id, 
    blackList } = payload || {};
  switch (type) {
    case PROFILE_ACTIONS.FOLLOW_SUCCESS: {
      const newUser = state.user;
      if (newUser && newUser.id === id) {
        newUser.is_followed = true;
        let count = newUser?.followers_count || 0;
        count += 1;
        newUser.followers_count = count;
      }
      const newUsers = [...state.users];
      newUsers.forEach((item, index) => {
        if (item && item.id === id) {
          newUsers[index].is_followed = true;
          let count = item?.followers_count || 0;
          count += 1;
          newUsers[index].followers_count = count;
        }
      });
      return {
        ...state,
        users: newUsers,
        user: newUser,
      };
    }
    case PROFILE_ACTIONS.UNFOLLOW_SUCCESS: {
      const newUser = state.user;
      if (newUser && newUser.id === id) {
        newUser.is_followed = false;
        let count = newUser?.followers_count || 1;
        count -= 1;
        newUser.followers_count = count;
      }
      const newUsers = [...state.users];
      newUsers.forEach((item, index) => {
        if (item && item.id === id) {
          newUsers[index].is_followed = false;
          let count = item?.followers_count || 1;
          count -= 1;
          newUsers[index].followers_count = count;
        }
      });
      return {
        ...state,
        users: newUsers,
        user: newUser,
      };
    }
    case PROFILE_ACTIONS.GET_FOLLOWERS:
      return {
        ...state,
        followersMeta: meta,
      };
    case PROFILE_ACTIONS.GET_FOLLOWERS_SUCCESS:
      return {
        ...state,
        followers,
        followersMeta: {
          ...state.followersMeta,
          total_pages,
        },
      };
    case PROFILE_ACTIONS.GET_FOLLOWERS_NEXT_PAGE_SUCCESS:
      return {
        ...state,
        followers: [...state.followers, ...followers],
        followersMeta: {
          ...state.followersMeta,
          total_pages,
        },
      };
    case PROFILE_ACTIONS.GET_FOLLOWING:
      return {
        ...state,
        followingMeta: meta,
      };
    case PROFILE_ACTIONS.GET_FOLLOWING_SUCCESS:
      return {
        ...state,
        following,
        followingMeta: {
          ...state.followingMeta,
          total_pages,
        },
      };
    case PROFILE_ACTIONS.GET_FOLLOWING_NEXT_PAGE_SUCCESS:
      return {
        ...state,
        following: [...state.following, ...following],
        followingMeta: {
          ...state.followingMeta,
          total_pages,
        },
      };
    case PROFILE_ACTIONS.BLOCK_SUCCESS: {
      const blackList = [Number(id)];
      const newUsers = filterBlackList({
        users: state.users,
        blackList,
        blockedMe: [],
      });
      const newRatings = filterBlackList({
        users: state.ratings,
        blackList,
        blockedMe: [],
      });
      const newFollowers = filterBlackList({
        users: state.followers,
        blackList,
        blockedMe: [],
      });
      const newFollowing = filterBlackList({
        users: state.following,
        blackList,
        blockedMe: [],
      });
      return {
        ...state,
        users: newUsers,
        ratings: newRatings,
        followers: newFollowers,
        following: newFollowing,
      };
    }
    case PROFILE_ACTIONS.UNBLOCK_SUCCESS: {
      let newList = state.user?.i_block_user_ids;
      let newBlackList = state.blackList.filter(el => el.id !== id);
      if (!Array.isArray(newList)) newList = [];
      const i = newList.indexOf(id);
      if (i !== -1) newList.splice(i, 1);
      const newUser = {...state.user, i_block_user_ids: newList};
      return {
        ...state,
        user: newUser,
        blackList: newBlackList
      };
    }
    case PROFILE_ACTIONS.GET_PETS_RATINGS:
      return {
        ...state,
        petsRatingUsersMeta,
      };
    case PROFILE_ACTIONS.GET_SERVICES_RATINGS:
      return {
        ...state,
        servicesRatingUsersMeta,
      };
    case PROFILE_ACTIONS.GET_PETS_RATINGS_SUCCESS:
      return {
        ...state,
        petsRatingUsers: ratingUsers,
        petsRatingUsersMeta: {
          ...state.petsRatingUsersMeta,
          total_pages
        }
      };
    case PROFILE_ACTIONS.GET_BLACK_LIST_SUCCESS:
        return {
          ...state,
          blackList,
        };
    case PROFILE_ACTIONS.BLOCK_USER_SUCCESS: {
      const newFollowers = [...state.followers].filter(
        (el) => el.id !== user_id,
      );
      const newFollowing = [...state.followers].filter(
        (el) => el.id !== user_id,
      );
      const newUsers = [...state.users].filter((el) => el.id !== user_id);
      const newPetsRatingUsers = [...state.petsRatingUsers].filter(
        (el) => el.id !== user_id,
      );
      const newServicesRatingUsers = [...state.servicesRatingUsers].filter(
        (el) => el.id !== user_id,
      );
      const newUserDetailsStack = [];
      state.userDetailsStack?.forEach((stackedUser) => {
        if (stackedUser.id === user_id)
          newUserDetailsStack.push({...stackedUser, blockedNow: true});
        else newUserDetailsStack.push(stackedUser);
      });
      return {
        ...state,
        users: newUsers,
        followers: newFollowers,
        following: newFollowing,
        servicesRatingUsers: newServicesRatingUsers,
        petsRatingUsers: newPetsRatingUsers,
        userDetailsStack: newUserDetailsStack,
      };
    }
    case PROFILE_ACTIONS.GET_PETS_RATINGS_NEXT_PAGE_SUCCESS:
      return {
        ...state,
        petsRatingUsers: [...state.petsRatingUsers, ...ratingUsers],
        petsRatingUsersMeta: {
          ...state.petsRatingUsersMeta,
          total_pages
        }
      };
      case PROFILE_ACTIONS.GET_SERVICES_RATINGS_SUCCESS:
        return {
          ...state,
          servicesRatingUsers: ratingUsers,
          servicesRatingUsersMeta: {
            ...state.servicesRatingUsersMeta,
            total_pages
          }
        };
      case PROFILE_ACTIONS.GET_SERVICES_RATINGS_NEXT_PAGE_SUCCESS:
        return {
          ...state,
          servicesRatingUsers: [...state.servicesRatingUsers, ...ratingUsers],
          servicesRatingUsersMeta: {
            ...state.servicesRatingUsersMeta,
            total_pages
          }
        };
    case PROFILE_ACTIONS.GET_USER_DETAILS_SUCCESS: {
      return {...state, user: user || state.user};
    }
    case PROFILE_ACTIONS.GET_USERS:
      return {
        ...state,
        usersMeta,
      };
    case PROFILE_ACTIONS.GET_USERS_SUCCESS:
      return {
        ...state,
        users,
        usersMeta: {...state.usersMeta, total_pages},
      };
    case PROFILE_ACTIONS.GET_USERS_NEXT_PAGE_SUCCESS: {
      const newUsers = [...state.users];
      const lastUsers = state.users.slice(-20).map((user) => user.id);
      // testArr.push(users.slice(-2));
      for (let i = 0; i < users.length; i += 1) {
        if (lastUsers.indexOf(users[i].id) === -1) {
          newUsers.push(users[i]);
        }
      }
      return {
        ...state,
        users: newUsers,
        usersMeta: {...state.usersMeta, total_pages},
      };
    }
    case PROFILE_ACTIONS.FOLLOW_ERROR:
    case PROFILE_ACTIONS.UNFOLLOW_ERROR:
    case PROFILE_ACTIONS.BLOCK_UNBLOCK_ERROR:
    case PROFILE_ACTIONS.GET_CURRENT_USER_ERROR:
    case PROFILE_ACTIONS.GET_USER_DETAILS_ERROR:
    case PROFILE_ACTIONS.UPLOAD_AVATAR_ERROR:
    case PROFILE_ACTIONS.UPDATE_PROFILE_ERROR:
    case PROFILE_ACTIONS.UPDATE_PASSWORD_ERROR:
    case PROFILE_ACTIONS.GET_FOLLOWERS_ERROR:
    case PROFILE_ACTIONS.GET_FOLLOWING_ERROR:
    case PROFILE_ACTIONS.GET_USERS_ERROR:
    case PROFILE_ACTIONS.GET_RATINGS_ERROR:
    case PROFILE_ACTIONS.REPORT_USER_ERROR:
    case PROFILE_ACTIONS.GET_BLACK_LIST_ERROR:
    case PROFILE_ACTIONS.SEND_DONATION_ERROR:
    case PROFILE_ACTIONS.GET_DONATIONS_ERROR:
      return {
        ...state,
        error,
      };
      case PROFILE_ACTIONS.LOGOUT_SUCCESS:
        return INITIAL_STATE.ProfileInitialState
    default:
      return state;
  }
};

export {profileReducer};