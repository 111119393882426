import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { carsActions } from '@actions';
import { Steps } from 'antd';
import { useWindowSize } from '@hooks/useWindowSize'
import './sellCar.scss'

import ReConZone from './ReConZone';
import Photos from './Photos/';
import ServiceHistory from './ServiceHistory';
import MainDetails from './MainDetails';

const SellCar = () => {
  const { formStep, product_id } = useSelector(state => state.carsReducer)
  const size = useWindowSize();
  const dispatch = useDispatch()

  const steps = [
    <MainDetails />,
    <ServiceHistory />,
    <Photos />,
    <ReConZone />
  ];

  const onBackToMain = () => {
    if(product_id) {
      dispatch(carsActions.setStep(0))
    }
  }

  return (
    <div className="sell">
      { size.width > 1100 ?
        <Steps current={formStep} className="styled-steps">
          <Steps.Step title="Main details"  />
          <Steps.Step title="Service History" />
          <Steps.Step title="Photos" />
          <Steps.Step title="ReCon Zone"  />
        </Steps> :
        <Steps current={formStep} className="styled-steps">
          <Steps.Step />
          <Steps.Step />
          <Steps.Step />
          <Steps.Step />
        </Steps>
      }
      {steps[formStep]}
    </div>
  )
}

export default SellCar