import React from 'react'
import { Input } from 'antd'
import { Controller } from 'react-hook-form'

const Field = ({ name, label, control, ...args }) => {
  return (
    <label>
      {label}
      <Controller 
        name={name}
        control={control}
        render={({ field }) => <Input style={{ borderColor: '#d9d9d9',  padding: '9px 16px' }} { ...field } { ...args } />}
      />
    </label> 
  )
}


export default Field