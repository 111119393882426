import React from 'react'
import './ContactUs.scss'
import ContactUs1 from "@images/ContactUs1.png"
import ContactUs1Mob from "@images/ContactUs1Mob.png"
import linkedin from "@images/linkedin.png"
import instagram from "@images/instagram.png"
import facebook from "@images/facebook.png"


const ContactUs = () => {
  return (
    <>
      <div className='block-contact-us'>
          <div className='description-title'>
            <div className='description'> keep in touch</div>
            <img className="block-contact-us-img-mob" src={ContactUs1Mob} alt="block contact us img"/>
            <div className='contact-us-title'>Contact us if you have any questions</div>

            <div>
              <div className='block-info-us'>
              <div className='block-info-left'>
                <div className='title-info-us'>
                  <div className='сontact-details-description' style={{ marginBottom: '5px' }}>
                    Email us
                  </div>
                  <div className='сontact-details'>
                    <a href="mailto:hello@motobids.ie">hello@motobids.ie</a> 
                  </div>
                  {/* <div className='сontact-details-description'>
                    Phone-Number
                  </div>
                  <div className='сontact-details'>
                    <a href="tel:045-906-480">045 906480</a>
                  </div> */}
                </div>
              </div>
              <div className='block-info-us-right'>
                <div className='title-info-us'>
                  <div className='сontact-details-description'>
                    Adress Details
                  </div>
                  <div className='сontact-details'>
                    Cirrus Suite <br></br>
                    The Atrium <br></br> 
                    John’s Lane  <br></br> 
                    Naas <br></br> 
                    Co Kildare <br></br> 
                    W91 WF21
                  </div>
                  <div className='сontact-details-description'>
                    {/* Our social media */}
                  </div>
                    {/* <div className='block-img-social-media'>
                      <img className="img-social-media" src={linkedin} alt="linkedin img"/>
                      <img className="img-social-media" src={facebook} alt="facebook img"/>
                      <img className="img-social-media" src={instagram} alt="instagram img"/>
                    </div> */}

                </div>
              </div>
            </div>
            </div>
          </div>
            <img className="block-contact-us-img" src={ContactUs1} alt="block contact us img"/>
      </div>
        </>
        )
        }
        export default ContactUs;

