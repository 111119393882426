import React, { useEffect } from 'react'
import actioncable from 'actioncable'
import { useDispatch, useSelector } from 'react-redux'
import { authActions, messagingActions } from '@actions'
import { getToken } from '@services/LocalStorage'
import { config } from '@config'

const PageWrapper = ({ children }) => {
  const { user } = useSelector(state => state.authReducer)
  const token = getToken()
  const dispatch = useDispatch()

  useEffect(() => {
   dispatch(authActions.isAuthenticated())
  }, [])

  useEffect(() => {
    if(token) {
      const userCable = actioncable.createConsumer(`${config.REACT_APP_WEBSOCKET_URL}?token=${token}`,)
      dispatch(messagingActions.webSocketConnection(userCable))
    }
  }, [token, user?.id])

  return children
}

export default PageWrapper
