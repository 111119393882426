import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, notification } from 'antd';
import { carsActions } from '@actions'
import { useParams, useHistory } from 'react-router';
import { scrollToTheTop } from '@helpers'

import CarDetailTabs from './CarDetailTabs';
import CarSale from './CarSale';
import CarInfo from './CarInfo';
import CarPhotos from './CarPhotos';
import RelatedCars from './RelatedCars';
import ReconZones from './ReconZones';

import './CarDetail.scss'
import PhotosModal from './PhotosModal';
import CardModal from '@components/CardModal';
import MotorCheckInfoModal from './MotorCheckInfoModal';

const CarDetail = () => {
  const { id } = useParams()
  const { location } = useHistory();
  const { car, error, isMotorCheckModalOpen } = useSelector(state => state.carsReducer)
  const { loading } = useSelector(state => state.globalReducer);
  const [openModal, setOpenModal] = useState(false)
  const [detailedModal, setDetailedModal] = useState(false)

  const dispatch = useDispatch()
  
  useEffect(() => {
    const {state} = location || {};
      dispatch(carsActions.getCarInfo(id, state?.isPurchased))
      scrollToTheTop()

	  return () => {
		dispatch(carsActions.cleanCarInfo())
	  }
  }, [id])

  const onClose = () => {
    dispatch(carsActions.getMotorCheckInfoError());
  };

  useEffect(() => {
    if (error)
      notification.error({
        message: 'Error',
        description: error,
        onClose,
      });
  }, [error]);

  const handleGetMotorCheckInfo = () => {
    dispatch(carsActions.getMotorCheckInfo(id));
  };

  const handleMotorCheckModal = () => {
    dispatch(carsActions.toggleMotorCheckModal(true));
  };

  const handleMotorCheckDetailedModal = useCallback(() => {
    setDetailedModal(prev => !prev)
  }, [setDetailedModal])

  return car ? (
    <div className="car-detail">
      <div className="car-preview">
        <CarPhotos attachments={car?.attachments?.data} setOpenModal={setOpenModal} />
        <div className="desc-box car-preview__description">
          <CarInfo
            car={car}
            sale={car?.sale?.data?.attributes}
            detailedModal={detailedModal}
            isMotorCheckModalOpen={isMotorCheckModalOpen}
            getCheckInfo={handleGetMotorCheckInfo}
            handleMotorCheckDetailedModal={handleMotorCheckDetailedModal}
            loading={loading}
          />
          {/* <Alert
            message="Error"
            description={error}
            type="error"
            closable
            onClose={onClose}
          /> */}
          { (car?.sale?.data || car?.has_winner || car?.win_bid?.data ) && <CarSale sale={car?.sale?.data?.attributes} hasWinner={car.has_winner} winBid={car?.win_bid} /> }
          <PhotosModal open={openModal} id={id} setOpenModal={setOpenModal} />
        </div>
      </div>
      <div className="car-characteristics">
        <ReconZones reconZone={car?.recon_zone?.data?.attributes} />
        <CarDetailTabs reconZone={car?.recon_zone?.data?.attributes} serviceHistory={car?.service_history?.data?.attributes} />
      </div>
      <RelatedCars />
      <CardModal />
    </div>
  ) : <p>loading...</p>
}

export default CarDetail
