import React from 'react'
import CreateProfile from '@components/CreateProfile'
import Header from '@components/Header'
import Footer from '@components/Footer'
import ContentWrapper from '@components/Markup/ContentWrapper'

const CreateProfilePage = () => {
  return (
    <ContentWrapper>
      <Header />
      <CreateProfile />
      <ContentWrapper.Sticky>
        <Footer />
      </ContentWrapper.Sticky>
    </ContentWrapper>
  )
}

export default CreateProfilePage
