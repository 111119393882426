import React from 'react'
import Header from '@components/Header'
import Footer from '@components/Footer'
import ContentWrapper from '@components/Markup/ContentWrapper'
import Terms from '../components/Terms'

const SellCarPage = () => {
  return (
    <ContentWrapper>
      <Header />
      <Terms />
      <ContentWrapper.Sticky>
        <Footer />
      </ContentWrapper.Sticky>
    </ContentWrapper>
  )
}

export default SellCarPage