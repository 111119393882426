import React from 'react'
import {Button} from 'antd';
import createProfile from '@images/createProfile.png'
import bitmapLeft from '@images/bitmapLeft.png'
import bitmapRight from '@images/bitmapRight.png'

const FinallySteps = () => {
  return (
    <form>
      <div className="finally-step">
        <div className="final-step-description">
          You have successfully created an account
        </div>
        <div className="final-step-title">
          Choose your next step
        </div>
        <div className="steps-block">
        <div className="img-steps-block">
          <img className="img-steps-right" src={bitmapRight} alt="final step image"/>
          <div className='let’s-continue'>
            <Button style={{padding: '0 50px'}} className="sell__submit" size="large" type="primary">Finish</Button>
          </div>
        </div>
        <div className="img-button-block">
          <img className="img-steps-left" src={bitmapLeft} alt="final step image"/>
          <div className='let’s-continue'>
            <Button style={{padding: '0 50px'}} className="sell__submit" size="large" type="primary">Buy a Car</Button>
          </div>
        </div>
        </div>
      </div>
      <img className="img-create-account" src={createProfile} alt="create account image"/>
    </form>

  )
}

export default FinallySteps
