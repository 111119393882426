import React, {useState} from 'react'
import { useDispatch } from 'react-redux';
import { profileActions } from '@actions';
import { useForm } from "react-hook-form";
import { Button } from 'antd';
import { DEALER_TYPES, LOCATION_TYPES } from '@mainConstants';
import CustomSelect from '@components/CustomSelect';

const DealerDetails = () => {
  const [selectedDealerType, setDealerType] = useState('');
  const [selectedLocation, setLocation] = useState('');
  const { handleSubmit, register,  formState: { errors }, control, setValue, clearErrors } = useForm();
  const dispatch = useDispatch()

  const onSubmit = data => {
    dispatch(profileActions.updateProfile({ 
      ...data,
      dealer_type: selectedDealerType,
      location: selectedLocation,
      is_finished_sign_up: true 
    }, true))
  }

  const onChangeDealerType = value => {
    const typeId = DEALER_TYPES.filter(type => type.title === value)[0].value;

    setDealerType(typeId);
    setValue('dealer_type', value);
    clearErrors('dealer_type');
  }

  const onChangeLocation = value => {
    const typeId = LOCATION_TYPES.filter(type => type.title === value)[0].value;

    setLocation(typeId);
    setValue('location', value);
    clearErrors('location');
  }

  const dealerTypes = DEALER_TYPES.map(type => type.title);
  const locations = LOCATION_TYPES.map(location => location.title);

  return (
    <form className="sell-form sell-main" onSubmit={handleSubmit(onSubmit)}>
      <h1>Dealer details</h1>
      <p style={{ textAlign: 'center', width: '315px', color: '#525252', margin: 'auto' }}>Register now for access to Buy and/or Sell Trade vehicles within the Trade only!</p>
      <label className="sell-form__label" style={{ marginTop: '24px' }} >
        Official business name
        <input { ...register('business_name', { required: true }) } type="text" className="sell-form__input"/>
      </label>
      { errors.business_name && <p className="error">Bussiness name is required</p> }
      <label className="sell-form__label" style={{ marginTop: '24px' }} >
        Phone
        <input { ...register('phone_number', { required: true }) } type="tel" className="sell-form__input"/>
      </label>
      { errors.phone_number && <p className="error">Phone is required</p> }
      <label className="sell-form__label" style={{ marginTop: '24px' }} >
        Name
        <input { ...register('name', { required: true }) } type="text" className="sell-form__input"/>
      </label>
      { errors.name && <p className="error">Name is required</p> }
      <label className="sell-form__label" style={{ marginTop: '24px' }} >
        Job Title
        <input { ...register('title', { required: true }) } type="text" className="sell-form__input"/>
      </label>
      { errors.title && <p className="error">Job Title is required</p> }
      <label className="sell-form__label" >
        Address
        <input { ...register('address', { required: true }) } type="text" className="sell-form__input"/>
      </label>
      { errors.address && <p className="error">Address is required</p> }
      <label className="sell-form__label" >
        Eircode
        <input { ...register('eircode', { required: true }) } type="text" className="sell-form__input"/>
      </label>
      { errors.eircode && <p className="error">Eircode is required</p> }
      <CustomSelect
        label="Dealer Type"
        control={control}
        placeholder="Dealer Type"
        name="dealer_type"
        values={dealerTypes}
        onChange={onChangeDealerType}
        required
      />
      { errors.dealer_type && <p className="error">Dealer Type is required</p> }
      <CustomSelect
        label="Location"
        control={control}
        placeholder="Location"
        name="location"
        values={locations}
        onChange={onChangeLocation}
        required
      />
      { errors.location && <p className="error">Location is required</p> }
      {/* <label className="sell-form__label" >
        Iban
        <input { ...register('iban', { required: true }) } type="text" className="sell-form__input"/>
      </label>
      { errors.eircode && <p className="error">Iban is required</p> }
      <label className="sell-form__label" >
        Bank name
        <input { ...register('bank_name', { required: true }) } type="text" className="sell-form__input"/>
      </label>
      { errors.bank_name && <p className="error">Bank name is required</p> }
      <label className="sell-form__label" >
        Bank address
        <input { ...register('bank_address', { required: true }) } type="text" className="sell-form__input"/>
      </label>
      { errors.bank_address && <p className="error">Bank address is required</p> } */}
      <Button htmlType="submit" style={{ padding: '10px 40px', lineHeight: '1', fontSize: '14px', fontWeight: '600', textTransform: 'uppercase' }} className="sell__submit" size="large" type="primary">Let’s continue</Button>
    </form>
  )
}

export default DealerDetails
