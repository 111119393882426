import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import moment from 'moment'
import { Tabs } from 'antd'

import carSideImg from '@images/car-side.png'
import carLeftImg from '@images/car-left.png'
import carFbImg from '@images/car-fb.png'
import carTopImg from '@images/car-top.png'

const CarDetailTabs = ({ reconZone, serviceHistory }) => {
  const parts = {}

  const reconZones = useSelector(state => state.carsReducer.car?.recon_zone?.data)
  const [warningClass, setWarningClass] = useState([]);
  
  reconZones?.attributes?.parts.data.forEach(part => {
    parts[part.attributes.side + '_' + part.attributes.side_number] = part.attributes
  })

  const sides = [
    { key: 1, name: 'RIGHT SIDE', img: carSideImg, parts: [ parts.right_1, parts.right_2, parts.right_3, parts.right_4 ], sum: reconZone?.right_damage_info },

    { key: 2, name: 'LEFT SIDE', img: carLeftImg, parts: [ parts.left_1, parts.left_2, parts.left_3, parts.left_4 ], sum: reconZone?.left_damage_info },

    { key: 3, name: 'FRONT AND BACK SIDE', img: carFbImg, parts: [ parts.front_and_back_1, parts.front_and_back_2, parts.front_and_back_3, parts.front_and_back_4 ], sum: reconZone?.front_back_damage_info },

    { key: 4, name: 'TOP VIEW', img: carTopImg, parts: [ parts.top_1, parts.top_2, parts.top_3, parts.top_4 ], sum: reconZone?.top_damage_info }
  ]

  useEffect(() => {
    sides.map((side, i) => {
      console.log(side)
      console.log(i)
      if(side.parts.map(part => part?.damage ? true : false).includes(true)) {
        setWarningClass(prevWarning => [...prevWarning, `tab--warning-${i}`])
      }
    })

    return () => {
      setWarningClass([]);
    }
  }, [reconZones?.attributes?.parts]);
  
  
  // const renderTabBar = 

  return parts ? (
    <div className="car-characteristics__info" >
     { serviceHistory && <div className="desc-box" style={{ marginBottom: '24px' }}>
          <h2>Service History</h2>
          <ul className="desc-box__stats--column zone-2">
              <li className="desc-box__stat"><strong>Type:</strong> {serviceHistory?.history_type.split('_').join(" ")}</li>
              { (serviceHistory?.last_service && serviceHistory?.odometer)  && <li className="desc-box__stat"><strong>Date and odometer:</strong>  {moment(serviceHistory?.last_service).format('MM/YY')} {serviceHistory?.odometer}</li> }
          </ul>
        </div>
      }
      <div className={`styled-tabs ${warningClass?.length ? 'tab--warning' : ''} ${warningClass.join(' ')}`}>
        {/* {{ backgroundColor: `${side.parts.map(part => part.damage ? true : false).includes(true) ? 'red' : 'inherit'}`}} */}
      
      <Tabs defaultActiveKey="1" type="card" >
        { sides.map((side, i) => {
          const j = i !== 0 ? i * 4 : 0;

          console.log();

          return (
            <Tabs.TabPane tab={side.name} key={side.key}>
              <div className="desc-container">
                { side?.parts.length && <div className="desc-container__image" >
                  <img className="side-img" src={side.img} alt="car side image" />
                  <div className={`desc-container__circle desc-container__circle-${j + 1} ${side.parts[0]?.damage && 'circle--warning' }`}>1</div>
                  <div className={`desc-container__circle desc-container__circle-${j + 2} ${side.parts[1]?.damage && 'circle--warning' }`}>2</div>
                  <div className={`desc-container__circle desc-container__circle-${j + 3} ${side.parts[2]?.damage && 'circle--warning' }`}>3</div>
                  <div className={`desc-container__circle desc-container__circle-${j + 4} ${side.parts[3]?.damage && 'circle--warning' }`}>4</div>
                </div> }
                <ul className='desc-box__stats'>
                  {side.parts.map((part, i) => (
                    <li className="desc-box__stat"><strong>{i + 1}:  </strong>{part?.damage?.split('_').join(' ') || 'all okay'}</li> ))}
                </ul>
                { side?.sum && <div className="desc-sum">
                  <h4>Summarise damage to car:</h4>
                  <p>{ side.sum }</p>
                </div> }
              </div>
          </Tabs.TabPane>
          )
    }) }

      </Tabs>
      </div>
    </div>
  ) : <p>Loading...</p>
}

export default CarDetailTabs
