import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { messagingActions } from '@actions'
import { Button, Input, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useForm } from 'react-hook-form'
import Countdown from 'react-countdown';
import moment from 'moment'
import CarWinner from './CarWinner'
import useTimeLeft from '../../hooks/useTimeLeft'

const CarSale = ({ sale, hasWinner }) => {
  const [completed, setCompleted] = useState(false)
  const { handleSubmit, register, watch, formState: { errors }, setValue, getValues } = useForm();
  const user = useSelector(state => state.authReducer.user)
  const win_bid  = useSelector(state => state.carsReducer.car?.win_bid)
  const win = win_bid?.data ? win_bid?.data?.attributes : null
  const { is_owner }  = useSelector(state => state.carsReducer.car)
  const { cbs } = useSelector(state => state.messagingReducer)
  const dispatch = useDispatch()
  const timeLeft = useTimeLeft(sale?.end_date)

  const [auctionDone, setAuctionDone] = useState((moment(sale?.end_date).isBefore() || hasWinner || win?.winner_id))
  const auctionPrebid = moment(sale?.start_date).isAfter() && !completed
  const auctionLive = !auctionDone && !auctionPrebid

  useEffect(() => setAuctionDone((moment(sale?.end_date).isBefore() || hasWinner || win?.winner_id)), [sale, win])

  useEffect(() => {
    // array of cable ids 
    const ids = Object.keys(cbs).map(key => key.split('_')?.[1])

    if(auctionLive) {
      console.log('auction is live')
    }

    // if(ids.includes(`${sale?.id}`)) {
    //   dispatch(messagingActions.deleteSub(cbs[`cb_${sale?.id}`], sale?.id))
    // }

    if(!ids.includes(`${sale?.id}`)) {
      dispatch(messagingActions.createSub(sale?.id, dispatch))
    }

    // return () => {
    //   dispatch(messagingActions.deleteSub(cbs[`cb_${sale?.id}`], sale?.id))
    // }
  }, [sale?.id, auctionLive])
  

  useEffect(() => {
    setValue('amount', sale?.current_live_bid + 100)
  }, [sale?.current_live_bid])

  useEffect(() => {
    if (timeLeft < 0 && !hasWinner && !win?.winner_id) {
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
  }, [timeLeft]);

  useEffect(() => {
    setValue('max_amount', 0)
  }, [])

  const putBid = (amount) => {
    if(cbs[`cb_${sale?.id}`]) {
      cbs[`cb_${sale?.id}`].perform('speak', { amount: amount, sale_id: sale?.id, bid_by: user.id, bid_type: 'live' })
    } else {
      console.log('no cb')
    }
  }

  const putMaxBid = (amount) => {
    if(cbs[`cb_${sale?.id}`]) {
      console.log('MAXBID')
      cbs[`cb_${sale?.id}`].perform('speak', { amount: amount, sale_id: sale?.id, bid_by: user.id, bid_type: 'max_price' })
    } else {
      console.log('no cb')
    }
  }

  const onSubmit = data => {
    putBid(parseInt(data.amount))
  }

  const onMaxSubmit = data => {
    putMaxBid(parseInt(data.max_amount))
  }

  const onPreBid = data => {
    dispatch(carsActions.preBid(parseInt(data.amount), sale.id, ))
  }

  const handleChange = e => {
    setValue(e.target.name, parseInt(e.target.value))
  }

  return auctionDone  ? (
    // If auction is already done
    <>
      <CarWinner />
    </>
  ) : // Auction in prebid stage
  auctionPrebid ? (
    <form onSubmit={handleSubmit(onPreBid)}>
        <h3 className="countdown__headline">Auction start in</h3>
        <div className="countdown"><Countdown onComplete={() => setCompleted(true)} date={timeLeft} controlled /></div>
        <h2 style={{ fontFamily: 'Montserrat', fontSize: '16px', color: '#008170', margin: '33px 0 16px 0' }}>Reserved Price:  € {sale?.reserved_price}</h2>
        { !is_owner && 
        <>
        <Input
          className="bid-input"
          type="number"
          style={{ borderColor: '#d9d9d9', padding: '9px 16px', maxWidth: '65%', marginRight: '16px'}}
          suffix={
            <Tooltip title={`Your bid should be at least €100 higher than current bid (${sale?.current_bid + 100}). You can use arrows on your keyboard to change the bid`}>
              <InfoCircleOutlined style={{ color: 'rgba(0,129,112, 0.8)' }} />
            </Tooltip>
          }
          value={watch('amount')}
          { ...register('amount', { required: true, min: sale?.current_bid + 100 }) }
          onChange={handleChange}
        /> 
        <Button disabled={sale?.current_bid_user_id === user?.id} htmlType="submit" size="large" type="primary" style={{ fontWeight: '500' }}>BID NOW</Button>
        </>
        }
      { errors.amount && <p style={{ color: '#ff0000', marginTop: '8px' }}>Your bid should be at least €100 higher than current bid ({sale?.current_bid + 100})</p> 
      }

    </form>
  ) : auctionLive && <div>
    <div className="countdown">
      {/* <Countdown date={sale?.created_at ? moment(sale?.created_at).add(29, 'seconds') : moment(timeLeft) controlled.add(30, 'seconds')} /> */}
      <Countdown date={timeLeft} controlled />
      </div>
    <h2 style={{ fontFamily: 'Montserrat', fontSize: '16px', color: '#008170', margin: '33px 0 16px 0' }}>Current Bid: € {sale?.current_live_bid}</h2>
     { !is_owner &&  
     <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          className="bid-input"
          type="number"
          name='amount'
          style={{ borderColor: '#d9d9d9', padding: '9px 16px', maxWidth: '65%', marginRight: '16px'}}
          suffix={
            <Tooltip title={`Your bid should be at least €100 higher than current bid (${sale?.current_live_bid + 100}). You can use arrows on your keyboard to change the bid`}>
              <InfoCircleOutlined style={{ color: 'rgba(0,129,112, 0.8)' }} />
            </Tooltip>
          }
          value={watch('amount')}
          { ...register('amount', { required: true, min: sale?.current_live_bid + 100 }) }
          onChange={handleChange}
        /> 
        <Button disabled={sale?.current_live_bid_user_id == user?.id || sale?.current_max_price_user_id == user?.id && sale?.current_live_bid < sale?.current_max_price} htmlType="submit" size="large" type="primary" style={{ fontWeight: '500' }}>BID NOW</Button>
      </form>
      <form onSubmit={handleSubmit(onMaxSubmit)} style={{marginTop: 15}}>
        <Input
          className="bid-input"
          type="number"
          name='max_amount'
          style={{ borderColor: '#d9d9d9', padding: '9px 16px', maxWidth: '65%', marginRight: '16px'}}
          // suffix={
          //   <Tooltip title={`Your bid should be at least €100 higher than current max bid (${sale?.current_max_price + 100}). You can use arrows on your keyboard to change the bid`}>
          //     <InfoCircleOutlined style={{ color: 'rgba(0,129,112, 0.8)' }} />
          //   </Tooltip>
          // }
          value={watch('max_amount')}
          { ...register('max_amount', { required: true, min: 0 }) }
          onChange={handleChange}
        /> 
        <Button disabled={sale?.current_max_price_user_id == user?.id && sale?.current_live_bid < sale?.current_max_price} htmlType="submit" size="large" type="primary" style={{ fontWeight: '500' }}>MAX BID</Button>
      </form>
      </>
      }
      { errors.amount && <p style={{ color: '#ff0000', marginTop: '8px' }}>Your bid should be at least €100 higher than current bid ({sale?.current_bid + 100})</p> 
      }
  </div>
}

export default CarSale
