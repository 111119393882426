import {CAR_FILTER_ACTIONS, INITIAL_STATE} from '@constants';

const carFilterReducer = (state = INITIAL_STATE.CAR_FILTER_STORE, action) => {
  const {type, payload} = action || {};
  const {makes, models, make_id, filter, generations, series, trims, error} = payload || {};
  switch (type) {
    case CAR_FILTER_ACTIONS.GET_MAKES_SUCCESS:
      return {
        ...state,
        makes,
      };
    case CAR_FILTER_ACTIONS.GET_MODELS:
      return {
        ...state,
        make_id,
      };
    case CAR_FILTER_ACTIONS.GET_MODELS_SUCCESS:
      return {
        ...state,
        models,
      }

    case CAR_FILTER_ACTIONS.GET_GENERATIONS_SUCCESS: 
      return {
        ...state,
        generations
      }

    case CAR_FILTER_ACTIONS.GET_SERIES_SUCCESS: 
      return {
        ...state,
        series
      }
    case CAR_FILTER_ACTIONS.GET_TRIMS_SUCCESS: 
      return {
        ...state,
        trims
      }
    case CAR_FILTER_ACTIONS.GET_MAKES_ERROR:
    case CAR_FILTER_ACTIONS.GET_MODELS_ERROR:
      return {
        ...state,
        error,
      }
    case CAR_FILTER_ACTIONS.FILTER_SELECT:
      return {
        ...state,
        filter,
      };
    case CAR_FILTER_ACTIONS.CLEAR_FILTER:
      return {
        ...state,
        filter: state.filter,
      };
    default:
      return state;
  }
};

export {carFilterReducer};