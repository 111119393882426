import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { paymentsActions, carsActions } from '@actions'
import { Button, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

const CarWinner = () => {
  const { car } = useSelector(state => state.carsReducer)
  const { user } = useSelector(state => state.authReducer)

  const handleGetFeeInvoice = () => {
    if(win?.id) {
      dispatch(carsActions.getFeeInvoice(win?.id))
    } else {
      console.error('No win_bid id')
    }
  }
  const win = car?.win_bid?.data?.attributes

  const dispatch = useDispatch()

  const handleOpenModal = () => {
    dispatch(paymentsActions.toggleDonateModal())
  }

  const handleGetInvoice = () => {
    if(win?.id) {
      dispatch(carsActions.getInvoice(win?.id))
    } else {
      console.error('No win_bid id')
    }
  }

  return win?.winner_id == user?.id && win?.is_accepted && !win?.is_paid ?
    <div className="car-winner">
      <h4>You have won for {win?.amount} €</h4>
      <p>You must pay the MotoBids Platform Fee now and then we will email you the Sellers Info for payment (within 24hrs) & collection</p>
      {!win?.is_paid && <><Button onClick={handleOpenModal} size="large" type="primary">PAY FEE</Button>
      <Button onClick={handleGetFeeInvoice} size="large" type="primary">PRINT FEE</Button></>}
      <Tooltip 
        title={`text`}>
              <InfoCircleOutlined style={{ color: 'rgba(0,129,112, 0.8)' }} />
      </Tooltip>
          
    </div>
    : win?.winner_id == user?.id && win?.is_accepted && win?.is_paid ? 
      <div className="car-winner" style={{ paddingLeft: '16px' }}>
        <p><strong>Purchase Date:</strong> </p>
        <p><strong>Buyer:</strong> {win?.winner?.data?.attributes?.user_name}</p>
        <h4>You have won for € {win?.amount}</h4>
        <h4>Check your e-mail to see info about the car and the seller.</h4>
        {/* <Button onClick={handleGetInvoice} style={{ width: 'auto', marginBottom: '9px' }} size="large" type="primary">GET INVOICE</Button>  */}
        <Tooltip 
          title={`text`}>
                <InfoCircleOutlined style={{ color: 'rgba(0,129,112, 0.8)' }} />
        </Tooltip>
      </div>
    : win?.winner_id == user?.id && !win?.is_accepted ? 
    <div className="car-winner" style={{ paddingLeft: '16px' }}>
      <h4>You have won for € {win?.amount}</h4>
      <p>You will be able to proceed after the owner accepts your bid.</p>
      <Button style={{ width: 'auto', marginBottom: '9px' }} size='large' type='primary'><Link to='/marketplace'>BACK TO LIST OF CARS</Link></Button>
    </div> 
    :
    <div className="car-winner" style={{ paddingLeft: '16px' }}>
      <h4 style={{ fontSize: '28px'}}>Auction is over</h4>
      <p style={{ fontSize: '18px', fontWeight: '600', color: '#1f8c7b' }}>Final Bid: € {win?.amount}</p>
      <Button style={{ width: 'auto', marginBottom: '9px' }} size='large' type='primary'><Link to='/marketplace'>BACK TO LIST OF CARS</Link></Button>
    </div>
}

export default CarWinner
