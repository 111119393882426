import {CAR_FILTER_ACTIONS} from '@constants'

export const getMakes = () => ({
  type: CAR_FILTER_ACTIONS.GET_MAKES,
});

export const getMakesSuccess = makes => ({
  type: CAR_FILTER_ACTIONS.GET_MAKES_SUCCESS,
  payload: {makes},
});

export const getMakesError = error => ({
  type: CAR_FILTER_ACTIONS.GET_MAKES_ERROR,
  payload: {error},
});

export const getModels = make_id => ({
  type: CAR_FILTER_ACTIONS.GET_MODELS,
  payload: {make_id},
});

export const getModelsSuccess = models => ({
  type: CAR_FILTER_ACTIONS.GET_MODELS_SUCCESS,
  payload: {models},
});

export const getModelsError = error => ({
  type: CAR_FILTER_ACTIONS.GET_MODELS_ERROR,
  payload: {error},
});

export const getGenerations = (model_id) => ({
  type: CAR_FILTER_ACTIONS.GET_GENERATIONS,
  payload: { model_id }
});

export const getGenerationsSuccess = (generations) => ({
  type: CAR_FILTER_ACTIONS.GET_GENERATIONS_SUCCESS,
  payload: { generations }
});

export const getGenerationsError = (error) => ({
  type: CAR_FILTER_ACTIONS.GET_GENERATIONS_ERROR,
  payload: { error }
});

export const getSeries = (model_id, generation_id) => ({
  type: CAR_FILTER_ACTIONS.GET_SERIES,
  payload: { model_id, generation_id }
});

export const getSeriesSuccess = (series) => ({
  type: CAR_FILTER_ACTIONS.GET_SERIES_SUCCESS,
  payload: { series }
});

export const getSeriesError = (error) => ({
  type: CAR_FILTER_ACTIONS.GET_SERIES_ERROR,
  payload: { error }
});

export const getTrims = (model_id, serie_id) => ({
  type: CAR_FILTER_ACTIONS.GET_TRIMS,
  payload: { model_id, serie_id }
});

export const getTrimsSuccess = (trims) => ({
  type: CAR_FILTER_ACTIONS.GET_TRIMS_SUCCESS,
  payload: { trims }
});

export const getTrimsError = (error) => ({
  type: CAR_FILTER_ACTIONS.GET_TRIMS_ERROR,
  payload: { error }
})

export const filterSelect = filter => ({
  type: CAR_FILTER_ACTIONS.FILTER_SELECT,
  payload: {filter},
});

export const clearFilter = () => ({
  type: CAR_FILTER_ACTIONS.CLEAR_FILTER,
});
