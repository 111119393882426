import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {notificationsActions} from '../../redux/actions';
import './Notifications.scss'
import InfiniteScroll from 'react-infinite-scroll-component';
import Notification from './Notification';

const Notifications = () => {
  const {meta, notifications} = useSelector(({notificationsReducer}) => notificationsReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(notificationsActions.getNotifications({
      ...meta,
      page: 1,
    }));
  }, []);
  const getMoreNotifications = () => {
    dispatch(notificationsActions.getNotifications({
      ...meta,
      page: meta.page + 1,
    }));
  };
  const handleRemoveNotification = id => {
    dispatch(notificationsActions.removeNotification(id));
  };
  return (
    <div className="notifications">
      <h1>Notifications</h1>
      <div className="items-list">
        <InfiniteScroll
          next={getMoreNotifications}
          hasMore={meta?.page * 10 <= notifications?.length}
          dataLength={notifications?.length}
        >
          { notifications?.map(notification => {
              return (
                <Notification
                  key={notification.id}
                  notification={notification}
                  handleRemoveNotification={handleRemoveNotification}
                />
              )
            })
          }
        </InfiniteScroll>
      </div>
    </div>
  )
}

export default Notifications
