import React from 'react'
import { Select } from 'antd'
import { Controller } from 'react-hook-form'

const Field = ({ name, placeholder, label, labelStyle, control, children, defaultValue, defaultArrayFirst, values, required, className, width, ...args }) => {
  return (
    <label className={className || 'sell-form__label'} style={{ width: width, ...labelStyle }}>
      {label}
      <Controller 
        name={name}
        control={control}
        rules={{required}}
        render={({ field }) => (
          <Select 
            { ...field }
            size="large" 
            defaultValue={defaultValue} 
            placeholder={placeholder} 
            { ...args }
          >
            { values?.map(value => <Select.Option value={value} key={value}>{value}</Select.Option>) }
            { children }
          </Select>
        ) 
      }
      />
    </label> 
  )
}


export default Field