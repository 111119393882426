import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { carsActions, carFilterActions } from '@actions'
import CarCard from './CarCard'
import CarFilter from './CarFilter'
import { Typography, Dropdown, Pagination, Button, Menu } from 'antd'
import { DownOutlined } from '@ant-design/icons';
import './Marketplace.scss'
import MobileFilter from './MobileFilter'

const Marketplace = () => {
  const { carsMeta, cars, total_products } = useSelector(state => state.carsReducer)
  const { time } = useSelector(state => state.globalReducer)
  const { user } = useSelector(state => state.authReducer)
  const [visible, setVisible] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(carsActions.getCars(carsMeta));
  }, [])

  const handlePageChange = (page) => {
    dispatch(carsActions.getCars({ ...carsMeta, page: page }, true))
  }

  const menu = (
    <Menu>
      <Menu.Item key="1">
        1st menu item
      </Menu.Item>
      <Menu.Item key="2">
        2nd menu item
      </Menu.Item>
      <Menu.Item key="3" icon>
        3rd menu item
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="marketplace">
      <CarFilter />
      <MobileFilter setVisible={setVisible} visible={visible} />
      <div className="marketplace__goods">
        <div className="marketplace__goods-header">
          {/* <Typography.Title 
            style={{ margin: '0px', padding: '0px', lineHeight: '1' }} className="wide--text no-margin no-padding">
            {total_products} Cars for sale
          </Typography.Title> */}
          <div className="marketplace__goods-header-actions">
            <Button onClick={() => setVisible(true)} type="primary" className="mobile-filter">Filter</Button>
          </div>
        </div>
        <div className="card-list">
          { cars?.filter(car => car?.sale?.data?.id).sort((left, right) => new Date(left?.sale?.data.attributes.end_date) - new Date(right?.sale?.data.attributes.end_date)).map(car => <CarCard hideFollow={!user} key={car.id} time={time} { ...car } />) }
        </div>
        { carsMeta.total_pages && <Pagination className="home__pagination" onChange={handlePageChange} defaultCurrent={1} total={carsMeta.total_pages} pageSize={1} /> }
      </div>
    </div>
  )
}

export default Marketplace
