import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { profileActions } from '@actions'
import { useForm } from "react-hook-form";
import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import './ChangePassword.scss'
import Field from '@components/Field'
import { Link, useParams } from 'react-router-dom'

const ChangePassword = () => {
  const { pToken } = useParams()
  const { error } = useSelector(({profileReducer}) => profileReducer);
  const { handleSubmit, control } = useForm();
  const dispatch = useDispatch()

  const onSubmit = useCallback((data) => {
    dispatch(profileActions.updatePassword(data, pToken));
  }, [pToken])

  return (
    <div className="login">
      <div className="login-content">
        <h1>Change Password</h1>
        <form className="login__form" onSubmit={handleSubmit(onSubmit)}>
          <Field 
            className="hide-extra-margin" 
            name="password" 
            label="New Password" 
            type="password" 
            control={control}
            suffix={
              <Tooltip title="This field is case-sensitive. Be careful with uppercase ">
                <InfoCircleOutlined style={{ color: 'rgba(0,129,112, 0.8)' }} />
              </Tooltip>
            } 
          />
          <Field 
            className="hide-extra-margin" 
            name="password_confirmation" 
            label="Repeat New Password" 
            type="password" 
            control={control}
            suffix={
              <Tooltip title="This field is case-sensitive. Be careful with uppercase ">
                <InfoCircleOutlined style={{ color: 'rgba(0,129,112, 0.8)' }} />
              </Tooltip>
            } 
          />

          <Button style={{ width: '100%', marginTop: '16px'}} size="large" type="primary" htmlType="submit">Change</Button>
          { error && <p style={{ textAlign: 'center', marginTop: '12px' }} className="error">{error}</p> }
        </form>
      </div>
    </div>
  )
}

export default ChangePassword
