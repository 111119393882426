import React from 'react'
import './JoinTheGarage.scss'
import { Button } from 'antd';
import { profileActions } from '@actions';
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from 'react-redux';

const JoinTheGarage = () => {
  const {user} = useSelector(state => state.authReducer)
  const {handleSubmit, register, formState: {errors}} = useForm();
  const dispatch = useDispatch()

  const onSubmit = data => {
    console.log(data)
    dispatch(profileActions.saveBuyerRequest({ 
      ...user,
      ...data,
      email: user.email,
    }, true));
  }

  return user ? (
    <div className="sell">
      <form className="sell-form sell-main" onSubmit={handleSubmit(onSubmit)}>
        <h1>Join the garage</h1>
        <p style={{ textAlign: 'center', width: '315px', color: '#525252', margin: 'auto' }}>Register today and get access to hundreds of used cars and damaged vehicles!</p>
        <label className="sell-form__label" style={{ marginTop: '24px' }} >
          Email
          <input type="email" className="sell-form__input" disabled defaultValue={user.garage_code} value={user.email} />
          {/* value={user.email} /> */}
        </label>
        { errors.email && <p className="error">Email</p> }
        <label className="sell-form__label" >
          Garage code
          <input { ...register('garage_code', { required: true }) } type="text" className="sell-form__input" defaultValue={user.garage_code} value={user.garage_code} />
        </label>
        { errors.garage_code && <p className="error">Garage code is required</p> }
        <label className="sell-form__label" >
          Password
          <input { ...register('password', { required: true, pattern: /((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,})/ }) } type="password" className="sell-form__input"/>
        </label>
        { errors.password && <p className="error">Password is too weak. Must be minimum of 6 characters long, with at least one capital letter and one number </p> }
        <Button htmlType="submit" style={{ padding: '10px 40px', lineHeight: '1', fontSize: '14px', fontWeight: '600', textTransform: 'uppercase' }} className="sell__submit" size="large" type="primary">Join</Button>
      </form>
    </div>
  ) : null
}

export default JoinTheGarage;
