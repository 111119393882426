import React from 'react'
import { Tabs } from 'antd'
import MyProfile from './MyProfile';
import ChangeEmail from './ChangeEmail';
import ChangePassword from './ChangePassword';

const EditProfileTabs = () => {
  return (
    <Tabs style={{ width: '100%' }} defaultActiveKey="1" type="card">
      <Tabs.TabPane tab="MY PROFILE" key="1">
        <MyProfile />
      </Tabs.TabPane>
      <Tabs.TabPane tab="CHANGE EMAIL" key="2">
        <ChangeEmail />
      </Tabs.TabPane>
      <Tabs.TabPane tab="CHANGE PASSWORD" key="3">
        <ChangePassword />
      </Tabs.TabPane>
    </Tabs>
  )
}

export default EditProfileTabs
