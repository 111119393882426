import React from 'react'
import EditProfile from '@components/EditProfile'
import Header from '@components/Header'
import Footer from '@components/Footer'
import ContentWrapper from '@components/Markup/ContentWrapper'

const EditProfilePage = () => {
  return (
    <ContentWrapper>
      <Header />
      <EditProfile />
      <ContentWrapper.Sticky>
        <Footer />
      </ContentWrapper.Sticky>
    </ContentWrapper>
  )
}

export default EditProfilePage
