import React from 'react'
import EditProfileTabs from './EditProfileTabs';
import './EditProfile.scss'

const EditProfile = () => {
  return (
    <div className="edit-profile">
      <div className="edit-profile__content">
        <div className="edit-profile__tabs styled-tabs">
        <EditProfileTabs />
      </div>
      {/* { myCarsMeta.total_pages && <Pagination className="edit-profile__pagination" onChange={handlePageChange} defaultCurrent={1} total={myCarsMeta.total_pages} pageSize={1} /> } */}
      </div>
    </div>
  )
}

export default EditProfile
