import React, { useState } from 'react'
// import { NavLink } from 'react-router-dom'
// import { Divider, Avatar } from 'antd'
// import { ReactComponent as SearchIcon } from '@icons/i-search.svg'
import { useSelector, useDispatch } from 'react-redux'
import { NavLink, Link } from 'react-router-dom'

import logo from '@icons/logo-motobids.svg'
import { Drawer, Divider, Avatar, Input, Button } from 'antd'
import { ReactComponent as HamburgerIcon } from '@icons/i-hamburger.svg'
import { ReactComponent as CrossIcon } from '@icons/i-cross-black.svg'
import carsBgImage from '@images/cars-bg.png'

import { authActions, carsActions, myCarsTabsActions } from '@actions'
import {MY_CARS_TABS} from '../../redux/constants';

import { useHistory } from 'react-router'

import { config } from '@config'

const MobileNav = () => {
  const { user } = useSelector(state => state.authReducer)
  const { carsMeta } = useSelector(state => state.carsReducer)
  const [search, setSearch] = useState(carsMeta.search || '')
  const [visible, setVisible] = useState(false)
  const history = useHistory()

  const dispatch = useDispatch()

  const logOut = () => {
    dispatch(authActions.logout())
  }

  const handleOpen = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const onChangeTab = event => {
    onClose();
    dispatch(myCarsTabsActions.selectTab(event.target.name));
  };

  const addSecondaryFilterDebounced = _.debounce((key, value) => {
    if(value?.length) {
      const tempMeta = { ...carsMeta }  
      tempMeta[key] = value
      dispatch(carsActions.getCars(tempMeta));
      console.log(history.location)
    } else {
      dispatch(carsActions.getCars({ ...carsMeta, search: null }));
    }
  },
  500,
);

  return (
     <nav className="header__nav mobile">
      <div className="header__routes">
        <Link to="/"><img src={logo} alt="motobids logo" /></Link>
      </div>
      <div className="header__actions">
        <HamburgerIcon onClick={handleOpen} />
      </div>
      <Drawer
          width="100%"
          closable={false}
          onClose={onClose}
          visible={visible}
        >
          <div className="drawer__header">
            <img src={logo} alt="motobids logo" />
            <CrossIcon onClick={onClose} />
          </div>
          { user && <>
            <div className="drawer__user">
            { user?.avatar_url ? <Avatar size={80} src={config.REACT_APP_IMAGE_URL + user?.avatar_url} /> : <Avatar size={80}>{user?.business_name?.[0].toUpperCase()}</Avatar> }
            </div>
            <p style={{ textAlign: 'center', color: '#008170', fontWeight: '500' }}>{user?.business_name}</p>

            { history.location.pathname == '/marketplace' ? 
            <Input.Search 
              onChange={e => {
                setSearch(e.target.value)
                addSecondaryFilterDebounced('search', e.target.value)
                  } 
              }
              onSearch={onClose}
              style={{ padding: '0 10%' }} placeholder="Search car or buyer" size="large" /> 
            : null }
          </> }

          <ul className="drawer__list">
            {  user && <>
              <li><Link to="/home" onClick={onChangeTab} name={MY_CARS_TABS.cars}>My Cars</Link></li>
              <li><Link to="/home" onClick={onChangeTab} name={MY_CARS_TABS.tracked}>My Tracked Cars</Link></li>
              <li><Link to="/home" onClick={onChangeTab} name={MY_CARS_TABS.purchases}>My Purchases</Link></li>
              <Divider style={{ margin: "12px 0" }}  />
              <li><NavLink to="/notifications">Notification</NavLink></li>
              <Divider style={{ margin: "12px 0" }}  />
              <li><NavLink to="/home">Home</NavLink></li>
            </>  }
            
            <li><NavLink to="/marketplace">Find Cars</NavLink></li>
            <li><NavLink to="/how-it-works">How It Works</NavLink></li>
            <li><NavLink to="/faq">FAQ</NavLink></li>
            <li><NavLink to="/contact">Contact Us</NavLink></li>
            <Divider style={{ margin: "12px 0" }}  />
            { user ? <>
            <Link to="/sell"><Button type="primary" style={{ width: "100%", fontWeight: "500"}}>SELL A CAR</Button></Link>
            <Divider style={{ margin: "12px 0" }}  />
            <h4 onClick={logOut} style={{ fontWeight: "600", color: '#008170', fontSize: '14px' }}>LOG OUT</h4></> : <Link to="/login"><Button type="primary" style={{ width: "100%", fontWeight: "500"}}>LOG IN</Button></Link> }
          </ul>
      </Drawer>
    </nav>
  )
}

export default MobileNav
