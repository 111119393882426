import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '@actions'
import { useForm } from "react-hook-form";
import { Button } from 'antd';

const ProfileDetails = () => {
  const { error } = useSelector(state => state.authReducer)
  const { handleSubmit, register, watch,  formState: { errors }, control } = useForm();
  const mail = watch('email')
  const dispatch = useDispatch()

  const onSubmit = data => {
    console.log(data)
    dispatch(authActions.signUp({ email: data.email, password: data.password, garage_code: data.garage_code }))
  }

  return (
    <form className="sell-form sell-main" onSubmit={handleSubmit(onSubmit)}>
      <h1>Create new account</h1>
      <p style={{ textAlign: 'center', width: '315px', color: '#525252', margin: 'auto' }}>Register now for access to Buy and/or Sell <br />Trade vehicles within the Trade only!</p>

      <label className="sell-form__label" style={{ marginTop: '24px' }} >
        Email
        <input { ...register('email', { required: true }) } type="email" className="sell-form__input"/>
      </label>
      { errors.email && <p className="error">Email is required</p> }

      <label className="sell-form__label" >
        Repeat Email
        <input { ...register('eamilConfirm', { required: true, validate: value =>
            value === mail || "Emails do not match" }) } type="email" className="sell-form__input"/>
      </label>
      { errors.eamilConfirm && <p className="error">Emails are required, and should match</p> }

      <label className="sell-form__label" >
        Password
        <input { ...register('password', { required: true, pattern: /((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,})/ }) } type="password" className="sell-form__input" />
      </label>
      { errors.password && <p className="error">Password is too weak. Must be minimum of 6 characters long, with at least one capital letter and one number </p> }

      <label className="sell-form__label" >
        Garage code
        <input { ...register('garage_code', { required: true }) } type="text" className="sell-form__input"/>
      </label>
      { errors.garage_code && <p className="error">Garage code is requied</p> }

      <Button htmlType="submit" style={{ padding: '10px 40px', lineHeight: '1', fontSize: '14px', fontWeight: '600', textTransform: 'uppercase' }} className="sell__submit" size="large" type="primary">Let’s continue</Button>
      { error && <p className="error">{error}</p> }
    </form>
  )
}

export default ProfileDetails
