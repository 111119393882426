import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { carsActions } from '@actions'
import { Pagination } from 'antd';
import TabCard from './TabCard'
import { scrollToTheTop } from '@helpers'

import {MY_CARS_TABS} from '../../redux/constants';


const PurchasedTab = () => {
  const { purchasedCars, purchasedCarsMeta } = useSelector(state => state.carsReducer)
  const {selectedTab} = useSelector(({myCarsTabsReducer}) => myCarsTabsReducer);
  const dispatch = useDispatch()

  useEffect(() => {
    if (MY_CARS_TABS.purchases === selectedTab)
      dispatch(carsActions.getPurchasedCars(purchasedCarsMeta, true))
  }, [selectedTab])

  const handlePageChange = (page) => {
    dispatch(carsActions.getPurchasedCars({ ...purchasedCarsMeta, page: page }, true))
    scrollToTheTop()
  }

  return (
    <div className="tab-cards">
      { purchasedCars.length && purchasedCars.map(car => {
          const { attributes: attr } = car
          return <TabCard 
                    key={car.id}
                    car={attr}
                    isPurchased
                  />
        })}

        { (purchasedCarsMeta.total_pages &&  purchasedCarsMeta.total_pages > 1) && <Pagination style={{ alignSelf: 'flex-start' }} className="home__pagination" onChange={handlePageChange} defaultCurrent={1} total={purchasedCarsMeta.total_pages} pageSize={1} /> }
    </div>
  )
}

export default PurchasedTab
