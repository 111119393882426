import React from 'react'
import Login from '@components/Login'
import Header from '@components/Header'
import Footer from '@components/Footer'
import ContentWrapper from '@components/Markup/ContentWrapper'

const LoginPage = () => {
  return (
    <ContentWrapper contentClass="register">
      <Header />
      <Login />
      <ContentWrapper.Sticky>
        <Footer />
      </ContentWrapper.Sticky>
    </ContentWrapper>
  )
}

export default LoginPage
