import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { carsActions } from '@actions'
import { Button, Select, Radio, Space  } from 'antd';
import { useForm } from "react-hook-form";
import CustomSelect from '@components/CustomSelect';
import { scrollToTheTop } from '@helpers'

import carSideImg from '@images/right-min.png'
import carSideImg2x from '@images/right-min@2x.png'
import carSideImg3x from '@images/right-min@3x.png'

import carLeftImg from '@images/left-min.png'
import carLeftImg2x from '@images/left-min@2x.png'
import carLeftImg3x from '@images/left-min@3x.png'

import carFbImg from '@images/fb-min.png'
import carFbImg2x from '@images/fb-min@2x.png'
import carFbImg3x from '@images/fb-min@3x.png'

import carTopImg from '@images/top-min.png'
import carTopImg2x from '@images/top-min@2x.png'
import carTopImg3x from '@images/top-min@3x.png'

const { Option } = Select;

const ReConZone = () => {
  const { handleSubmit, register,  formState: { errors }, watch, control } = useForm();
  const watchClutch = watch("clutch", false);
  const { tyres_types, damages_types, product_id, carSummary } = useSelector(state => state.carsReducer)
  const dispatch = useDispatch()

  const [values, setValue] = useState({ dash_warnings: 0 });

  const onChange = (e, name) => {
    setValue(prevValue => ({
      ...prevValue,
      [name]: e.target.value 
    }))
  }

  useEffect(() => {
    dispatch(carsActions.getTyres())
    dispatch(carsActions.getDamage())
    scrollToTheTop()
  }, [])

  const damageList = damages_types ? damages_types.map(type => <Option value={type.value}>{type.key}</Option>) : null

  const onSubmit = data => {
    const {
      clutch,
      tyres,
      tyres_mms,
      alloy_refurbs,
      dash_warnings_info,
      right_damage_info,
      front_back_damage_info,
      left_damage_info,
      top_damage_info,
      engine_condition,
      recon_summary,
      other,
    } = data

    console.log(data)

    const payload = {
      recon_zone: {
        ...values,
		clutch,
		tyres,
		tyres_mms,
		alloy_refurbs,
		product_id,
		dash_warnings_info,
		engine_condition,
		right_damage_info,
		front_back_damage_info,
		left_damage_info,
		top_damage_info,
		recon_summary,
		other,
		summarise_damage_info: carSummary,
		parts_attributes: [
			{ side: 'right', side_number: 1, damage: data.right_1 },
			{ side: 'right', side_number: 2, damage: data.right_2 },
			{ side: 'right', side_number: 3, damage: data.right_3 },
			{ side: 'right', side_number: 4, damage: data.right_4 },
			{ side: 'front_and_back', side_number: 1, damage: data.front_and_back_1 },
			{ side: 'front_and_back', side_number: 2, damage: data.front_and_back_2 },
			{ side: 'front_and_back', side_number: 3, damage: data.front_and_back_3 },
			{ side: 'front_and_back', side_number: 4, damage: data.front_and_back_4 },
			{ side: 'left', side_number: 1, damage: data.left_1 },
			{ side: 'left', side_number: 2, damage: data.left_2 },
			{ side: 'left', side_number: 3, damage: data.left_3 },
			{ side: 'left', side_number: 4, damage: data.left_4 },
			{ side: 'top', side_number: 1, damage: data.top_1 },
			{ side: 'top', side_number: 2, damage: data.top_2 },
			{ side: 'top', side_number: 3, damage: data.top_3 },
			{ side: 'top', side_number: 4, damage: data.top_4 },
		]
      }
    }

    dispatch(carsActions.createZone(payload))
  }

  return (
    <form className="sell-form" onSubmit={handleSubmit(onSubmit)}>
      <h1>Sell a car</h1>
      <p style={{ textAlign: 'center' }}>Fill in all important info to help sell your car</p>

    {/* ReCon Zone 1 */}

    <div className="zone" style={{ marginBottom: "24px" }}>
      <h3 className="sell-form__headline">ReCon Zone 1</h3>
      {/* <CustomSelect label="Clutch" name="clutch" control={control} placeholder="clutch" >
        { clutch_types?.map(type => <Option value={type.value}>{type.key}</Option>) }
      </CustomSelect> */}
      <label className="sell-form__label" style={{ marginBottom: '12px' }}>
        Clutch
        <input { ...register('clutch') } type="text" className="sell-form__input" placeholder="ie All Okay, Slipping, Noisy/Judder Flywheel, Gear Selection Issue, Etc" />
      </label>

      <label className="sell-form__label" style={{ marginBottom: '12px' }}>
        Engine
        <input { ...register('engine_condition') } type="text" className="sell-form__input" placeholder=" ie All Okay, Smokey, Bearings Noise, Etc" />
      </label>

      <div className="sell-form__label-wrapper" style={{ marginBottom: '12px' }}> 
        {/* <CustomSelect label="Tyres" name="tyres" control={control} placeholder="tyres"  >
          { tyres_types?.map(type => <Option value={type.value}>{type.key}</Option>) }
        </CustomSelect> */}
        <CustomSelect label="Tyres" className="sell-form__item" name="tyres_mms" control={control}  >
          <Option value={0}>None</Option>
          { [1, 2, 3, 4, 5].map(num => <Option value={num}>{num}</Option>) }
        </CustomSelect>
        <CustomSelect label="Alloy Refurbs" className="sell-form__item" name="alloy_refurbs" control={control} placeholder="1,2,3,4,5" >
        <Option value="none">None</Option>
        { [1, 2, 3, 4, 5].map(num => <Option value={num}>{num}</Option>) }
        </CustomSelect>
      </div>

      <label className="sell-form__label">
        <input { ...register('tyres_summary') } type="text" className="sell-form__input" placeholder="ie All Okay, 2 Tyres, 2 Alloy Refurbs, Etc." />
      </label>

      <label className="sell-form__label">
        Dash Warnings
        <input { ...register('dash_warnings_info') } type="text" className="sell-form__input" placeholder="Put in relevant info on dash warning lights if any on" />
      </label>
      <label className="sell-form__label" style={{ padding: '0', marginTop: '18px' }}>
          <Radio.Group className="sell-form__radio-group" onChange={e => onChange(e, "dash_warnings")} value={values.dash_warnings}>
            <Radio value={0}>All okay</Radio>
            <Radio value={1}>There is damage</Radio>
          </Radio.Group>
      </label>
      { values.dash_warnings == 1 ? 
      <label className="sell-form__label" style={{ marginBottom: '12px' }}>
        <input { ...register('recon_summary') } type="text" className="sell-form__input" placeholder="ie All Okay, AirBag Light On, Engine Management Light On, Etc. No need for the" />
      </label> : null 
      }
    </div>

    <div className="zone">
      <h3 className="sell-form__headline">ReCon Zone 2</h3>
      {/* right side */}
      <div className="side">
        <p className="side__headline">Right side</p>
        <img className="side__car-img" src={carSideImg} srcSet={`${carSideImg2x} 2x, ${carSideImg3x} 3x`} alt="car side image" />

        <div className="sell-form__label-wrapper"> 
          <CustomSelect name="right_1" label="Part 1" placeholder="Damage to car" control={control}>{damageList}</CustomSelect>
          <CustomSelect name="right_2" label="Part 2" placeholder="Damage to car" control={control}>{damageList}</CustomSelect>
          <CustomSelect name="right_3" label="Part 3" placeholder="Damage to car" control={control}>{damageList}</CustomSelect>
          <CustomSelect name="right_4" label="Part 4" placeholder="Damage to car" control={control}>{damageList}</CustomSelect>
        </div>
        <label className="sell-form__label">
          Summarise damage to car
          <input { ...register('right_damage_info') } type="text" className="sell-form__input" placeholder="Summarise damage to car" />
        </label>
      </div>
      {/* front and back side */}
    <div className="side">
      <p className="side__headline">Front and back side</p>
      <img className="side__car-img" src={carFbImg} srcSet={`${carFbImg2x} 2x, ${carFbImg3x} 3x`} alt="car side image" />

      <div className="sell-form__label-wrapper"> 
        <CustomSelect name="front_and_back_1" label="Part 1" placeholder="Damage to car" control={control}>{damageList}</CustomSelect>
        <CustomSelect name="front_and_back_2" label="Part 2" placeholder="Damage to car" control={control}>{damageList}</CustomSelect>
        <CustomSelect name="front_and_back_3" label="Part 3" placeholder="Damage to car" control={control}>{damageList}</CustomSelect>
        <CustomSelect name="front_and_back_4" label="Part 4" placeholder="Damage to car" control={control}>{damageList}</CustomSelect>
      </div>
      <label className="sell-form__label">
        Summarise damage to car
        <input { ...register('front_back_damage_info') } type="text" className="sell-form__input" placeholder="Summarise damage to car" />
      </label>
    </div>
    {/* left side */}
    <div className="side">
      <p className="side__headline">Left side</p>
      <img className="side__car-img" src={carLeftImg} srcSet={`${carLeftImg2x} 2x, ${carLeftImg3x} 3x`} alt="car side image" />

      <div className="sell-form__label-wrapper"> 
        <CustomSelect name="left_1" label="Part 1" placeholder="Damage to car" control={control}>{damageList}</CustomSelect>
        <CustomSelect name="left_2" label="Part 2" placeholder="Damage to car" control={control}>{damageList}</CustomSelect>
        <CustomSelect name="left_3" label="Part 3" placeholder="Damage to car" control={control}>{damageList}</CustomSelect>
        <CustomSelect name="left_4" label="Part 4" placeholder="Damage to car" control={control}>{damageList}</CustomSelect>
      </div>
      <label className="sell-form__label">
        Summarise damage to car
        <input { ...register('left_damage_info') } type="text" className="sell-form__input" placeholder="Summarise damage to car" />
      </label>
    </div>
    {/* top side */}
    <div className="side">
      <p className="side__headline">Top view</p>
      <img className="side__car-img" src={carTopImg} srcSet={`${carTopImg2x} 2x, ${carTopImg3x} 3x`} alt="car side image" />

      <div className="sell-form__label-wrapper"> 
        <CustomSelect name="top_1" label="Part 1" placeholder="Damage to car" control={control}>{damageList}</CustomSelect>
        <CustomSelect name="top_2" label="Part 2" placeholder="Damage to car" control={control}>{damageList}</CustomSelect>
        <CustomSelect name="top_3" label="Part 3" placeholder="Damage to car" control={control}>{damageList}</CustomSelect>
        <CustomSelect name="top_4" label="Part 4" placeholder="Damage to car" control={control}>{damageList}</CustomSelect>
      </div>
      <label className="sell-form__label" style={{ margin: '0' }}>
        Summarise damage to car
        <input { ...register('top_damage_info') } type="text" className="sell-form__input" placeholder="Summarise damage to car" />
      </label>
    </div>
    <div className='side' style={{ margin: '0' }}>
      <label className="sell-form__label" style={{ margin: '0' }}>
          Other ReCon
          <input { ...register('other') } type="text" className="sell-form__input" placeholder="Other ReCon" />
        </label>
    </div>

    </div>
    <Button htmlType="submit" className="sell__submit" size="large" type="primary">Save and sell a car</Button>
  </form>
  )
}

export default ReConZone
