export default {
  TOGGLE_LOGIN_MODAL : 'TOGGLE_LOGIN_MODAL',
  TOGGLE_REGISTER_MODAL : 'TOGGLE_REGISTER_MODAL',
  CLEAR_ERROR : 'CLEAR_ERROR',
  SET_ERROR : 'SET_ERROR',
  SET_SUCCESS : 'SET_SUCCESS',
  CLEAR_SUCCESS : 'CLEAR_SUCCESS',
  SEND_CONTACT_US : 'SEND_CONTACT_US',
  SEND_CONTACT_US_SUCCESS : 'SEND_CONTACT_US_SUCCESS',
  SEND_CONTACT_US_ERROR : 'SEND_CONTACT_US_ERROR',
  TOGGLE_SUCCESS_POPUP: 'TOGGLE_SUCCESS_POPUP',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  TOGGLE_REPORT_MODAL: 'TOGGLE_REPORT_MODAL',
  SET_LOADING: 'SET_LOADING',
  GET_MAKE: 'GET_MAKE',
  GET_MAKE_SUCCESS: 'GET_MAKE_SUCCESS',
  GET_MAKE_ERROR: 'GET_MAKE_ERROR',
  TOGGLE_NOTIFICATION: 'TOGGLE_NOTIFICATION',
  SET_TIME: 'SET_TIME'
}