import {AUTH_ACTIONS, INITIAL_STATE} from '@constants';

const authReducer = (state = INITIAL_STATE.AuthInitialState, action) => {
  const {type, payload} = action || {};
  const {user, error, step, id} = payload || {};
  switch (type) {
    case AUTH_ACTIONS.LOGIN_SUCCESS:
      return {
        ...state,
        user: user || null,
        error: null,
      };
    case AUTH_ACTIONS.SIGN_UP_SUCCESS:
      return {
        ...state,
        user: user || null,
        error: null
      };
    case AUTH_ACTIONS.LOGOUT_SUCCESS:
      return {
        ...state,
        user: null,
      };
    case AUTH_ACTIONS.STEP_SET:
      return {
        ...state,
        step: step
      }
    case AUTH_ACTIONS.STEP_ADD:
      return {
        ...state,
        step: state.step >= 0 && state.step < 3 ? state.step + 1 : state.step
      }
    case AUTH_ACTIONS.STEP_DECREMENT:
      return {
        ...state,
        step: state.step > 0 && state.step <= 3 ? state.step - 1 : state.step
      }
    case AUTH_ACTIONS.BLOCK_SUCCESS: {
      const {i_block_user_ids = []} = state.user;
      const newBlackList = i_block_user_ids?.push(id) || [id]
      return {
        ...state,
        user: {
          ...state.user, i_block_user_ids: newBlackList,
        }
      }
    }
    case AUTH_ACTIONS.SIGN_UP_ERROR:
    case AUTH_ACTIONS.LOGIN_ERROR:
    case AUTH_ACTIONS.LOGOUT_ERROR:
      return {
        ...state,
        error: error || '',
      };
    default:
      return state;
  }
};

export {authReducer};
