export const DEALER_TYPES = [{
  title: "Main Dealer",
  value: 0,
}, {
  title: "Used Dealer",
  value: 1,
}, {
  title: "Trader",
  value: 2,
}];
