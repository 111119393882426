export const AuthInitialState = {
  user: null,
  error: '',
  step: 0,
};

export const GlobalInitialState = {
	time: [new Date(), new Date()],
  showLoginModal: false,
  showRegisterModal: false,
  reportModal: {
    show: false,
    reportUserId: null
  },
  successPopup: {
    show: false,
    message: ''
  },
  loading: false,
  successMessage: '',
  error: '',
};

export const CarsInitialState = {
  // posts: [],
  // post: null,
  isMotorCheckModalOpen: false,
  error: '',
  car: null,
  tempCar: null,
  auctions: [],
  cars: [],
  myCars: [],
  followingCars: [],
  purchasedCars: [],
  archivedCars: [],
  relatedCars: [],
  auctionsMeta: {
    page: 1,
    perPage: 6,
    total_pages: null,
  },
  carsMeta: {
    page: 1,
    perPage: 6,
    total_pages: null,
    cars: [ {}, ],
    price_from: null,
    price_to: null,
    year_from: null,
    year_to: null,
    odometer_from: null,
    odometer_to: null,
    color: null,
    search: null,
  },
  myCarsMeta: {
    page: 1,
    perPage: 6,
    archived: false,
    total_pages: null,
  },
  followingCarsMeta: {
    page: 1,
    perPage: 6,
    archived: false,
    total_pages: null,
  },
  purchasedCarsMeta: {
    page: 1,
    perPage: 6,
    archived: false,
    total_pages: null,
  },
  archivedCarsMeta: {
    page: 1,
    perPage: 6,
    archived: true,
    total_pages: null,
  },
  relatedCarsMeta: {
    page: 1,
    perPage: 3,
    archived: false,
    total_pages: null,
  },
  buyersMeta: {
    page: 1,
    perPage: 10,
    total_pages: null,
  },
  serivceStep: false,
  photosStep: false,
  total_products: 0,
  total_sales: 0,
  createdAttachments: [],
  clutch_types: [],
  tyres_types: [],
  damages_types: [],
  history_types: [],
  body_types: [],
  transmition_types: [],
  fuel_types: [],
  colors: [],
  formStep: 0,
  product_id: null,
  buyersForm: [ 1 ],
  buyers: [],
  carSummary: '',
};

export const ProfileInitialState = {
  users: [],
  user: null,
  error: '',
  meta: {
    page: 1,
    perPage: 4,
    total_pages: null,
    search: '',
  },
  followers: [],
  following: [],
  followersMeta: {
      page: 1,
      perPage: 10,
    },
    followingMeta: {
      page: 1,
      perPage: 10,
    },
  ratingUsers: [],
  ratingUsersMeta: {
    page: 1,
    perPage: 10,
  },
  petsRatingUsers: [],
  petsRatingUsersMeta: {
    page: 1,
    perPage: 10,
  },
  servicesRatingUsers: [],
  servicesRatingUsersMeta: {
    page: 1,
    perPage: 10,
  },
  ratings: [],
  followers: [],
  following: [],
  blackList: [],
  donations: [],
  currentUser: null,
};

export const MESSAGING_STORE = {
  chats: [],
  chats_count: 0,
  chat: {},
  messages: [],
  message: {},
  messages_length: 0,
  badge_count: 0,
  showMessagePopup: false,
  cable: null,
  cbs: {},
  meta: {
    page: 1,
    perPage: 10,
    search: '',
  }
}

export const PaymentsInitialState = {
  isProcessing: false,
  paymentIntent: null,
  donateModal: {},
  donateModalVisible: false,
  paymentMessage: ''
}

export const INFORMATION_STORE = {
  resources: [],
  resourcesMeta: {
    page: 1,
    perPage: 10,
    search: ''
  },
  article: {},
  articles: [],
  articlesMeta: {
    page: 1,
    perPage: 10,
    search: ''
  },
  feed: [],
  feedMeta: { page: 1, perPage: 10 }
}

export const SERVICES_STORE = {
  serviceTypes: [],
  services: [],
  meta: {
    page: 1,
    perPage: 20,
  },
  servicesMeta: {
    page: 1,
    perPage: 10,
    selectedServiceTypes: [],
    search: '',
    lat: null,
    lon: null,
  }
}

export const CAR_FILTER_STORE = {
  makes: [],
  models: [],
  generations: [],
  series: [],
  trims: [],
  make_id: null,
  filter: {
    make: null,
    model: null,
    year_from: null,
    year_to: null,
    price_from: null,
    price_to: null,
  },
};

export const NOTIFICATIONS_STORE = {
  meta: {
    page: 1,
    per_page: 10,
  },
  notifications: [],
};

export const MyCarsTabsInitialState = {
  selectedTab: 'tracked',
};
