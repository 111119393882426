import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
// import './Landing.scss'
import './Landing.scss'
import {push} from 'connected-react-router'
import {Button, Carousel, Select, InputNumber } from 'antd'
import { Link } from 'react-router-dom'
import {carFilterActions} from '@actions';
import landingPageMob from '@images/landingPageMob.png'
import CarCard from '@components/Marketplace/CarCard'
import { carsActions } from '@actions';
import {ROUTES} from '@router/routes';
import {getYears} from '@helpers';

const {Option} = Select;

const contentStyle = {
  color: '#000000',
  textAlign: 'center',
};

const Landing = () => {
  const [newFilter, setNewFilter] = useState({
    make: null,
    model: null,
    year_from: null,
    year_to: null,
    price_from: null,
    price_to: null,
  });
  const dispatch = useDispatch();
  const {makes, models, filter} = useSelector(({carFilterReducer}) => carFilterReducer);
  const { cars, carsMeta } = useSelector(state => state.carsReducer)

  useEffect(() => {
    dispatch(carFilterActions.getMakes());
    dispatch(carsActions.getCars(carsMeta))
  }, []);

  const handleMakeClick = value => {
    const make_id = makes.filter(make => make.title === value)[0].id;
    dispatch(carFilterActions.getModels(make_id));
    setNewFilter({
      ...newFilter,
      make: value,
    });
  };

  const handleModelClick = value => {
    setNewFilter({
      ...newFilter,
      model: value,
    });
  };

  const handleDateFromChange = value => {
    if (newFilter.year_from && newFilter.year_to) {
      const year_to = Number(value) > Number(newFilter.year_to) ? value : newFilter.year_to;
      const year_from = Number(value) > Number(newFilter.year_to) ? newFilter.year_to : value;
      setNewFilter({
        ...newFilter,
        year_to,
        year_from,
      });
    } else {
      setNewFilter({
        ...newFilter,
        year_from: value,
      });
    }
  };

  const handleDateToChange = value => {
    if (newFilter.year_from) {
      const year_to = Number(value) < Number(newFilter.year_from) ? newFilter.year_from : value;
      const year_from = Number(value) < Number(newFilter.year_from) ? value : newFilter.year_from;
      setNewFilter({
        ...newFilter,
        year_to,
        year_from,
      });
    } else {
      setNewFilter({
        ...newFilter,
        year_to: value,
      });
    }
  };

  const handlePriceFromChange = value => {
    setNewFilter({
      ...newFilter,
      price_from: value,
    });
  };

  const handlePriceToChange = value => {
    setNewFilter({
      ...newFilter,
      price_to: value,
    });
  };

  const handleBlurPrice = () => {
    if (newFilter.price_from && newFilter.price_to && Number(newFilter.price_from) > Number(newFilter.price_to)) {
      setNewFilter({
        ...newFilter,
        price_from: newFilter.price_to,
        price_to: newFilter.price_from,
      });
    }
  };

  const handleSaveFilter = () => {
    dispatch(carFilterActions.filterSelect({
      ...filter,
      ...newFilter,
    }));
    dispatch(push(ROUTES.MARKETPLACE));
  };

  const YEARS = getYears();

  return (
    <div className="landing">

      <div className="hero-page">
        <div className="hero-page__content">
        
        <div className="hero-page__headline">
          <h1>Restock</h1>
          <h1>Your Garage</h1>
          <h1>Here</h1>
        </div>
        <p>Buy Direct From Volkswagen.</p>
        <p>Motor Trade Only Access.</p>
        <p>The Best & Only Place To Buy Our Trade-Ins on Buy Cars.</p>

        <div className="landing-actions">
          <Button size='large' type='primary' style={{ fontWeight: '600', fontSize: 
          '14px', minWidth: '200px', marginRight: '30px' }}><Link to="/marketplace">BUY A CAR</Link></Button>
          <Button size='large' style={{ fontWeight: '600', fontSize: 
          '14px', minWidth: '200px', color: '#008170', border: 'solid 1px #008170' }}>
            <Link to="/sell">SELL A CAR</Link>
          </Button>
        </div>

        </div>
        
      </div>
      {/* Popular cars */}
      <div className='look-at-this'>LOOK AT THIS</div>
      <div className='popular-list'>Popular Vehicles Right Now</div>
        <div className="car-list--sm home__desktop-cards" style={{ width: '80%', margin: 'auto', flexWrap: 'wrap' }}>
            { cars?.map(car => <CarCard key={`landing_car_${car?.id}`} hideFollow { ...car } />) }
      </div>
      <div className='block-home-grid-mob'>
        <Carousel effect="fade">
          { cars?.map(car => <CarCard key={`carousel_car_${car?.id}`} hideFollow { ...car } />) }
        </Carousel>
      </div>
      <div className="about-us">
        <div className="about-us__content">
        <img className="img-landing" src={landingPageMob} alt="landing image"/>
        <h2 className="about-us__title">Who We Are ?</h2>
        <div className="block-about-us">
          <div className="description">
            <p>
            We are Motor trade. We have decades of experience working in Dealerships, running Main Dealerships, Used Car Dealerships, Trading Cars and dealing with Auction Houses both here and in the UK. So to say we understand the Trade from your perspective, would be spot on.
            </p>
            <h2 className="about-us__title" style={{ marginTop: '18px' }} >What We Do ?</h2>
            <p>Provide a platform to sell your trade cars and for you to buy cars if they fit your profile. We envision Dealers doing both. All cars stay with the selling Dealer until sold and paid for, to you, speeding up the whole process and minimising costs. The Buyer pays the Seller directly and collects the car once cleared funds are received. It’s faster and safer for everyone. For the Buyer, it gives access to Dealer stock across the Country from your mobile or desktop.</p>

          </div>
        </div>
        </div>
        
      </div>
    </div>
  )
}

export default Landing
