import React, { useEffect, useState } from 'react'
import Modal from '@components/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { carsActions } from '@actions'
import { config } from '@config'
import { useWindowSize } from '@hooks/useWindowSize';

import ImageGallery from 'react-image-gallery';

import SwiperCore, { Navigation,Thumbs } from 'swiper/core';

import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css"
import "swiper/components/thumbs/thumbs.min.css"

const PhotosModal = ({ open, id, setOpenModal }) => {
  const size = useWindowSize()
  const { createdAttachments } = useSelector(state => state.carsReducer)
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [nav1, setNav1] = useState(null)
  const [nav2, setNav2] = useState(null)
  let slider1 = []
  let slider2 = []
  const dispatch = useDispatch()

  useEffect(() => {
   SwiperCore.use([Navigation,Thumbs]);
  }, [open])

  useEffect(() => {
    dispatch(carsActions.getAttachments(id, true))
  }, [id])

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  // console.log(createdAttachments)

  const keys = Object.keys(createdAttachments);
  const images = Object.keys(createdAttachments).map(key => ({
      original: config.REACT_APP_IMAGE_URL + createdAttachments[key]?.image_url,
      thumbnail: config.REACT_APP_IMAGE_URL + createdAttachments[key]?.image_thumb_url,
      // originalWidth: '50%'
  }))
  // console.log(keys)

  return keys.length ? (
    <Modal open={open} handleClose={() => setOpenModal(false)} className="img__modal">
      <div className="modal__container">
       <ImageGallery  items={images} />
      </div>
    </Modal>
  ) : <p>loading</p>
}

export default PhotosModal
