export const BASE_URL = 'http://46.101.156.14:7070'
const {
  REACT_APP_API_URL,
  REACT_APP_IMAGE_URL,
  REACT_APP_WEBSOCKET_URL,
  REACT_APP_STRIPE_KEY,
} = process.env;

const STRIPE_API_KEY = REACT_APP_STRIPE_KEY || '';

export const config = {
  REACT_APP_API_URL,
  REACT_APP_IMAGE_URL,
  REACT_APP_WEBSOCKET_URL,
  STRIPE_API_KEY
};