import {takeEvery, put, call} from 'redux-saga/effects';
import {CAR_FILTER_ACTIONS} from '@constants';
import {carFilterActions} from '../actions';
import {processRequest} from '../../services/Api';

function* handleGetMakes() {
  try {
    const {data} = yield call(
      processRequest,
      '/makes',
      'GET',
    );
    if (data.makes) {
      const makes = data.makes.data.map(make => make.attributes);
      yield put(carFilterActions.getMakesSuccess(makes));
    }
  } catch (e) {
    const {response} = e;
    const {data, status, statusText} = response || {};
    const {error_messages, error, error_message} = data || {};

    if (status === 400) {
      let message = '';
      if (error_message) {
        message = error_message;
      } else if (typeof error_messages === 'string') {
        message = error_messages;
      } else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];

        message = error_messages && `${keys[0]} ${errorMessage}`;
      }

      yield put(carFilterActions.getMakesError(message));
    } else if (status === 401) {
      yield put(carFilterActions.getMakesError(error));
    } else if (status === 500) {
      yield put(
        carFilterActions.getMakesError(
          statusText || 'Internal server error.',
        ),
      );
    } else if (e.message) {
      yield put(carFilterActions.getMakesError(e.message));
    } else {
      yield put(carFilterActions.getMakesError('Internal server error.'));
    }
  }
}

function* handleGetModels(action) {
  try {
    const {payload} = action || {};
    const {make_id} = payload || {};
    const {data} = yield call(
      processRequest,
      `/models?make_id=${make_id}`,
      'GET',
    );
    if (data.models) {
      const models = data.models.data.map(model => model.attributes);
      yield put(carFilterActions.getModelsSuccess(models));
    }
  } catch (e) {
    const {response} = e;
    const {data, status, statusText} = response || {};
    const {error_messages, error, error_message} = data || {};

    if (status === 400) {
      let message = '';
      if (error_message) {
        message = error_message;
      } else if (typeof error_messages === 'string') {
        message = error_messages;
      } else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];

        message = error_messages && `${keys[0]} ${errorMessage}`;
      }

      yield put(carFilterActions.getModelsError(message));
    } else if (status === 401) {
      yield put(carFilterActions.getModelsError(error));
    } else if (status === 500) {
      yield put(
        carFilterActions.getModelsError(
          statusText || 'Internal server error.',
        ),
      );
    } else if (e.message) {
      yield put(carFilterActions.getModelsError(e.message));
    } else {
      yield put(carFilterActions.getModelsError('Internal server error.'));
    }
  }
}

function* handleGetGenerations(action) {
  try {
    const {payload} = action || {};
    const {model_id} = payload || {};
    const {data} = yield call(
      processRequest,
      `/generations?model_id=${model_id}`,
      'GET',
    );

    if(data && data.generations) {
      console.log(data.generations?.data?.map(generation => generation.attributes))
      yield put(carFilterActions.getGenerationsSuccess(data.generations?.data?.map(generation => generation.attributes)))
    }

  } catch (e) {
    console.log(e)
  }
}

function* handleGetSeries(action) {
  try {
    const {payload} = action || {};
    const {model_id, generation_id} = payload || {};
    const {data} = yield call(
      processRequest,
      `/series?model_id=${model_id}&generation_id=${generation_id}`,
      'GET',
    );

    if(data && data.series) {
      yield put(carFilterActions.getSeriesSuccess(data.series?.data?.map(serie => serie.attributes)))
    }
  } catch (e) {
    console.log(e)
    yield put(carFilterActions.getSeriesError(e))
  }
}

function* handleGetTrims(action) {
  try {
    const {payload} = action || {};
    const {model_id, serie_id} = payload || {};
    const {data} = yield call(
      processRequest,
      `/trims?model_id=${model_id}&serie_id=${serie_id}`,
      'GET',
    );

    if(data && data.trims) {
      yield put(carFilterActions.getTrimsSuccess(data.trims?.data?.map(trim => trim.attributes)))
    }
  } catch (e) {
    console.log(e)
    yield put(carFilterActions.getTrimsError(e))
  }
}

export function* watchCarFilterSagas() {
  yield takeEvery(CAR_FILTER_ACTIONS.GET_MAKES, handleGetMakes);
  yield takeEvery(CAR_FILTER_ACTIONS.GET_MODELS, handleGetModels);
  yield takeEvery(CAR_FILTER_ACTIONS.GET_GENERATIONS, handleGetGenerations);
  yield takeEvery(CAR_FILTER_ACTIONS.GET_SERIES, handleGetSeries);
  yield takeEvery(CAR_FILTER_ACTIONS.GET_TRIMS, handleGetTrims);
}