import React from 'react';
import {Link} from 'react-router-dom';
import terms from '../../img/files/Terms_and_Conditions.pdf';
import privacy from '../../img/files/PRIVACY_COOKIE_POLICY.pdf';
import './Footer.scss';

const Footer = () => {
  return (
    <>
    <h2 className="footer__slogan">« For The Trade, By The Trade, To The Trade! » </h2>
    <footer className="footer">
      <nav>
        <h4 className="footer__heading">© Motobids {new Date().getFullYear()}. All rights reserved.</h4>
        <ul className="footer__items">
          <li><Link style={{ color: '#000' }} to="aboutus">About us</Link></li>
          <li><Link style={{ color: '#000' }} to="/contact">Contact us</Link></li>
          <li><Link style={{ color: '#000' }} to='/terms-of-use'>Terms of Service</Link></li>
          <li><Link style={{ color: '#000' }} to='/privacy-policy'>Privacy Policy</Link></li>
        </ul>
      </nav>
    </footer>
    </>
  )
}

export default Footer
