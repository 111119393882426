import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { profileActions, messagingActions, carsActions } from '@actions'
import { useHistory } from 'react-router-dom'

import { Button, Divider, Input, Tooltip, Typography, Popconfirm } from 'antd'
import { IdcardFilled, InfoCircleOutlined,DeleteFilled } from '@ant-design/icons'

import moment from 'moment'
import { Link } from 'react-router-dom'
import { config } from '@config'

import carMock from '@images/car-mock.png'
import carMock2x from '@images/car-mock@2x.png'
import carMock3x from '@images/car-mock@3x.png'
import isDayLeft from '../../helpers/isDayLeft'
import Countdown from 'react-countdown'
import customCountdownRenderer from '../../helpers/customCountdownRenderer'
import { useForm } from 'react-hook-form'
import useTimeLeft from '../../hooks/useTimeLeft'


const TabCard = ({ car, my, onTrackedPage, isPurchased }) => {

  const { id, sale, has_winner, make, model, start_bid, end_date, front_image_url, buyer, is_followed, is_owner, win_bid, sale_date, published_at, fuel_type, engine = '', variant = '', is_ready_to_sell = false, is_finished_creation, reserved_price } = car

  const timeLeft = useTimeLeft(sale?.data?.attributes?.end_date)
  const makeName = make?.data?.attributes?.title
  const modelName = model?.data?.attributes?.title

  const { user } = useSelector(state => state.authReducer)

  const history = useHistory()
  const dispatch = useDispatch()

  const handleVievMore = e => {
    if(is_ready_to_sell || has_winner || is_followed) {
      history.push(`/cars/${id}`, {isPurchased})
    }
  }

  const stopPropagation = e => {
    e.stopPropagation()
  }

  const handleFollow = e => {
    e.stopPropagation()
    dispatch(profileActions.follow(id))
  }

  const handleUnfollow = e => {
    e.stopPropagation()
    dispatch(profileActions.unfollow(id))
  }

  const handleAcceptWin = () => {
    dispatch(carsActions.acceptWin(win_bid?.data?.id))
  }

  const handleRejectWin = () => {
    dispatch(carsActions.rejectWin(win_bid?.data?.id))
  }

  const handleDeleteCar = (e) => {
    e.stopPropagation()
    dispatch(carsActions.deleteCar(id))
  }



  return (
  <div className={`${onTrackedPage ? 'cardCustom' : ''} tab-Card card`} >
    <div onClick={handleVievMore} style={{ cursor: 'pointer', height: 'min-content' }} className="card tab-card adaptive" >
      <figure>
        { front_image_url ? 
          <img src={`${config.REACT_APP_IMAGE_URL}${front_image_url}`} alt="car image" /> 
          : <img src={carMock} srcSet={`${carMock2x} 2x, ${carMock3x} 3x`} alt="car image" /> 
        }
      </figure>
      <div className="card__content">
        {/* Reject / confrim header */}
        {  (is_owner && win_bid?.data && !win_bid?.data?.attributes?.is_accepted && win_bid?.data?.attributes.winner_id !== user?.id )  ? 
        <div className="card__header card-decision">
          <div className="card__headline" onClick={stopPropagation}>
            <p>This car was sold at auction with this bid</p>
            <span className="card-decision__final-bid">Final bid: € {win_bid?.data?.attributes?.amount}</span>
            <div className="card__decisions">
              <Button onClick={handleAcceptWin} type="primary" style={{ marginRight: '16px', width: 'max-content' }}>CONFIRM</Button>
              <Button onClick={handleRejectWin} style={{ marginRight: '16px' }}>REJECT</Button>
              <Tooltip 
              title={`Your bid should be at least 50€ higher than current bid (${sale. current_bid + 50}). You can use arrows on your keyboard to change the bid`}>
                <InfoCircleOutlined style={{ color: 'rgba(0,129,112, 0.8)' }} />
              </Tooltip>
            </div>
            <Typography.Title 
              onClick={handleVievMore} 
              ellipsis={{ rows: 2, expandable: false }} 
              level={3}>
                {makeName} {modelName} {variant} {engine} {fuel_type}
            </Typography.Title>
          </div>
        </div> :
        <div className="card__header">
          <div className="card__headline" onClick={stopPropagation}>
            {!onTrackedPage && <span>€ {sale?.current_live_bid || reserved_price}</span>}
            <Typography.Title 
              onClick={handleVievMore} 
              ellipsis={{ rows: 2, expandable: false }} 
              level={3}>
                {makeName} {modelName} {variant} {engine} {fuel_type}
            </Typography.Title>
          </div>


          {/* Follow */}
          { !is_owner && 
              <Button 
                onClick={ !is_followed ? handleFollow : handleUnfollow } 
                size="large" 
                style={{ borderColor: '#008170', color: is_followed ? '#fff' : '#008170'  }} 
                type={is_followed ? 'primary' : null} >
                  { !is_followed ? 'Track' : 'Tracked' }
              </Button> 
          }

          {/* Unsold */}
          { !sale && has_winner == null && is_ready_to_sell ? <p>Unsold</p> : null }

          {/*  Unfinished */}
          { (!is_finished_creation && !has_winner && is_owner) &&
              <Button type="primary" style={{ width: 'max-content' }} size="large">
                <Link style={{ fontSize: '14px', fontWeight: '600' }} to={`/edit/${id}`}>
                  Finish creation
                </Link>
              </Button> 
          }

          {/* Rejecet / Accept */}
        </div>
        }
        <Divider style={{ margin: '8px 0 16px 0' }} />
        <ul className="card__properties">
          { (published_at && !onTrackedPage) && <li className="card--required"><strong>Published:</strong> {moment(published_at).format('MMMM D, YYYY')}</li> }
          {/* <li className="card--required"><strong>Buyer:</strong> {buyer}</li> */}
          {/* <Popconfirm title="Are you sure you want to delete this car?" onConfirm={e => handleDeleteCar(e)}>
            <DeleteFilled onClick={e => e.stopPropagation()} className="card__delete" />
          </Popconfirm> */}
        </ul>
        {!onTrackedPage && <>
          {isDayLeft(sale?.data?.attributes?.end_date) ? <div className='countdownWrapper'><p>Auction finishes in:</p><div className="countdown"><Countdown date={timeLeft} controlled renderer={customCountdownRenderer} /></div></div> : <><strong>End time:</strong> {moment(sale?.data?.attributes?.end_date).format('DD/MM/YYYY [at] hh:mm a')}</>}
        </>}
      </div>
    </div>
    {onTrackedPage && <Auction car={car} />}
  </div>
  )
}

const Auction = ({ car }) => {
  const { cbs } = useSelector(state => state.messagingReducer)
  const { has_winner: hasWinner, sale: saledata, is_owner, win_bid } = car
  const sale = saledata?.data?.attributes
  const win = win_bid?.data ? win_bid?.data?.attributes : null
  const user = useSelector(state => state.authReducer.user)
  const timeLeft = useTimeLeft(sale?.end_date)

  const dispatch = useDispatch()
  const { handleSubmit, register, watch, formState: { errors }, setValue, getValues } = useForm();
  
  const [bidEnabled, setBidEnabled] = useState(false)
  const [maxBidEnabled, setMaxBidEnabled] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [auctionDone, setAuctionDone] = useState((moment(sale?.end_date).isBefore() || hasWinner || win?.winner_id))

  const auctionPrebid = moment(sale?.start_date).isAfter() && !completed
  const auctionLive = !auctionDone && !auctionPrebid

  useEffect(() => setAuctionDone((moment(sale?.end_date).isBefore() || hasWinner || win?.winner_id)), [sale, win])

  useEffect(() => {
    // array of cable ids 
    const ids = Object.keys(cbs).map(key => key.split('_')?.[1])

    if(auctionLive) {
      console.log('auction is live')
    }

    // if(ids.includes(`${sale?.id}`)) {
    //   dispatch(messagingActions.deleteSub(cbs[`cb_${sale?.id}`], sale?.id))
    // }

    if(!ids.includes(`${sale?.id}`)) {
      dispatch(messagingActions.createSub(sale?.id, dispatch))
    }

    // return () => {
    //   dispatch(messagingActions.deleteSub(cbs[`cb_${sale?.id}`], sale?.id))
    // }    
  }, [sale?.id, auctionLive])
  

  useEffect(() => {
    setValue('amount', sale?.current_live_bid + 100)
  }, [sale?.current_live_bid])

  useEffect(() => {
    setValue('max_amount', 0)
  }, [])

  const putBid = (amount) => {
    if(cbs[`cb_${sale?.id}`]) {
      cbs[`cb_${sale?.id}`].perform('speak', { amount: amount, sale_id: sale?.id, bid_by: user.id, bid_type: 'live' })
      setBidEnabled(false)
    } else {
      console.log('no cb')
    }
  }

  const putMaxBid = (amount) => {
    if(cbs[`cb_${sale?.id}`]) {
      console.log('MAXBID')
      cbs[`cb_${sale?.id}`].perform('speak', { amount: amount, sale_id: sale?.id, bid_by: user.id, bid_type: 'max_price' })
      setMaxBidEnabled(false)
    } else {
      console.log('no cb')
    }
  }

  const onSubmit = data => {
    putBid(parseInt(data.amount))
  }

  const onMaxSubmit = data => {
    putMaxBid(parseInt(data.max_amount))
  }

  const onPreBid = data => {
    dispatch(carsActions.preBid(parseInt(data.amount), sale.id, ))
  }

  const handleChange = e => {
    setValue(e.target.name, parseInt(e.target.value))
  }

  return (
    <div className="card__bidding">
      {auctionPrebid && <form onSubmit={handleSubmit(onPreBid)}>
        <h3 className="countdown__headline">Auction start in</h3>
        <div className="countdown"><Countdown onComplete={() => setCompleted(true)} controlled date={timeLeft} /></div>
        <h2 style={{ fontFamily: 'Montserrat', fontSize: '16px', color: '#008170', margin: '33px 0 16px 0' }}>Reserved Price:  € {sale?.reserved_price}</h2>
        {!is_owner && <>
          <Input
            className="bid-input"
            type="number"
            style={{ borderColor: '#d9d9d9', padding: '9px 16px', flex: 1, height: 40, marginRight: '16px'}}
            suffix={
              <Tooltip title={`Your bid should be at least €100 higher than current bid (${sale?.current_bid + 100}). You can use arrows on your keyboard to change the bid`}>
                <InfoCircleOutlined style={{ color: 'rgba(0,129,112, 0.8)' }} />
              </Tooltip>
            }
            value={watch('amount')}
            { ...register('amount', { required: true, min: sale?.current_bid + 100 }) }
            onChange={handleChange}
          /> 
          <Button disabled={sale?.current_bid_user_id === user?.id} htmlType="submit" size="large" type="primary" style={{ fontWeight: '500' }}>BID NOW</Button>
        </>}
        {errors.amount && <p style={{ color: '#ff0000', marginTop: '8px' }}>Your bid should be at least €100 higher than current bid ({sale?.current_bid + 100})</p> }
      </form>}
      {auctionLive && <>
        <div className="countdown">
          {/* <Countdown date={sale?.created_at ? moment(sale?.created_at).add(29, 'seconds') : moment(sale?.end_date).add(30, 'seconds')} /> */}
          <Countdown date={timeLeft} controlled />
          </div>
        <h2 style={{ fontFamily: 'Montserrat', fontSize: 26, color: '#008170', margin: 0 }}>€ {sale?.current_live_bid}</h2>
        {!is_owner && maxBidEnabled ? <form onSubmit={handleSubmit(onMaxSubmit)}>
          <Input
            className="bid-input"
            type="number"
            name='max_amount'
            style={{ borderColor: '#d9d9d9', padding: '9px 16px', flex: 1, height: 40, marginRight: '16px'}}
            // suffix={
            //   <Tooltip title={`Your bid should be at least €100 higher than current max bid (${sale?.current_max_price + 100}). You can use arrows on your keyboard to change the bid`}>
            //     <InfoCircleOutlined style={{ color: 'rgba(0,129,112, 0.8)' }} />
            //   </Tooltip>
            // }
            value={watch('max_amount')}
            { ...register('max_amount', { required: true, min: 0 }) }
            onChange={handleChange}
          />
          <Button disabled={sale?.current_max_price_user_id == user?.id && sale?.current_live_bid < sale?.current_max_price} htmlType="submit" size="large" type="primary" style={{ fontWeight: '500' }}>MAX BID</Button>
          <Button style={{marginLeft: 7, fontWeight: '500', fontSize: 36, lineHeight: 0.1}} onClick={() => setMaxBidEnabled(false)} size="large" type="primary">×</Button>
        </form> : bidEnabled ? <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            className="bid-input"
            type="number"
            name='amount'
            style={{ borderColor: '#d9d9d9', padding: '9px 16px', flex: 1, height: 40, marginRight: '16px'}}
            suffix={
              <Tooltip title={`Your bid should be at least €100 higher than current bid (${sale?.current_live_bid + 100}). You can use arrows on your keyboard to change the bid`}>
                <InfoCircleOutlined style={{ color: 'rgba(0,129,112, 0.8)' }} />
              </Tooltip>
            }
            value={watch('amount')}
            { ...register('amount', { required: true, min: sale?.current_live_bid + 100 }) }
            onChange={handleChange}
          /> 
          <Button disabled={sale?.current_live_bid_user_id == user?.id || sale?.current_max_price_user_id == user?.id && sale?.current_live_bid < sale?.current_max_price} htmlType="submit" size="large" type="primary" style={{ fontWeight: '500' }}>BID NOW</Button>
          <Button style={{marginLeft: 7, fontWeight: '500', fontSize: 36, lineHeight: 0.1}} onClick={() => setBidEnabled(false)} size="large" type="primary">×</Button>
        </form> : <div className='buttons'>
          <Button disabled={sale?.current_live_bid_user_id == user?.id || sale?.current_max_price_user_id == user?.id && sale?.current_live_bid < sale?.current_max_price} style={{flex: 2, margin: 0, fontWeight: '500'}} onClick={() => setBidEnabled(true)} size="large" type="primary">BID</Button>
          <Button disabled={sale?.current_max_price_user_id == user?.id && sale?.current_live_bid < sale?.current_max_price} style={{flex: 3, margin: 0, fontWeight: '500'}} onClick={() => setMaxBidEnabled(true)} size="large" type="primary">MAX BID</Button>
        </div>}
        {errors.amount && <p style={{ color: '#ff0000', marginTop: '8px' }}>Your bid should be at least €100 higher than current bid ({sale?.current_bid + 100})</p>}
      </>}
    </div>
  )
}

export default TabCard
