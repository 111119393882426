import {NOTIFICATIONS_ACTIONS} from '../constants';

export const getNotifications = meta => ({
  type: NOTIFICATIONS_ACTIONS.GET_NOTIFICATIONS,
  payload: {meta},
});

export const getNotificationsSuccess = notifications => ({
  type: NOTIFICATIONS_ACTIONS.GET_NOTIFICATIONS_SUCCESS,
  payload: {notifications},
});

export const getNotificationsNextPageSuccess = notifications => ({
  type: NOTIFICATIONS_ACTIONS.GET_NOTIFICATIONS_NEXT_PAGE_SUCCESS,
  payload: {notifications},
});

export const getNotificationsError = error => ({
  type: NOTIFICATIONS_ACTIONS.GET_NOTIFICATIONS_ERROR,
  payload: {error},
});

export const removeNotification = id => ({
  type: NOTIFICATIONS_ACTIONS.REMOVE_NOTIFICATION,
  payload: {id},
});

export const removeNotificationSuccess = id => ({
  type: NOTIFICATIONS_ACTIONS.REMOVE_NOTIFICATION_SUCCESS,
  payload: {id},
});

export const removeNotificationError = error => ({
  type: NOTIFICATIONS_ACTIONS.REMOVE_NOTIFICATION_ERROR,
  payload: {error},
});
