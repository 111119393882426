import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { carsActions } from '@actions'
import { Upload, Button, message } from 'antd';
import { ReactComponent as UploadIcon } from '@icons/i-upload.svg'
import ImgCrop from 'antd-img-crop';
import { config } from '@config'

import {CheckCircleOutlined} from '@ant-design/icons';

const Photo = ({ title, attr, headline, additional, addAdditionalPhoto, product_id }) => {
  const dispatch = useDispatch()

  const uploadPhoto = (data, onSuccess, onError) => {
    dispatch(carsActions.addCarPhoto({ 
      product_id,
      title: data.title,
      image: data.file
     }, onSuccess, onError))
  }

  const fileList = [...attr]

  const removePhoto = () => {
    dispatch(carsActions.removeAttachments(attr[0]?.uid, product_id))
  }
    
  const props = {
    fileList: fileList,
    beforeUpload: () => { 
      if(fileList.length) {
        message.error(`File is already uploaded.`);
        return false;
      }
      return true
    },
    customRequest: ({ onSuccess, onError, file }) => {
      uploadPhoto({ file: file, title: title}, onSuccess, onError)
      if(additional) {
        addAdditionalPhoto()
      }
    },
    name: 'file',
    multiple: false,
    accept: ".jpg, .jpeg, .png",
    maxCount: 1,
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log('uploading');
      }

      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onRemove (file) {
      removePhoto()
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1)

  // console.log(attr)

  return (
    <div className="photo">
      <h3 style={{ fontFamily: 'Montserrat' }}>{capitalizeFirstLetter(headline ? headline : title)}</h3>
      {/* <ImgCrop rotate aspect={16 / 9} style={{ cursor: 'pointer' }} quality={1}> */}
        <Upload.Dragger {...props}  className={`photo__uploader ${fileList.length && 'uploader--disabled' }`}>
          { fileList.length ? 
          <>
            <p className="ant-upload-drag-icon">
              <CheckCircleOutlined style={{ transform: 'scale(0.7)', opacity: '0.3' }} />
            </p>
            <p className="ant-upload-text">Uploaded successfully</p>
          </>
          : 
          <>
          <p className="ant-upload-drag-icon">
            <UploadIcon />
          </p>
          <p className="ant-upload-text">Click or drag the file here to upload</p>
          <p className="ant-upload-hint">
            Support extension：.png, .jpg, jpeg
          </p>
          </>}
        </Upload.Dragger>
      {/* </ImgCrop> */}
    </div>
  )
}

export default Photo
