import {takeEvery, put, call} from 'redux-saga/effects';
import {appHistory} from '../../services/HistoryConfig';
import {GLOBAL_ACTIONS} from '@constants';
import {globalActions} from '../actions';
import {processRequest} from '../../services/Api';

function* handleSendContactUs(action) {
  try {
    const {payload} = action;
    const {contact_us} = payload;
    const requestPayload = {contact_us};
    const {data} = yield call(
      processRequest,
      '/contact_us',
      'POST',
      requestPayload,
    );
    if (data.message) {
      appHistory.back();
      yield put(
        globalActions.sendContactUsSuccess(
          data.message || 'Message successfuly sent',
        ),
      );
    }
  } catch (e) {
    const {response} = e;
    const {data, status, statusText} = response || {};
    const {error_messages, error, error_message} = data || {};

    if (status === 400) {
      let message = '';
      if (error_message) {
        message = error_message;
      } else if (typeof error_messages === 'string') {
        message = error_messages;
      } else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];

        message = error_messages && `${keys[0]} ${errorMessage}`;
      }

      yield put(globalActions.sendContactUsError(message));
    } else if (status === 401) {
      yield put(globalActions.sendContactUsError(error));
    } else if (status === 500) {
      yield put(
        globalActions.sendContactUsError(
          statusText || 'Internal server error.',
        ),
      );
    } else if (e.message) {
      yield put(globalActions.sendContactUsError(e.message));
    } else {
      yield put(globalActions.sendContactUsError('Internal server error.'));
    }
  }
}

export function* watchGlobalSagas() {
  yield takeEvery(
    GLOBAL_ACTIONS.SEND_CONTACT_US,
    handleSendContactUs,
  );
}
