import {AUTH_ACTIONS, LOCAL_STORAGE} from '@constants';
import { ROUTES } from '@router/routes';
import {authActions, globalActions, profileActions} from '@actions';
import {processRequest} from '@services/Api';
import { put, call, takeEvery } from 'redux-saga/effects'
import {getUserSelector} from '../selectors/auth';
import { push } from 'connected-react-router'

import { setToken, getToken } from '@services/LocalStorage'

function* handleIsAuthenticated() {
  try {
    const userJSON = localStorage.getItem(LOCAL_STORAGE.USER);
    const token = getToken();
    if(token) {
      yield put(profileActions.getCurrentUser());
    }

    if (userJSON && token) {
      const user = JSON.parse(userJSON);
      yield put(authActions.loginSuccess(user));
    }
  } catch (e) {
    console.log(e);
    const {data, status, statusText} = e || {};
    const {error_messages, error, error_message} = data || {};

    if (status === 400) {
      let message = '';
      if (error_message) {
        message = error_message;
      } else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];

        message = error_messages && `${keys[0]} ${errorMessage}`;
      }

      yield put(authActions.loginError(message));
    } else if (status === 401) {
      yield put(authActions.loginError(error));
    } else if (status === 500) {
      yield put(authActions.loginError(statusText || 'Internal server error.'));
    } else if (e.message) {
      yield put(authActions.loginError(e.message));
    } else {
      yield put(authActions.loginError('Internal server error.'));
    }
  }
}

function* handleLogin(action) {
  try {
    const {payload} = action;
    const {email, password, garage_code, redirect} = payload;
    const requestPayload = {user: {email: email, password: password, garage_code: garage_code}};
    const response = yield call(
      processRequest,
      '/authorization/login',
      'POST',
      requestPayload,
    );

    console.log(response)

    if (response?.data) {
      const { data } = response
      localStorage.setItem(LOCAL_STORAGE.USER, JSON.stringify(data.user.data.attributes));
      setToken(
        LOCAL_STORAGE.AUTH_TOKEN,
        data.token,
      );

      setToken(
        LOCAL_STORAGE.REFRESH_TOKEN,
        data.refresh_token,
      );

      // yield call(appHistory.push, ROUTES.FEED)
      if(redirect) {
        yield put(push(ROUTES.HOME))
      }
      yield put(authActions.loginSuccess(data.user.data.attributes));
    }
  } catch (e) {
    console.log(12122121)
    const {response} = e || {};
    const {data, status, statusText} = response || {};
    const {error_messages, error, error_message} = data || {};

    if (status === 400) {
      let message = '';
      if (error_message) {
        message = error_message;
      } else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];

        message = error_messages && `${keys[0]} ${errorMessage}`;
      }

      yield put(globalActions.setError(message));
      yield put(authActions.loginError(message));
    } else if (status === 401) {
      yield put(globalActions.setError(error));
      yield put(authActions.loginError(error));
    } else if (status === 500) {
      yield put(globalActions.setError(statusText || 'Internal server error.'));
      yield put(authActions.loginError(statusText || 'Internal server error.'));
    } else if (e.message) {
      yield put(globalActions.setError(e.message));
      yield put(authActions.loginError(e.message));
    } else {
      yield put(globalActions.setError('Internal server error.'));
      yield put(authActions.loginError('Internal server error.'));
    }
  }
}

function* handleSignUp(action) {
  try {
    const {
      payload: {formData},
    } = action || {};
    const {email, password, garage_code} = formData;
    const requestPayload = {
      user: {
        email: email.toLowerCase(),
        password,
        garage_code
      },
    };

    const {data} = yield call(
      processRequest,
      '/users',
      'POST',
      requestPayload,
    );
    if (data.user && data.token) {
      const {token, user} = data;
      console.log(data)
      localStorage.setItem(LOCAL_STORAGE.USER, JSON.stringify(user.data.attributes));
      setToken(
        LOCAL_STORAGE.AUTH_TOKEN,
        token,
      );

      // yield put(authActions.stepAdd())
      yield put(authActions.signUpSuccess(user.data.attributes));
      yield put(push(ROUTES.CONFIRM_EMAIL))

      // yield call(AsyncStorage.setItem, LOCAL_STORAGE.TOKEN, token.auth_token);
      // yield call(AsyncStorage.setItem, LOCAL_STORAGE.USER, jsonUser);
      // yield put(authActions.nextStep(user, ROUTES.USERNAME_PAGE));
      // yield put(globalActions.toggleLoading(false));
    }
  } catch (e) {
    const {data, status, statusText} = e || {};
    const {error_messages, error, error_message} = data || {};

    if (status === 400) {
      let message = '';
      if (error_message) {
        message = error_message;
      } else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];
        if (keys[0] === 'password') {
          message = "The password field can't be blank";
        } else {
          message =
            error_messages && keys[0] === 'email'
              ? `${errorMessage}`
              : `${keys[0]} ${errorMessage}`;
        }
      }

      yield put(authActions.signUpError(message));
    } else if (status === 401) {
      yield put(authActions.signInError(error));
    } else if (status === 500) {
      yield put(authActions.signUpError(statusText));
    } else if (e.message) {
      yield put(authActions.signUpError(e.message));
    } else {
      yield put(authActions.signUpError('Internal server error.'));
    }
  }
}

function* handleLogout() {
  try {
    // const {data} = yield call(processRequest, '/authentication/logout', 'PUT');
    // if (data.data.mesage === 'Success') {
    localStorage.removeItem(LOCAL_STORAGE.AUTH_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE.REFRESH_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE.USER);
    yield put(authActions.logoutSuccess());
    yield put(push(ROUTES.LOGIN))
    // }
  } catch (e) {
    const {response} = e || {};
    const {data, status, statusText} = response || {};
    const {error_messages, error, error_message} = data || {};

    if (status === 400) {
      let message = '';
      if (error_message) {
        message = error_message;
      } else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];

        message = error_messages && `${keys[0]} ${errorMessage}`;
      }

      yield put(globalActions.setError(message));
      yield put(authActions.logoutError(message));
    } else if (status === 401) {
      yield put(globalActions.setError(error));
      yield put(authActions.logoutError(error));
    } else if (status === 500) {
      yield put(globalActions.setError(statusText || 'Internal server error.'));
      yield put(
        authActions.logoutError(statusText || 'Internal server error.'),
      );
    } else if (e.message) {
      yield put(globalActions.setError(e.message));
      yield put(authActions.logoutError(e.message));
    } else {
      yield put(globalActions.setError('Internal server error.'));
      yield put(authActions.logoutError('Internal server error.'));
    }
  }
}

function* handleBuyerConfirm(action) {
  try {
    const {payload} = action || {};
    const {url} = payload || {};
    const {data} = yield call(processRequest, url, 'PUT');
    if (data.user) {
      localStorage.setItem(LOCAL_STORAGE.USER, JSON.stringify(data.user.data.attributes));
      setToken(
        LOCAL_STORAGE.AUTH_TOKEN,
        data.token,
      );
      yield put(authActions.buyerConfirmSuccess(data.user.data.attributes));
      yield put(authActions.loginSuccess(data.user.data.attributes));
      if (data.user.data.attributes.user_type === 'manager') {
        yield put(push(ROUTES.REGISTER));
        yield put(authActions.stepAdd());
      } else {
        yield put(push(ROUTES.JOIN_THE_GARAGE))
      }
    }
  } catch (e) {
    const {response} = e || {};
    const {data, status, statusText} = response || {};
    const {error_messages, error, error_message} = data || {};

    if (status === 400) {
      let message = '';
      if (error_message) {
        message = error_message;
      } else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];

        message = error_messages && `${keys[0]} ${errorMessage}`;
      }

      yield put(globalActions.setError(message));
      yield put(authActions.buyerConfirmError(message));
    } else if (status === 401) {
      yield put(globalActions.setError(error));
      yield put(authActions.buyerConfirmError(error));
    } else if (status === 500) {
      yield put(globalActions.setError(statusText || 'Internal server error.'));
      yield put(
        authActions.buyerConfirmError(statusText || 'Internal server error.'),
      );
    } else if (e.message) {
      yield put(globalActions.setError(e.message));
      yield put(authActions.buyerConfirmError(e.message));
    } else {
      yield put(globalActions.setError('Internal server error.'));
      yield put(authActions.buyerConfirmError('Internal server error.'));
    }
  }
}

function* handleForgotPassword(action) {
  try {
    const {payload} = action || {};
    const {email} = payload || {};
    const requestPayload = {email};
    const {data} = yield call(
      processRequest,
      '/passwords/forgot',
      'PUT',
      requestPayload,
    );

    if (data) {
      yield put(push(ROUTES.LOGIN));
      yield put(authActions.forgotPasswordSuccess());
    } else {
      yield put(authActions.forgotPasswordError(data));
    }
  } catch (e) {
    const {response} = e || {};
    const {data, status, statusText} = response || {};
    const {error_messages, error, error_message} = data || {};

    if (status === 400) {
      let message = '';
      if (error_message) {
        message = error_message;
      } else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];

        message = error_messages && `${keys[0]} ${errorMessage}`;
      }

      yield put(globalActions.setError(message));
      yield put(authActions.forgotPasswordError(message));
    } else if (status === 401) {
      yield put(globalActions.setError(error));
      yield put(authActions.forgotPasswordError(error));
    } else if (status === 500) {
      yield put(globalActions.setError(statusText || 'Internal server error.'));
      yield put(authActions.forgotPasswordError(statusText || 'Internal server error.'));
    } else if (e.message) {
      yield put(globalActions.setError(e.message));
      yield put(authActions.forgotPasswordError(e.message));
    } else {
      yield put(globalActions.setError('Internal server error.'));
      yield put(authActions.forgotPasswordError('Internal server error.'));
    }
  }
}

export function* watchAuthSagas() {
  yield takeEvery(AUTH_ACTIONS.IS_AUTHENTICATED, handleIsAuthenticated);
  yield takeEvery(AUTH_ACTIONS.SIGN_UP, handleSignUp);
  yield takeEvery(AUTH_ACTIONS.LOGIN, handleLogin);
  yield takeEvery(AUTH_ACTIONS.LOGOUT, handleLogout);
  yield takeEvery(AUTH_ACTIONS.BUYER_CONFIRM_REQUEST, handleBuyerConfirm);
  yield takeEvery(AUTH_ACTIONS.FORGOT_PASSWORD, handleForgotPassword);
}