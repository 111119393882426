import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { carsActions } from '@actions'
import { Button, Select,  } from 'antd';
import { useForm, useWatch } from "react-hook-form";
import { getYears } from '@helpers';
import CustomSelect from '@components/CustomSelect';

const { Option } = Select;

const ServiceHistory = () => {
  const { product_id, history_types } = useSelector(state => state.carsReducer)
  const { handleSubmit, register, watch, formState: { errors }, control } = useForm();
  const dispatch = useDispatch()

  // const lastService = watch('history_year', 'If Known')

  const tempYears = [ 'If Known', ...getYears() ];

  const lastService = useWatch({
    control,
    name: "history_year", 
    defaultValue: tempYears[0]
  });

  const onSubmit = data => {
    if (lastService !== 'If Known') {
      dispatch(carsActions.addCarSerivceHistory({
        ...data,
        last_service: data.history_month && data.history_year ? `${data.history_month}/${data.history_year}` : null,
        product_id
      }))
    } else {
      dispatch(carsActions.addStep())
    }
  }

  useEffect(() => {
    dispatch(carsActions.getHistoryTypes())
  }, [])
  
  return (
    <form className="sell-form" onSubmit={handleSubmit(onSubmit)}>
      <h1>Sell a car</h1>
      <p style={{ textAlign: 'center', marginBottom: '24px' }}>Fill in all important info to help sale your car.</p>
      <CustomSelect required={lastService !== 'If Known'} label="Service History" name="history_type" control={control} placeholder="Choose the services history type from the list" >
        { history_types?.map(type => <Option value={type.value}>{type.key}</Option>) }
      </CustomSelect>

      <div className="sell-form__label-wrapper" style={{ marginBottom: '32px' }}>
        <CustomSelect  label="Last service" values={tempYears} defaultValue={tempYears[0]} control={control} placeholder="Year" name="history_year" />
        <CustomSelect labelStyle={{ color: 'transparent' }} label="text" values={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]} control={control} placeholder="Month" name="history_month" />
        <label className="sell-form__label">
          Odometer
          <input { ...register('odometer') } type="text" className="sell-form__input" placeholder="Odometer" style={{ fontSize: '14px', padding: '6px 10px' }} />
        </label>
      </div>
      <Button htmlType="submit" style={{ padding: '0 50px' }} className="sell__submit" size="large" type="primary">NEXT STEP</Button>
    </form>
  )
}

export default ServiceHistory
