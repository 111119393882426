import React from 'react'
// import { Card } from 'antd'
import { Button, Divider, Typography } from 'antd';
import { config } from '@config'
import { useHistory } from 'react-router-dom'
import moment from 'moment';
import isDayLeft from '../../helpers/isDayLeft';
import Countdown from 'react-countdown';
import customCountdownRenderer from '../../helpers/customCountdownRenderer';
import useTimeLeft from '../../hooks/useTimeLeft'

const AuctionsCard = ({ 
  id,
  current_bid,
  make, 
  model,
  lot,
  reserved_price,
  front_image_url,
  odometer,
  end_date,
  fuel_type,
  engine,
  car_year,
  variant = ''
}) => {
  const timeLeft = useTimeLeft(end_date)
  
  const history = useHistory()

  const handleRedirect = () => { 
    history.push(`/cars/${id}`)
  }

  const makeName = make?.data?.attributes?.title
  const modelName = model?.data?.attributes?.title  
  // const trimName = trim?.data?.attributes?.name  

  return (
    <div className="card adaptive" style={{ cursor: 'pointer' }} onClick={handleRedirect}>
      <div className="card__image" style={{ backgroundImage: `url(${config.REACT_APP_IMAGE_URL + front_image_url})` }}></div>
      <div className="card__content">
        <div className="card__header">
          <div className="card__headline">
            <span>Current Bid: € {current_bid || reserved_price}</span>
            <Typography.Title ellipsis={{ rows: 2, expandable: false }} level={3}>
              {makeName} {modelName} {variant} {engine} {fuel_type}
            </Typography.Title>
          </div>
          <Button className="card__btn" size="large" type="primary">JOIN AUCTION</Button>
        </div>
        <Divider style={{ margin: '8px 0 16px 0' }} />
        <ul className="card__properties">
          <li className="card--required"><strong>Year:</strong> {car_year}</li>
          <li className="card--required"><strong>Odometer:</strong> {odometer}</li>
          <li className="card--required"><strong>Engine:</strong> {engine}</li>
          <li className="card--required" style={{ marginBottom: '8px' }}><strong>Lot:</strong> {lot}</li>
        </ul>
        {isDayLeft(end_date) ? <div className='countdownWrapper'><p>Auction finishes in:</p><div className="countdown"><Countdown date={timeLeft} controlled renderer={customCountdownRenderer} /></div></div> : <><strong>End time:</strong> {moment(end_date).format('DD/MM/YYYY [at] hh:mm a')}</>}
      </div>
    </div>
  )
}

export default AuctionsCard
