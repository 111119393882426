import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { getToken, getUser } from '@services/LocalStorage'
import { ROUTES } from './routes';

const PrivateRoute = (props) => {
  const token = getToken()
  const user = getUser()

  return (
    token  ? <Route {...props } /> : <Redirect to={ROUTES.LOGIN} />
  )
}

export default PrivateRoute
