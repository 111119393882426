import {combineReducers} from 'redux';
import {authReducer} from './auth';
import {globalReducer} from './global';
import {carsReducer} from './cars';
import {profileReducer} from './profile';
import {messagingReducer} from './messages';
import {paymentsReducer} from './payments';
import {carFilterReducer} from './carFilter';
import {notificationsReducer} from './notifications';
import {myCarsTabsReducer} from './myCarsTabs';
import { connectRouter } from 'connected-react-router'

export default (history) => combineReducers({
  router: connectRouter(history),
  authReducer,
  globalReducer,
  carsReducer,
  profileReducer,
  paymentsReducer,
  messagingReducer,
  carFilterReducer,
  notificationsReducer,
  myCarsTabsReducer,
});
