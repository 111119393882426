import React from 'react'
import {useSelector} from 'react-redux';
import { Avatar } from 'antd';
import moment from 'moment';
import {config} from '../../config';
import { ReactComponent as CrossIcon } from '@icons/i-cross.svg'

const Notification = ({notification, handleRemoveNotification}) => {
  const {user} = useSelector(({authReducer}) => authReducer);
  const removeNotification = () => {
    handleRemoveNotification(notification.id);
  };
  const getType = () => {
    switch(notification.feed_type) {
      case 'reject_winner': {
        const feedable = notification.feedable.data.attributes;
        return {
          avatar: feedable.avatar_thumb_url,
          data: `${feedable.user_name} reject winner`,
        };
      }
      case 'you_win_bid': {
        return {
          avatar: user.avatar_thumb_url,
          data: 'You win bid',
        };
      }
      case 'win_bid': {
        return {
          avatar: notification.feedable.data.attributes.winner.data.attributes.avatar_thumb_url,
          data: ' has won ',
          who: notification.feedable.data.attributes.winner.data.attributes.user_name,
          what: `${notification.feedable.data.attributes.product.data.attributes.make.title} ${notification.feedable.data.attributes.product.data.attributes.model.title}`
        };
      }
      case 'accept_winner': {
        return {
          avatar: '',
          data: 'Accept winner',
        };
      }
      default:
        return {
          avatar: '',
          data: '',
        };
    }
  };
  const data = getType();
  return (
    <div className="item">
      <div className="item__content">
        <Avatar
          size={55}
          src={data.avatar ?
            `${config.REACT_APP_IMAGE_URL}${data.avatar}` :
            "https://img.oastatic.com/img2/48129701/1080x410r/rocky-mountains-near-ward-colorado.jpg"
          }
        />
        <div className="item__text">
          <span>{moment(notification.feed_date).fromNow()}</span>
          {/* <p><strong>Buyer Name</strong> win the bet <strong>Porsche MACAN S</strong></p> */}
          <p><strong>{data.who}</strong>{data.data}<strong>{data.what}</strong></p>
        </div>
      </div>
      <CrossIcon onClick={removeNotification} />
    </div>
  )
}

export default Notification
