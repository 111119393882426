import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { profileActions, carsActions } from '@actions'
import { useForm } from "react-hook-form";
import { Button } from 'antd';
import AddBuyer from './AddBuyer';
import Buyer from './Buyer';
import { DEALER_TYPES, LOCATION_TYPES } from '@mainConstants';
import CustomSelect from '@components/CustomSelect';

const MyProfile = () => {
  const { user, error } = useSelector(({authReducer}) => authReducer);
  const [selectedDealerType, setDealerType] = useState(user?.dealer_type || '');
  const [selectedLocation, setLocation] = useState(user?.location || '');
  const { buyersMeta, buyers, buyersForm } = useSelector(({carsReducer}) => carsReducer);
  const { error: globalError } = useSelector(({globalReducer}) => globalReducer);
  const { handleSubmit, register, watch,  formState: { errors }, control, setValue, clearErrors } = useForm({
    defaultValues: {
      business_name: user?.business_name || '',
      address: user?.address || '',
      eircode: user?.eircode || '',
      garage_code: user?.garage_code || '',
      iban: user?.iban || '',
      bank_name: user?.bank_name || '',
      bank_address: user?.bank_address || '',
      dealer_type: user?.dealer_type || '',
      location: user?.location || '',
      name: user?.name || '',
      title: user?.title || '',
      phone_number: user?.phone_number || '',
    },
  });
  const mail = watch('email')
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(carsActions.getBuyers({
      ...buyersMeta,
      page: 1,
    }));
  }, []);

  const onSubmit = data => {
    dispatch(profileActions.updateProfile({
      ...user,
      ...data,
      dealer_type: selectedDealerType,
      location: selectedLocation,
    }, false, true))
  }

  const onSubmitBuyers = data => {
    dispatch(carsActions.createBuyer(data))
  }

  const onChangeDealerType = value => {
    const typeId = DEALER_TYPES.filter(type => type.title === value)[0].value;

    setDealerType(typeId);
    setValue('dealer_type', value);
    clearErrors('dealer_type');
  }

  const onChangeLocation = value => {
    const typeId = LOCATION_TYPES.filter(type => type.title === value)[0].value;

    setLocation(typeId);
    setValue('location', value);
    clearErrors('location');
  }

  const dealerTypes = DEALER_TYPES.map(type => type.title);
  const locations = LOCATION_TYPES.map(location => location.title);

  return (
    <form className="sell-form sell-main edit-profile-form" onSubmit={handleSubmit(onSubmit)}>
      <h1 style={{textAlign: 'center'}}>Edit account</h1>
      <p className="dealer-details-text">Dealer details</p>

      <label className="sell-form__label" style={{ marginTop: '24px' }} >
        Official business name
        <input { ...register('business_name', { required: true }) } type="text" className="sell-form__input" />
      </label>
      { errors.business_name && <p className="error">Bussiness name is required</p> }

      <label className="sell-form__label">
        Phone
        <input type="tel" className="sell-form__input" placeholder="Phone" { ...register("phone_number", { required: true}) } />
      </label>
      { errors.phone_number && <p className="error">Phone is required</p> }

      <label className="sell-form__label" style={{ marginTop: '24px' }} >
        Name
        <input { ...register('name', { required: true }) } type="text" className="sell-form__input"/>
      </label>
      { errors.name && <p className="error">Name is required</p> }

      <label className="sell-form__label" style={{ marginTop: '24px' }} >
        Title
        <input { ...register('title', { required: true }) } type="text" className="sell-form__input"/>
      </label>
      { errors.title && <p className="error">Title is required</p> }

      <label className="sell-form__label" >
        Address
        <input { ...register('address', { required: true }) } type="text" className="sell-form__input" />
      </label>
      { errors.address && <p className="error">Address is required</p> }

      <label className="sell-form__label" >
        Eircode
        <input { ...register('eircode', { required: true }) } type="text" className="sell-form__input" />
      </label>
      { errors.eircode && <p className="error">Eircode is required</p> }

      <label className="sell-form__label" >
        Garage code
        <input { ...register('garage_code', { required: true }) } type="text" className="sell-form__input" />
      </label>
      { errors.garage_code && <p className="error">Garage code is requied</p> }
      <CustomSelect
        label="Dealer Type"
        control={control}
        placeholder="Dealer Type"
        name="dealer_type"
        values={dealerTypes}
        onChange={onChangeDealerType}
        required
      />
      { errors.dealer_type && <p className="error">Dealer Type is required</p> }
      <CustomSelect
        label="Location"
        control={control}
        placeholder="Location"
        name="location"
        values={locations}
        onChange={onChangeLocation}
        required
      />
      { errors.location && <p className="error">Location is required</p> }

      {/* <label className="sell-form__label" >
        Iban
        <input { ...register('iban', { required: true }) } type="text" className="sell-form__input"/>
      </label>
      { errors.eircode && <p className="error">Iban is required</p> }
      <label className="sell-form__label" >
        Bank name
        <input { ...register('bank_name', { required: true }) } type="text" className="sell-form__input"/>
      </label>
      { errors.bank_name && <p className="error">Bank name is required</p> }
      <label className="sell-form__label" >
        Bank address
        <input { ...register('bank_address', { required: true }) } type="text" className="sell-form__input"/>
      </label>
      { errors.bank_address && <p className="error">Bank address is required</p> } */}

      {buyers.length > 0 && (
        <>
          <p className="dealer-details-text">Extra Buyers</p>
          {buyers.map(buyer => (
            <Buyer key={buyer.id} buyer={buyer} />
          ))}
        </>
      )}
      { buyersForm?.map(num => (
        <AddBuyer onSubmit={onSubmitBuyers} number={num} disabled={num !== buyersForm.length} />
      )) }

      <Button
        htmlType="submit"
        style={{
          padding: '10px 40px',
          lineHeight: '1',
          fontSize: '14px',
          fontWeight: '600',
          textTransform: 'uppercase',
        }}
        className="sell__submit"
        size="large"
        type="primary"
      >
        Save changes
      </Button>
      { error && <p className="error">{error}</p> }
      { globalError && <p className="error">{globalError}</p> }
    </form>
  )
}

export default MyProfile
