export default {
  FOLLOW : 'FOLLOW',
  UNFOLLOW : 'UNFOLLOW',
  FOLLOW_SUCCESS : 'FOLLOW_SUCCESS',
  FOLLOW_ERROR : 'FOLLOW_ERROR',
  UNFOLLOW_SUCCESS : 'UNFOLLOW_SUCCESS',
  UNFOLLOW_ERROR : 'UNFOLLOW_ERROR',
  BLOCK : 'BLOCK',
  BLOCK_SUCCESS : 'BLOCK_SUCCESS',
  UNBLOCK : 'UNBLOCK',
  UNBLOCK_SUCCESS : 'UNBLOCK_SUCCESS',
  GET_CURRENT_USER : 'GET_CURRENT_USER',
  GET_CURRENT_USER_SUCCESS : 'GET_CURRENT_USER_SUCCESS',
  GET_CURRENT_USER_ERROR : 'GET_CURRENT_USER_ERROR',
  GET_USER_DETAILS : 'GET_USER_DETAILS',
  GET_USER_DETAILS_SUCCESS : 'GET_USER_DETAILS_SUCCESS',
  GET_USER_DETAILS_ERROR : 'GET_USER_DETAILS_ERROR',
  UPLOAD_AVATAR : 'UPLOAD_AVATAR',
  UPLOAD_AVATAR_SUCCESS : 'UPLOAD_AVATAR_SUCCESS',
  UPLOAD_AVATAR_ERROR : 'UPLOAD_AVATAR_ERROR',
  UPDATE_PROFILE : 'UPDATE_PROFILE',
  UPDATE_PROFILE_SUCCESS : 'UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_ERROR : 'UPDATE_PROFILE_ERROR',
  UPDATE_PASSWORD : 'UPDATE_PASSWORD',
  UPDATE_PASSWORD_SUCCESS : 'UPDATE_PASSWORD_SUCCESS',
  UPDATE_PASSWORD_ERROR : 'UPDATE_PASSWORD_ERROR',
  GET_FOLLOWERS : 'GET_FOLLOWERS',
  GET_FOLLOWERS_SUCCESS : 'GET_FOLLOWERS_SUCCESS',
  GET_FOLLOWERS_NEXT_PAGE_SUCCESS : 'GET_FOLLOWERS_NEXT_PAGE_SUCCESS',
  GET_FOLLOWERS_ERROR : 'GET_FOLLOWERS_ERROR',
  GET_FOLLOWING : 'GET_FOLLOWING',
  GET_FOLLOWING_SUCCESS : 'GET_FOLLOWING_SUCCESS',
  GET_FOLLOWING_NEXT_PAGE_SUCCESS : 'GET_FOLLOWING_NEXT_PAGE_SUCCESS',
  GET_FOLLOWING_ERROR : 'GET_FOLLOWING_ERROR',
  GET_USERS : 'GET_USERS',
  GET_USERS_SUCCESS : 'GET_USERS_SUCCESS',
  GET_USERS_NEXT_PAGE_SUCCESS : 'GET_USERS_NEXT_PAGE_SUCCESS',
  GET_USERS_ERROR : 'GET_USERS_ERROR',
  GET_RATINGS : 'GET_RATINGS',
  GET_RATINGS_SUCCESS : 'GET_RATINGS_SUCCESS',
  GET_RATINGS_NEXT_PAGE_SUCCESS : 'GET_RATINGS_NEXT_PAGE_SUCCESS',
  GET_RATINGS_ERROR : 'GET_RATINGS_ERROR',
  REPORT_USER : 'REPORT_USER',
  REPORT_USER_ERROR : 'REPORT_USER_ERROR',
  REPORT_USER_SUCCESS : 'REPORT_USER_SUCCESS',
  GET_BLACK_LIST : 'GET_BLACK_LIST',
  GET_BLACK_LIST_SUCCESS : 'GET_BLACK_LIST_SUCCESS',
  GET_BLACK_LIST_ERROR : 'GET_BLACK_LIST_ERROR',
  BLOCK_UNBLOCK_ERROR : 'BLOCK_UNBLOCK_ERROR',
  SEND_DONATION : 'SEND_DONATION',
  SEND_DONATION_ERROR : 'SEND_DONATION_ERROR',
  GET_DONATIONS : 'GET_DONATIONS',
  GET_DONATIONS_SUCCESS : 'GET_DONATIONS_SUCCESS',
  GET_DONATIONS_NEXT_PAGE_SUCCESS : 'GET_DONATIONS_NEXT_PAGE_SUCCESS',
  GET_DONATIONS_ERROR : 'GET_DONATIONS_ERROR',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  GET_PETS_RATINGS: 'GET_PETS_RATINGS',
  GET_PETS_RATINGS_SUCCESS: 'GET_PETS_RATINGS_SUCCESS',
  GET_PETS_RATINGS_NEXT_PAGE_SUCCESS: 'GET_PETS_RATINGS_NEXT_PAGE_SUCCESS',
  GET_SERVICES_RATINGS: 'GET_SERVICES_RATINGS',
  GET_SERVICES_RATINGS_SUCCESS: 'GET_SERVICES_RATINGS_SUCCESS',
  GET_SERVICES_RATINGS_NEXT_PAGE_SUCCESS: 'GET_SERVICES_RATINGS_NEXT_PAGE',
  GET_RATINGS_ERROR: 'GET_RATINGS_ERROR',
  SAVE_BUYER_REQUEST: 'SAVE_BUYER_REQUEST',
  SAVE_BUYER_SUCCESS: 'SAVE_BUYER_SUCCESS',
  SAVE_BUYER_ERROR: 'SAVE_BUYER_ERROR',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_ERROR: 'CHANGE_PASSWORD_ERROR',
  CHANGE_EMAIL: 'CHANGE_EMAIL',
  CHANGE_EMAIL_SUCCESS: 'CHANGE_EMAIL_SUCCESS',
  CHANGE_EMAIL_ERROR: 'CHANGE_EMAIL_ERROR',
}