import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { authActions } from '../redux/actions';

const UserConfirmation = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		const {location} = window;
		if (location.pathname.includes('/user_confirmation')) {
      const url = location.pathname.replace('/api/v1', '');

      dispatch(authActions.buyerConfirmRequest(url));
		}
	}, []);

  return (
    <>
    </>
  )
}

export default UserConfirmation
