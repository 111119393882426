import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import Header from '@components/Header/'
import {carFilterActions} from '@actions';
import Marketplace from '../components/Marketplace/Marketplace'
import ContentWrapper from '@components/Markup/ContentWrapper'
import Footer from '@components/Footer'


const MarketplacePage = () => {
  const dispatch = useDispatch();
  const {makes, models, filter} = useSelector(({carFilterReducer}) => carFilterReducer);
  useEffect(() => {
    dispatch(carFilterActions.getMakes());
  }, []);
  const handleMakeClick = value => {
    dispatch(carFilterActions.getModels(value));
  };
  return (
    <ContentWrapper>
      <Header />
      <Marketplace
        handleMakeClick={handleMakeClick}
        makes={makes}
        models={models}
        filter={filter}
      />
      
      <ContentWrapper.Sticky>
        <Footer />
      </ContentWrapper.Sticky>
    </ContentWrapper>

  )
}

export default MarketplacePage
