import React from 'react'
import Header from '@components/Header'
import Landing from '@components/Landing'
import Footer from '@components/Footer'
import ContentWrapper from '@components/Markup/ContentWrapper'

const LandingPage = () => {
  return (
    <ContentWrapper>
      <Header />
      <Landing />
      <ContentWrapper.Sticky>
        <Footer />
      </ContentWrapper.Sticky>
    </ContentWrapper>
  )
}

export default LandingPage
