import React from "react"
import { useSelector } from "react-redux"

const useTimeLeft = (end_date) =>  {
	const { time } = useSelector(state => state.globalReducer)
	const timeNow = () => new Date(Date.now() - time[1].getTime() + time[0].getTime()).getTime()
	const timer = React.createRef(null)
	const [timeLeft, setTimeLeft] = React.useState(new Date(end_date).getTime() - timeNow())

	React.useEffect(() => {
		timer.current = setInterval(() => {
			const newTimeLeft = new Date(end_date).getTime() - timeNow()
			setTimeLeft(newTimeLeft)
		}, 100)
		return () => clearInterval(timer.current)
	}, [end_date])

	return end_date ? timeLeft : null
}

export default useTimeLeft