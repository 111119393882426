import React, { useState, useEffect } from 'react'
import { attachmentsToObject } from '@helpers'
import { config } from '@config'

const CarPhotos = ({ attachments, setOpenModal }) => {
  const [images, setImages] = useState([])
  console.log(attachments)

  useEffect(() => {
    if( attachments?.length) {
      setImages(attachments?.map(attch => attch.attributes))
    }
  }, [attachments])

  return images.length ? (
    <div className="car__slider">
      <div style={{ backgroundImage: `url(${config.REACT_APP_IMAGE_URL + images[0].image_url})` }} className="car__main-image"></div>
      <div className="car__img-row">
        <div style={{ backgroundImage: `url(${config.REACT_APP_IMAGE_URL + images[1]?.image_url})` }}  className="car__row-img"></div>
        <div style={{ backgroundImage: `url(${config.REACT_APP_IMAGE_URL + images[2]?.image_url})` }} className="car__row-img"></div>
        <div onClick={() => setOpenModal(true)} style={{ backgroundImage: `url(${config.REACT_APP_IMAGE_URL + images[3]?.image_url})`, cursor: 'pointer' }} className="car__row-img blured">
          <p className="img__overlay-text">show all photos</p>
        </div>
      </div>
    </div>
  ) : null
}

export default CarPhotos
