import React from 'react'
import PropTypes from 'prop-types';
import { ReactNotifications, Store } from 'react-notifications-component';
import { toggleNotification } from '../../redux/actions/global'
import { useDispatch, useSelector } from 'react-redux';
import './Markup.scss'
import 'react-notifications-component/dist/theme.css'
import 'animate.css/animate.compat.css'

const ContentWrapper = ({ children, contentClass }) => {
  const dispatch = useDispatch()
  const { notification } = useSelector(state => state.globalReducer)
  const sticky = React.Children.map(children, child => child.type.displayName === 'Sticky' ? child : null);
  const otherChildren = React.Children.map(children, child => child.type.displayName ? null : child );

  React.useEffect(() => {
    if(notification) {
      Store.addNotification({
        title: notification?.title || '',
        message: notification?.message || '',
        type: notification?.type || 'success',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 5000,
          onScreen: true
        },
        onRemoval: () => dispatch(toggleNotification(null))
      });
    }
  }, [notification, dispatch])

  return (
    <div className="content-wrapper" id="scrollTarget">
      <ReactNotifications />
      <div className={`content ${contentClass}`}>
        { otherChildren }
      </div>
      <div className="sticky">
        { sticky }
      </div>
    </div>
  )
}

ContentWrapper.propTypes = { children: PropTypes.node.isRequired };

const Sticky = ({ children }) => children;
Sticky.displayName = 'Sticky';
ContentWrapper.Sticky = Sticky;

export default ContentWrapper
