import React from 'react'
import './Faq.scss'
import {Menu} from 'antd';

const { SubMenu } = Menu;

const Faq = () => {
  return (
    <>
      <div className='question-title'>Frequently Asked Questions</div>
      <div className='question-content'>
        <Menu mode="inline">
          <div className='underline'></div>
          <SubMenu key="sub1" title="How does MotoBids work?">
            <Menu.Item key="1">
              Sellers load their trade cars for sale in the next available auction (Monday, Wednesday &amp;
              Friday @ 10.30am). The seller inputs the information and sets a reserve price. The car
              automatically has a MotorCheck History Report done and then goes into the auction where
              buyers can bid on the car. Buyers bid from their mobiles or Desktops.
            </Menu.Item>
          </SubMenu>
          <div className='underline'></div>
          <SubMenu key="sub2" title="How do I sign up?">
            <Menu.Item key="2">
              You can register to be a buyer, seller or both on our website, MotoBids.ie. During the
              registration, you will enter some details about yourself and your business. This is a “Trade
              Only” platform so all Dealers must have a Garage Code to enter the platform. Once
              registered, you will be granted access to the platform. You cannot bid or buy a vehicle until
              you have registered. All payments are done by Electronic Fund Transfer (EFT) only, for
              security and speed.
            </Menu.Item>
          </SubMenu>
          <div className='underline'></div>
          <SubMenu key="sub3" title="How do I buy on MotoBids Online Auction?">
            <Menu.Item key="3">
              All our auctions operate as a timed auction, similar to Ebay, where you can place a bid on
              the vehicle. If you are the winning bidder when the auction ends, then you win the vehicle
              (once it has reached it’s Reserve Price set by the Seller). If the car has not reached it’s
              Reserve Price, it is noted as a Provisional Sale and we will then deal with you and the Seller
              to see if a compromise can be achieved. You can also use the proxy bidding function on
              MotoBids, where you enter your maximum bid for the vehicle and we will automatically bid
              on that vehicle for you, up to your maximum bid or as much of it as is needed, to win the
              Auction.
            </Menu.Item>
          </SubMenu>
          <div className='underline'></div>
          <SubMenu key="sub4" title="What happens once I buy?">
            <Menu.Item key="4">
              Once you buy a vehicle on MotoBids, you will be prompted to pay the Buyers Fee. Once this
              is paid, we will forward details of the Seller so that you can pay for the Car. We will
              automatically email you an invoice for our fee and the Seller will invoice you for the car.
            </Menu.Item>
          </SubMenu>
          <div className='underline'></div>
          <SubMenu key="sub5" title="How long are the auctions?">
            <Menu.Item key="5">
              Each car has a ten minute slot which can be extended by 30 seconds if a bid comes in during
              the last 60 seconds of the Auction. This ensures that a Buyer who is bidding has time to bid
              or pull out.
            </Menu.Item>
          </SubMenu>
          <div className='underline'></div>
          <SubMenu key="sub6" title="Is there any Guarantee with vehicles bought on MotoBids?">
            <Menu.Item key="6">
              No! All vehicles are “Sold As Shown” or sold “Trade”, whereby you are buying vehicles to
              recondition and resell. This means that all vehicles are sold with any faults that they have
              and no guarantee whatsoever applies. However if major faults have not been included in
              the ReCon Zone by the Seller, then our Arbitration process is available.
            </Menu.Item>
          </SubMenu>
          <div className='underline'></div>
          <SubMenu key="sub7" title="Can I change my mind after I have won the Auction?">
            <Menu.Item key="7">
              In short, No ! Once you have won the Auction, you have entered into a legal contract and
              payment by EFT must be made the same day. This speeds up collection for the Buyer and
              gives forecourt space to the Seller.
            </Menu.Item>
          </SubMenu>
          <div className='underline'></div>
          <SubMenu key="sub8" title="Who are the sellers?">
            <Menu.Item key="8">
              Every Seller must be a legitimate Motor Trade seller. We insist on Garage Codes for all
              Sellers and Buyers to ensure that we all are dealing with trade professionals. Public are not
              allowed onto the platform and neither are legitimate traders who do not have a Garage
              Code. This ensures a level playing field and also makes change of ownership a smoother
              process. Each car undergoes a Motorcheck History check to ensure, as best as possible, that
              each cars pedigree is known. We operate a strict policy on information uploaded, to
              minimise any complaints for all. If a Dealer is not declaring faults in the ReCon Zone, they
              will be either temporarily or permanently denied access to our platform.
            </Menu.Item>
          </SubMenu>
          <div className='underline'></div>
          <SubMenu key="sub9" title="How do I sell on MotoBids?">
            <Menu.Item key="9">
              Simply load any cars that you have for sale to the trade, in minutes and they will
              automatically appear for viewing on MotoBids.ie next available auction. Set your Reserve
              Price and your car will not be sold below this price. If the highest bidder is below your
              Reserve Price, we class it as a Provisional Sale and will contact you to discuss and mediate
              between you and the Buyer to close the deal.
            </Menu.Item>
          </SubMenu>
          <div className='underline'></div>
          <SubMenu key="sub10" title="How do I pay for a vehicle?">
            <Menu.Item key="10">
              All cars are paid directly to the Seller by Electronic Fund Transfer (EFT). We will send you the
              Sellers details once you have successfully bid on a car. This speeds up the time to allow you
              to collect your car and removes any delay or risks in a third party handling your monies.
              Once cleared funds are received in the Sellers bank account, the Seller will liaise with you
              regarding collection. This can be done however suits you, either in person or by a third party
              on your behalf.
            </Menu.Item>
          </SubMenu>
          <div className='underline'></div>
          <SubMenu key="sub11" title="What happens if the vehicle is not as described when it is delivered?">
            <Menu.Item key="11">
              MotoBids has an arbitration process which is a free service in the event of any concerns
              regarding a vehicle bought through the platform. Simply contact us by email with the
              relevant information as set out in the Arbitration section.
            </Menu.Item>
          </SubMenu>
          <div className='underline'></div>
        </Menu>
      </div>
    </>
  )
}

export default Faq