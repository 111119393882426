import React from 'react'
import Faq from '@components/FAQ'
import Header from '@components/Header'
import Footer from '@components/Footer'
import ContentWrapper from '@components/Markup/ContentWrapper'

const QuestionsOnMainPage = () => {
  return (
    <ContentWrapper>
      <Header />
      <Faq/>
      <ContentWrapper.Sticky>
        <Footer />
      </ContentWrapper.Sticky>
    </ContentWrapper>
  )
}

export default QuestionsOnMainPage