import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { authActions, carsActions } from '@actions'

import { NavLink, Link } from 'react-router-dom'
import { Divider, Avatar, Menu, Dropdown, Button, Input, Drawer } from 'antd'
import { DownOutlined } from '@ant-design/icons';
import { ReactComponent as SearchIcon } from '../../img/icons/i-search.svg'
import { config } from '@config'

import { ROUTES } from '@router/routes';
import { useHistory } from 'react-router'
import logo from '@icons/logo-motobids.svg'
import _ from 'lodash';

const DesktopNav = () => {
  const { user } = useSelector(state => state.authReducer)
  const { carsMeta } = useSelector(state => state.carsReducer)
  const [search, setSearch] = useState(carsMeta.search || '')
  const history = useHistory()
  const dispatch = useDispatch()

  const logOut = () => {
    dispatch(authActions.logout())
  }

  const addSecondaryFilterDebounced = _.debounce((key, value) => {
      if(value?.length) {
        const tempMeta = { ...carsMeta }  
        tempMeta[key] = value
        dispatch(carsActions.getCars(tempMeta));
        console.log(history.location)
      } else {
        dispatch(carsActions.getCars({ ...carsMeta, search: null }));
      }
    },
    500,
  );

  return (
     <nav className="header__nav">
      <div className="header__routes">
        <Link to={user ? ROUTES?.HOME : "/"}><img src={logo} alt="motobids logo" /></Link>
        <ul className="header__menu-list">
          {/* { user ? <li className="header__menu-item"><NavLink to={ROUTES?.HOME}>Home</NavLink></li> : 
          <li className="header__menu-item"><NavLink exact to={ROUTES?.LANDING}>Home</NavLink></li>  } */}
          {!user && <li className="header__menu-item"><NavLink exact to={ROUTES?.LANDING}>Home</NavLink></li>}
          {user && <li className="header__menu-item"><NavLink exact to={ROUTES?.HOME}>My Cars</NavLink></li>}
          <li className="header__menu-item"><NavLink to={ROUTES?.MARKETPLACE}>Find Cars</NavLink></li>
          { user && <li className="header__menu-item"><NavLink to={ROUTES?.AUCTIONS}>Auctions</NavLink></li> }
          <li className="header__menu-item"><NavLink to={ROUTES?.HOW_IT_WORKS}>How It Works</NavLink></li>
          <li className="header__menu-item"><NavLink to={ROUTES?.FAQ}>FAQ</NavLink></li>
          <li className="header__menu-item"><NavLink to={ROUTES?.CONTACT}>Contact Us</NavLink></li>
        </ul>
      </div>
      <div className="header__actions">
        { history.location.pathname !== '/marketplace' ?
          <SearchIcon style={{ cursor: 'pointer' }} onClick={() =>  history.push('/marketplace')} />
          : <Input.Search value={search} onChange={e => {
              setSearch(e.target.value)
              addSecondaryFilterDebounced('search', e.target.value)
            } 
          }  style={{ width: '50%' }} placeholder="Search car" />
        }
        <Divider size={100} style={{ height: '60%',  margin: '0 16px', borderLeft: "2px solid rgba(0, 129, 112, 0.1)" }} type="vertical" />
        { user ?
        <>
        { user?.avatar_thumb_url ? <Avatar size={40} src={config.REACT_APP_IMAGE_URL + user?.avatar_thumb_url} /> : <Avatar size={40}>{user?.business_name?.[0].toUpperCase()}</Avatar> }

        <Dropdown overlay={
          <Menu style={{ padding: '16px' }}>
            <Menu.Item>
              <Link className="header-menu__item" to={ROUTES?.HOME}>
                My Cars
              </Link>
            </Menu.Item>
            <Menu.Item>
              <Link className="header-menu__item" to={ROUTES?.HOME} >
                My Tracked Cars
              </Link>
            </Menu.Item>
            <Menu.Item>
              <Link className="header-menu__item" to={ROUTES?.HOME}>
                My Purchases
              </Link>
            </Menu.Item>
              <Divider style={{ margin: "12px 0" }}  />
            <Menu.Item>
              <Link to={ROUTES?.NOTIFICATIONS} className="header-menu__item" >
                Notifications
              </Link>
            </Menu.Item>
            <Divider style={{ margin: "12px 0" }}  />
            <Link to="/sell"><Button type="primary" style={{ width: "100%", fontWeight: "500"}}>SELL A CAR</Button></Link>
            <Divider style={{ margin: "12px 0" }}  />
            <h4 onClick={logOut} style={{ cursor: 'pointer', fontWeight: "600", color: '#008170', fontSize: '14px' }}>LOG OUT</h4>
          </Menu>
        } trigger={['click']}>
          <span className="avatar__nick" onClick={e => e.preventDefault()}>
            {user.business_name} <DownOutlined />
          </span>
        </Dropdown>
        </> : <NavLink to={ROUTES.LOGIN}><Button size="large" type='primary'>Sign in</Button></NavLink>
        }
      </div>
    </nav>
  )
}

export default DesktopNav
