import { takeEvery, put, call, select } from 'redux-saga/effects';
import { MESSAGING_ACTIONS } from '../constants';
import { authActions, carsActions, messagingActions } from '@actions';
import { processRequest } from '@services/Api';
import { getUserSelector, cableSelector, getSelectedTabSelector } from '@selectors';
import { filterBlackList } from '@helpers';
import { sendMessage } from '@actions/messagingActions';
// import { appHistory } from '../../services/HistoryConfig'
import { ROUTES } from '@router/routes';
import { push } from 'connected-react-router'
import { toggleNotification } from '../actions/global';

import {MY_CARS_TABS} from '../constants';

function* handleCreateSub(action) {
  try {
    const { payload } = action
    const { sale_id, dispatch } = payload
    const user = yield select(getUserSelector)
    const cable = yield select(cableSelector)

    function* gen(data) {
      yield put();
    }

    

    const func = function (data) {
      // console.log(data)
      dispatch(messagingActions.receivedMessage(data))
    }

    // console.log(func({a: 222}).next())

    const userChannelConnected = () => console.log('connected')
    const userChannelDisconnected = () => console.log('disconnected')
    const userChannelReceived = (data) => func(data)
    
    if (cable && user) {
      const cb = cable.subscriptions.create({ channel: "SaleChannel", sale_id: sale_id }, {
        connected: userChannelConnected,
        disconnected: userChannelDisconnected,
        received: userChannelReceived,
      });
      
      if(cb) yield put(messagingActions.createSubSuccess(cb, sale_id))
    }
  } catch(e) {
    console.log(e)
    yield put(messagingActions.createSubError(e))
  }
}

function* handleDeleteSub(action) {
  const { payload } = action
  const { sale_id, channel } = payload

  yield channel?.unsubscribe()
  yield put(messagingActions.deleteSubSuccess(sale_id))
}

function* handleRecivedMessage(action) {
  const { payload } = action
  const { message } = payload
  const selectedTab = yield select(getSelectedTabSelector);
  const {
    followingCarsMeta,
    myCarsMeta,
    purchasedCarsMeta,
    archivedCarsMeta,
  } = yield select(state => state.carsReducer);
  console.log('RECEIVED MESSAGE: ', message)
  if(message?.win_bid) {
    yield put(carsActions.winBid(message.win_bid));

    switch (selectedTab) {
      case MY_CARS_TABS.tracked:
        yield put(carsActions.getFollowingCars(followingCarsMeta, true));
        break;
      case MY_CARS_TABS.cars:
        yield put(carsActions.getMyCars(myCarsMeta, true));
        break;
      case MY_CARS_TABS.purchases:
        yield put(carsActions.getPurchasedCars(purchasedCarsMeta, true));
        break;
      case MY_CARS_TABS.archive:
        yield put(carsActions.getArchivedCars(archivedCarsMeta, true));
        break;
      default:
        break;
    };
  } else if (message?.bid)  {
    yield put(carsActions.updateBid(message?.bid?.data?.attributes))
  } else if (message?.errors)  {
    console.log(message.errors)
    const userId = yield select(state => state.authReducer.user.id)
    if(message.user_id === userId) {
      yield put(toggleNotification({
        title: 'Attention!',
        message: message.errors.amount ? message.errors.amount[0] : message.errors.base ? message.errors.base[0] : message.errors.bidder ? message.errors.bidder[0] : 'Unrecognized error!',
        type: 'danger'
      }))

      switch (selectedTab) {
        case MY_CARS_TABS.tracked:
          yield put(carsActions.getFollowingCars(followingCarsMeta, true));
          break;
        case MY_CARS_TABS.cars:
          yield put(carsActions.getMyCars(myCarsMeta, true));
          break;
        case MY_CARS_TABS.purchases:
          yield put(carsActions.getPurchasedCars(purchasedCarsMeta, true));
          break;
        case MY_CARS_TABS.archive:
          yield put(carsActions.getArchivedCars(archivedCarsMeta, true));
          break;
        default:
          break;
      };
    } else {
      switch (selectedTab) {
        case MY_CARS_TABS.tracked:
          yield put(carsActions.getFollowingCars(followingCarsMeta, true));
          break;
        case MY_CARS_TABS.cars:
          yield put(carsActions.getMyCars(myCarsMeta, true));
          break;
        case MY_CARS_TABS.purchases:
          yield put(carsActions.getPurchasedCars(purchasedCarsMeta, true));
          break;
        case MY_CARS_TABS.archive:
          yield put(carsActions.getArchivedCars(archivedCarsMeta, true));
          break;
        default:
          break;
      };
    }
  } else {
    console.log('Unrecognized message')
  }
}

function* handleGetChats(action) {
  try {
    const {
      payload: {meta},
    } = action || {};
    const {page, perPage, search} = meta || {};
    const getChatsSuccess =
      page === 1
        ? messagingActions.getChatsSuccess
        : messagingActions.getChatsNextPageSuccess;
    const {data} = yield call(
      processRequest,
      `/chats?page=${page}&per_page=${perPage || 10}&query=${
        search || ''
      }`,
      'GET',
    );

    if (data.chats) {
      const user = yield select(getUserSelector);
      const chats = filterBlackList({
        chats: data.chats,
        blackList: user.i_block_user_ids || [],
        blockedMe: user.me_block_user_ids || [],
      });
      // const chats = data.chats.filter((chat) => chat.last_message);
      yield put(getChatsSuccess(chats, data.total_pages));
      if (data.chats.length === 10 && chats.length < 10)
        yield put(messagingActions.getChats({page: page + 1}));
    } else {
      yield put(messagingActions.getChatsError(data));
    }
  } catch (e) {
    console.log(e);
    const {data, status, statusText} = e || {};
    const {error_messages, error, error_message} = data || {};

    if (status === 400) {
      let message = '';
      if (error_message) message = error_message;
      else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];

        message = error_messages && `${keys[0]} ${errorMessage}`;
      }

      yield put(messagingActions.getChatsError(message));
    } else if (status === 401) {
      yield put(authActions.forceLogout());
    } else if (status === 500) {
      yield put(messagingActions.getChatsError(statusText));
    } else if (e.message) {
      yield put(messagingActions.getChatsError(e.message));
    } else {
      yield put(messagingActions.getChatsError('Internal server error.'));
    }
  }
}

function* handleGetMessages(action) {
  try {
    const {
      payload: {messagesMeta},
    } = action || {};
    const {chat_id, date} = messagesMeta || {};
    const {data} = yield call(
      processRequest,
      `/messages/?chat_id=${chat_id}${date?`&date=${date}`:''}`,
      'GET',
    );

    if (data.messages) {
      yield put(messagingActions.getMessagesSuccess(data.messages));
    } else {
      yield put(messagingActions.getMessagesError(data));
    }
  } catch (e) {
    console.log(e);

    const {data, status, statusText} = e || {};
    const {error_messages, error, error_message} = data || {};

    if (status === 400) {
      let message = '';
      if (error_message) message = error_message;
      else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];

        message = error_messages && `${keys[0]} ${errorMessage}`;
      }

      yield put(messagingActions.getMessagesError(message));
    } else if (status === 401) {
      yield put(authActions.forceLogout());
    } else if (status === 500) {
      yield put(messagingActions.getMessagesError(statusText));
    } else if (e.message) {
      yield put(messagingActions.getMessagesError(e.message));
    } else {
      yield put(messagingActions.getMessagesError('Internal server error.'));
    }
  }
}

function* handleSendMessageFromModal(action) {
  try {
    const {
      payload: {user_id, message},
    } = action || {};
    const requestPayload = {user_id};
    const {data} = yield call(
      processRequest,
      `/chats/create`,
      'POST',
      requestPayload,
    );
    if (data.chat) {
      const {chat, messages} = data;

      yield put(messagingActions.createChatSuccess(chat, messages || []));
      const newMessageData = {chat_id: chat.id, body: message};
      yield put(sendMessage(newMessageData));
      yield put(messagingActions.createChat(user_id));
      yield put(messagingActions.toggleMessagePopup());
      // yield put(push(ROUTES.MESSAGES));
      // yield put(appHistory.push(ROUTES.CHATS))
    } else {
      yield put(messagingActions.createChatError(data));
    }
  } catch (e) {
    const {data, status, statusText} = e || {};
    const {error_messages, error, error_message} = data || {};

    if (status === 400) {
      let message = '';
      if (error_message) message = error_message;
      else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];

        message = error_messages && `${keys[0]} ${errorMessage}`;
      }

      yield put(messagingActions.createChatError(message));
    } else if (status === 401) {
      yield put(authActions.forceLogout());
    } else if (status === 500) {
      yield put(messagingActions.createChatError(statusText));
    } else if (e.message) {
      yield put(messagingActions.createChatError(e.message));
    } else {
      yield put(messagingActions.createChatError('Internal server error.'));
    }
  }
}

function* handleCreateChat(action) {
  try {
    const {
      payload: {user_id},
    } = action || {};
    const currentUser = yield select(getUserSelector);
   
    if (
      currentUser.me_block_user_ids &&
      currentUser.me_block_user_ids.indexOf(currentUser.id) !== -1
    ) {
      return;
    } else {
      const requestPayload = {user_id};
      const {data} = yield call(
        processRequest,
        `/chats/create`,
        'POST',
        requestPayload,
      );

      if (data.chat) {
        const {chat, messages} = data;

        yield put(messagingActions.createChatSuccess(chat, messages || []));
        // yield call(RootNavActions.back);
        } else {
          yield put(messagingActions.createChatError(data));
        }
    }
  } catch (e) {

    const {data, status, statusText} = e || {};
    const {error_messages, error, error_message} = data || {};

    if (status === 400) {
      let message = '';
      if (error_message) message = error_message;
      else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];

        message = error_messages && `${keys[0]} ${errorMessage}`;
      }

      yield put(messagingActions.createChatError(message));
    } else if (status === 401) {
      yield put(authActions.forceLogout());
    } else if (status === 500) {
      yield put(messagingActions.createChatError(statusText));
    } else if (e.message) {
      yield put(messagingActions.createChatError(e.message));
    } else {
      yield put(messagingActions.createChatError('Internal server error.'));
    }
  }
}

function* handleSendMessage(action) {
  try {
    const {
      payload: {message},
    } = action || {};
    const {chat_id, body, picture} = message || {};
    const formData = new FormData();

    formData.append('chat_id', chat_id);

    if (body) formData.append('body', body);

    if (picture && picture.url)
      formData.append('picture', {
        uri: picture.url,
        name: picture.url,
        type: picture.type,
        filename: picture.fileName,
      });

    const {data} = yield call(
      processRequest,
      `/chats/send_message`,
      'POST',
      formData,
      false,
    );

    if (data.message) {
      yield put(messagingActions.sendMessageSuccess(data.message));
    } else {
      yield put(messagingActions.sendMessageError(data));
    }
  } catch (e) {
    console.log(e);

    const {data, status, statusText} = e || {};
    const {error_messages, error, error_message} = data || {};

    if (status === 400) {
      let message = '';
      if (error_message) message = error_message;
      else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];

        message = error_messages && `${keys[0]} ${errorMessage}`;
      }

      yield put(messagingActions.sendMessageError(message));
    } else if (status === 401) {
      yield put(authActions.forceLogout());
    } else if (status === 500) {
      yield put(messagingActions.sendMessageError(statusText));
    } else if (e.message) {
      yield put(messagingActions.sendMessageError(e.message));
    } else {
      yield put(messagingActions.sendMessageError('Internal server error.'));
    }
  }
}

function* handleDeleteMessage(action) {
  try {
    const {
      payload: {message},
    } = action || {};
    const {id} = message || {};
    const {data} = yield call(
      processRequest,
      `/chats/delete_message?message_id=${id}`,
      'DELETE'
    );

    if (data.data.mesage) {
      yield put(messagingActions.deleteMessageSuccess({id}));
    } else {
      yield put(messagingActions.deleteMessageError(data));
    }
  } catch (e) {

    const {data, status, statusText} = e || {};
    const {error_messages, error, error_message} = data || {};

    if (status === 400) {
      let message = '';
      if (error_message) message = error_message;
      else if (error_messages) {
        const keys = Object.keys(error_messages);
        const errorMessage = error_messages[keys[0]];

        message = error_messages && `${keys[0]} ${errorMessage}`;
      }

      yield put(messagingActions.deleteMessageError(message));
    } else if (status === 401) {
      yield put(authActions.forceLogout());
    } else if (status === 500) {
      yield put(messagingActions.deleteMessageError(statusText));
    } else if (e.message) {
      yield put(messagingActions.deleteMessageError(e.message));
    } else {
      yield put(messagingActions.deleteMessageError('Internal server error.'));
    }
  }
}

export function* watchMessagingSagas() {
  yield takeEvery(MESSAGING_ACTIONS.CREATE_SUB, handleCreateSub);
  yield takeEvery(MESSAGING_ACTIONS.DELETE_SUB, handleDeleteSub);
  yield takeEvery(MESSAGING_ACTIONS.RECEIVED_MESSAGE, handleRecivedMessage);
  yield takeEvery(MESSAGING_ACTIONS.GET_MESSAGES, handleGetMessages);
  yield takeEvery(MESSAGING_ACTIONS.GET_CHATS, handleGetChats);
  yield takeEvery(MESSAGING_ACTIONS.CREATE_CHAT, handleCreateChat);
  yield takeEvery(MESSAGING_ACTIONS.SEND_MESSAGE, handleSendMessage);
  yield takeEvery(MESSAGING_ACTIONS.DELETE_MESSAGE, handleDeleteMessage);
  yield takeEvery(MESSAGING_ACTIONS.SEND_MESSAGE_FROM_MODAL, handleSendMessageFromModal);
}
