import React from 'react'
import { useSelector } from 'react-redux';
import Header from '@components/Header'
import Footer from '@components/Footer'
import ContentWrapper from '@components/Markup/ContentWrapper'

const ConfirmEmailPage = () => {
  const {user} = useSelector(({authReducer}) => authReducer);

  return (
    <ContentWrapper contentClass="register">
      <Header />
      <div className="sell">
        <h1>Please confirm your email</h1>
        {user?.email ? (
          <p style={{ color: '#000', fontSize: '18px', fontWeight: '500', textAlign: 'center' }}>
            An email was sent to <span>{user.email}</span>.<br/> Check your email for a link to
            confirm your account.
            <br/>
            If you don’t receive an email, please check your spam folder.
          </p>
        ) : (
          <p style={{ color: '#000', fontSize: '18px', fontWeight: '500', textAlign: 'center' }}>Check your email for a link to confirm your account.</p>
        )}
      </div>
      <ContentWrapper.Sticky>
        <Footer />
      </ContentWrapper.Sticky>
    </ContentWrapper>
  )
}

export default ConfirmEmailPage;
