import React from 'react'
import './Register.scss'
import { Steps } from 'antd';
import { useWindowSize } from '@hooks/useWindowSize'
import { useSelector } from 'react-redux';

import ProfileDetails from './ProfileDetails'
import DealerDetails from './DealerDetails'
import AddBuyers from './AddBuyers'

const Register = () => {
  const { step } = useSelector(state => state.authReducer)
  const steps = [<ProfileDetails />, <DealerDetails />, <AddBuyers />]
  const size = useWindowSize() 

  return (
    <div className="sell">
      { size.width > 1100 ?  <Steps current={step} className="styled-steps">
        <Steps.Step title="Profile details" />
        <Steps.Step title="Dealer details" />
        <Steps.Step title="Add buyers" />
      </Steps> :
      <Steps current={step} className="styled-steps">
        <Steps.Step />
        <Steps.Step />
        <Steps.Step />
      </Steps> }
      {steps[step]}
    </div>
  )
}

export default Register
