import { takeEvery, select, put, call } from 'redux-saga/effects';
import { CARS_ACTIONS } from '@constants';
import { carsActions, globalActions } from '../actions';
import { processRequest } from '@services/Api';
import { push } from 'connected-react-router';
import { ROUTES } from '@router/routes';
import { getUrl } from '@helpers';
import { saveAs } from 'file-saver';

function* handlePreBid(action) {
  try {
    const { payload } = action;
    const { amount, sale_id } = payload;
    const { data } = yield  call(processRequest, `/bids`, 'POST', { amount: amount, sale_id: sale_id });
    if(data) {
      console.log(data);
      const { id } = yield select(state => state.carsReducer.car);
      yield put(carsActions.getCarInfo(id));
      yield put(carsActions.preBidSuccess(data));
      
    }
  } catch(e) {
    console.log(e);
  }
}

function* handleGetAuctions(action) {
  try {
    const { payload } = action;
    const { meta, replace } = payload;
    const { page, perPage } = meta || {};
    const query = `/sales?page=${page}&per_page=${perPage}`
    const { data } = yield call(processRequest, query, 'GET');
    if(data) {
      console.log(data)
      yield put(carsActions.getAuctionsSuccess(data?.sales?.data?.map(sale => sale.attributes), data?.total_pages, data?.total_sales, replace))
    }
  } catch (e) {
    yield put(carsActions.getAuctionsError(e))
    console.log(e)
  }
}

function* handleGetCars(action) {
  try {
    const { payload } = action;
    const { meta } = payload;
    const { 
      page,
      perPage, 
      cars, 
      price_from, 
      price_to, 
      year_from, 
      year_to, 
      odometer_from,
      odometer_to,
      fuel_type,
      body_type,
      engine,
      transmition_type,
      color,
      search
    } = meta || {};

    const secondary_filters = {
      price_from, 
      price_to, 
      year_from, 
      year_to, 
      odometer_from,
      odometer_to,
      fuel_type,
      transmition_type,
      body_type,
      engine,
      color,
      search
    }

    let query = `/products?page=${page}&per_page=${perPage}`

    cars.forEach((car, i) => {
      const keys = Object.keys(car)
      keys.forEach(key => {
        query = query + `&car[${i}]${key}=${car[key]}`
      })
    });

    const secondaryKeys = Object.keys(secondary_filters)

    secondaryKeys.forEach(key => {
      if(secondary_filters[key]) {
        query = query + `&${key}=${secondary_filters[key]}`
      }
    })
    
    const { data } = yield call(processRequest, query, 'GET');

    if(data) {
      console.log(data)
      yield put(carsActions.getCarsSuccess(data?.products?.data?.map(prod => prod.attributes), data?.total_pages, data?.total_products))
    }

  } catch (e) {
    yield put(carsActions.getCarsError(e))
    console.log(e)
  }
}

function* handleGetMyCars(action) {
  try {
    const { payload } = action;
    const { meta, replace } = payload;
    const { page, perPage, archived } = meta || {};
    const query = `/products/by_user?page=${page}&per_page=${perPage}&archived=${archived}`
    const { data } = yield call(processRequest, query, 'GET');
    if (data && data.products) { 
      console.log(data)
      yield put(carsActions.getMyCarsSuccess(data.products.data, data.total_pages, replace))
    }
  } catch (e) {
    // todo better error handlers
    console.log(e)
  }
}

function* handleGetRelatedCars(action) {
  try {
    const { payload } = action;
    const { meta, id, replace } = payload;
    const { page, perPage } = meta || {};
    const query = `/related/${id}?page=${page}&per_page=${perPage}`
    const { data } = yield call(processRequest, query, 'GET');
    if (data && data.products) { 
      console.log(data)
      yield put(carsActions.getRelatedCarsSuccess(data.products.data.map(car => car?.attributes), data.total_pages, replace))
    }
  } catch (e) {
    // todo better error handlers
    console.log(e)
  }
}

function* handleCreateCar(action) {
  try {
    const {payload} = action;
    const {car} = payload;
    const formData = new FormData();
    const keys = Object.keys(car)

    keys.forEach(key => {
      if(key !== 'nct_year' &&
         key !== 'nct_month' &&
         car[key] !== null
        ) {
        formData.append(`product[${key}]`, car[key])
      }
    })
    const {data} = yield call(
      processRequest,
      `/products/`,
      'POST',
      formData,
      false,
    );

    if(data && data.product) {
      console.log(data)
      yield put(carsActions.createCarSuccess(data.product.data))
      yield put(carsActions.addStep())
    }
  } catch (e) {
    console.log(e)
  }
}

function* handleGetFollowingCars (action) {
  try {
    const { payload } = action
    const { meta, replace } = payload
    const { page, perPage, archived } = meta || {};
    // const { data } = yield call(processRequest, `/followings?page=${page}&per_page=${perPage}&archived=${archived}`)
    const { data } = yield call(processRequest, `/followings?archived=${archived}`)

    if (data) { 
      console.log(data)
      yield put(carsActions.getFollowingCarsSuccess(data.products.data, data.total_pages, replace))
    }
  } catch (e) {
    console.log(e)
  }
}

function* handleGetPurchasedCars (action) {
  try {
    const { payload } = action
    const { meta, replace } = payload
    const { page, perPage, archived } = meta || {};
    const { data } = yield call(processRequest, `/win_bids?page=${page}&per_page=${perPage}`)

    if (data) { 
      console.log(data)
      yield put(carsActions.getPurchasedCarsSuccess(data.products.data, data.total_pages, replace))
    }
  } catch (e) {
    console.log(e)
  }
}

function* handleGetArchivedCars (action) {
  try {
    const { payload } = action
    const { meta, replace } = payload
    const { page, perPage, archived } = meta || {};
    const { data } = yield call(processRequest, `/products/by_user?page=${page}&per_page=${perPage}&archived=${archived}`)

    if (data) { 
      console.log(data)
      yield put(carsActions.getArchivedCarsSuccess(data.products.data, data.total_pages, replace))
    }
  } catch (e) {
    console.log(e)
  }
}

function* handleGetCarInfo(action) {
  try {
    const { payload } = action
    const { id, isPurchased } = payload;
    const params = isPurchased ? `?for_purchase=true` : '';
    const { data } = yield call(processRequest, `/products/${id}${params}`, 'GET')
    // yield put(globalActions.setLoading(true))

    if(data && data.product) {
      console.log(data.product.data)
      yield put(carsActions.getCarInfoSuccess(data.product.data.attributes))
      // yield put(globalActions.setLoading(false))
    }
  } catch (e) {
    console.log(e)
    yield put(carsActions.getCarInfoError(e))
    // yield put(globalActions.setLoading(false))
  }
}

function* handleAddCarServiceHistory(action) {
  try {
    const {payload} = action;
    const {history} = payload;
    const formData = new FormData();
    const keys = Object.keys(history)
    
    keys.forEach(key => {
      if(key !== 'history_year' &&
        key !== 'history_month' &&
        history[key] !== null
        ) {
          formData.append(`service_history[${key}]`, history[key])
      }
    })

    const {data} = yield call(
      processRequest,
      `/service_histories/`,
      'POST',
      formData,
      false,
    );

    if(data) {
      console.log(data)
      yield put(carsActions.addStep())
      yield put(carsActions.addCarSerivceHistorySuccess())
    }
  } catch(e) {
    console.log(e)

  }
}

function* handleAddCarPhoto(action) {
  const {payload} = action
  const {photo, onSuccess, onError} = payload
  try {
    const formData = new FormData()
    const keys = Object.keys(photo)

    keys.forEach(key => {
      if(history[key] !== null) {
          formData.append(`attachment[${key}]`, photo[key])
      }
    })

    const {data} = yield call(
      processRequest,
      `/attachments/`,
      'POST',
      formData,
      false,
    );

    if(data.attachment) {
      const attachments = [data?.attachment?.data.attributes]
      // console.log(data)
      onSuccess("Uploaded")
      yield put(carsActions.getAttachmentsSuccess(attachments))
      yield put(carsActions.addCarPhotoSuccess())
    }

  } catch (e) {
      console.log(e)
      onError("Something went wrong")
  }
}

function* handleGetAttachments(action) {
  try {
    const {payload} = action;
    const {id, replace} = payload;
    const {data} = yield call(processRequest, `/attachments?product_id=${id}&page =1&per_page=20`, 'GET');

    if(data?.attachments?.data) {
      const attachments = data.attachments.data.map(item => item.attributes)
      if(replace) {
        yield put(carsActions.getAttachmentsSuccess(attachments, replace))
      } else {
        yield put(carsActions.getAttachmentsSuccess(attachments))
      }
    }
    
  } catch (e) {
    console.log(e)
    yield put(carsActions.getAttachmentsError(e))
  }
}

function* handleRemoveAttachments(action) {
  try {
    const {payload} = action;
    const {id, product_id} = payload;
    const {data} = yield call(processRequest, `/attachments/${id}`, 'DELETE');  

    if(data?.message === 'Success') {
      console.log(product_id)
      yield put(carsActions.removeAttachmentsSuccess(id))
      yield put(carsActions.getAttachments(product_id, true))
    }
  } catch (e) {
    console.log(e)
    yield put(carsActions.addCarPhotoError(e))
  }
}

function* handleCreateZone(action) {
  try {
    const {payload} = action;
    const {zone} = payload;
    const {data} = yield call(processRequest, `/recon_zones`, 'POST', zone);

    if(data) {
      console.log(data)
      yield put(carsActions.createZoneSuccess(data))
      yield put(carsActions.releaseCar(zone.recon_zone.product_id))
    }
  } catch(e) {
    console.log(e)
    put(carsActions.createZoneError(e))
  }
}

function* handleGetHistoryTypes(action) {
  try {
    const {data} = yield call(processRequest, `/service_histories/types`, 'GET');
    if(data && data.service_histories_types) {
      console.log(data)
      yield put(carsActions.getHistoryTypesSuccess(data.service_histories_types))
    }
  } catch (e) {pes
    console.log(e)
  }
}

function* handleGetClutch(action) {
  try {
    const {data} = yield call(processRequest, `/recon_zones/clutch`, 'GET');

    if(data && data.clutch_types) {
      yield put(carsActions.getClutchSuccess(data.clutch_types))
    }
  } catch (e) {
    console.log(e)
    yield put(carsActions.getClutchError(e))
  }
}

function* handleGetTyres(action) {
  try {
    const {data} = yield call(processRequest, `/recon_zones/tyres`, 'GET');
    if(data && data.tyres_types) {
      yield put(carsActions.getTyresSuccess(data.tyres_types))
    }
  } catch (e) {
    console.log(e)
  }
}

function* handleGetDamage(action) {
  try {
    const {data} = yield call(processRequest, '/recon_zones/damages')
    if(data && data.damages_types) {
      yield put(carsActions.getDamageSuccess(data.damages_types))
    }
  } catch (e) {
    console.log(e)
  }
}

function* handleGetBodyTypes(action) {
  try {
    const {data} = yield call(processRequest, '/products/body_types')
    if(data && data.body_types) {
      yield put(carsActions.getBodyTypesSuccess(data.body_types))
    }
  } catch (e) {
    console.log(e)
  }
}

function* handleGetTransmitionTypes(action) {
  try {
    const {data} = yield call(processRequest, '/products/transmition_types')
    if(data && data.transmition_types) {
      yield put(carsActions.getTransmitionTypesSuccess(data.transmition_types))
    }
  } catch (e) {
    console.log(e)
  }
}

function* handleGetFuelTypes(action) {
  try {
    const {data} = yield call(processRequest, '/products/fuel_types')
    if(data && data.fuel_types) {
      console.log(data.fuel_types)
      yield put(carsActions.getFuelTypesSuccess(data.fuel_types))
    }
  } catch (e) {
    console.log(e)
  }
}

function* handleGetColors(action) {
  try {
    const {data} = yield call(processRequest, '/products/color_list')
    if(data && data.color_list) {
      console.log(data.color_list)
      yield put(carsActions.getColorsSuccess(data.color_list))
    }
  } catch (e) {
    console.log(e)
  }
}


function* handleReleaseCar(action) {
  try {
    const { payload } = action
    const { id } = payload
    const formData = new FormData()
    formData.append('product[is_ready_to_sell]', true)
    formData.append('product[is_finished_creation]', true)
    const {data} = yield call(processRequest, `/products/${id}`, 'PUT', formData, false)

    if(data) {
      console.log(data)
      yield put(carsActions.setStep(0))
      yield put(carsActions.releaseCarSuccess())
      yield put(push(ROUTES.HOME))
    }
  } catch (e) {
    console.log(e)
  }
}

function* handleCreateBuyer(action) {
  try {
    const { payload } = action
    const { user } = payload
    const formData = new FormData()
    const keys = Object.keys(user)

    keys.forEach(key => {
      if(user[key] !== null) {
          formData.append(`user[${key}]`, user[key])
      }
    })

   const {data, status} = yield call(processRequest, `/users/create_buyer`, 'POST', formData, false)

   if(data.user) {
     console.log(data, {status})
     const buyer = data.user.data.attributes;
     yield put(carsActions.createBuyerSuccess(buyer))
     yield put(globalActions.setError(''))
   }
  } catch (e) {
    console.log(e)
    console.log(e?.response?.data?.error_messages?.[0])
    yield put(globalActions.setError(e?.response?.data?.error_messages?.[0]))
  }
}

function* handleGetBuyers(action) {
  try {
    const { payload } = action;
    const { meta } = payload;
    const { page, perPage } = meta || {};
    const query = `/users/buyers?page=${page}&per_page=${perPage}`
    const { data } = yield call(processRequest, query, 'GET');
    if(data) {
      yield put(carsActions.getBuyersSuccess(data))
      console.log(data)
    }
  } catch (e) {
    yield put(carsActions.getBuyersError(e))
    console.log(e)
  }
}

function* handleAcceptWin(action) {
  try {
    const { payload } = action;
    const { win_id } = payload;
    const { data } = yield call(processRequest, `/win_bids/accept?id=${win_id}`, 'POST', {});

    if(data) {
      console.log(data)
      yield put(carsActions.acceptWinSuccess(win_id))
    }
  } catch(e) {
    yield put(carsActions.acceptWinError(e))
    console.log(e)
  }
}

function* handleRejectWin(action) {
  try {
    const { payload } = action;
    const { win_id } = payload;
    const { data } = yield call(processRequest, `/win_bids/reject?id=${win_id}`, 'POST', {});

    if(data) {
      console.log(data)
      yield put(carsActions.rejectWinSuccess(win_id))
    }
  } catch(e) {
    yield put(carsActions.rejectWinError(e))
    console.log(e)
  }
}

function* handleGetInvoice(action) {
  try {
    const { payload } = action;
    const { win_bid_id, ref } = payload;
    const { data } = yield call(processRequest, `/invoice/download_pdf?win_bid_id=${win_bid_id}`, 'GET', {}, true, 'blob');

    if(data) {
      saveAs(new Blob([data], {type: 'application/pdf'}), 'MotoBids invoice.pdf')
    }

  } catch (e) {
    console.log(e)
  }
}

function* handleGetFeeInvoice(action) {
  try {
    const { payload } = action;
    const { win_bid_id, ref } = payload;
    const { data } = yield call(processRequest, `/invoice?win_bid_id=${win_bid_id}`, 'POST');

    if(data) {
      // window.open(`${process.env.REACT_APP_IMAGE_URL}${data.transaction.data.attributes.pdf_url}`, '_blank').focus()
      // const res = yield call(fetch, `${process.env.REACT_APP_IMAGE_URL}${data.transaction.data.attributes.pdf_url}`, {method: 'GET'})
      saveAs(`${process.env.REACT_APP_IMAGE_URL}${data.transaction.data.attributes.pdf_url}`, 'MotoBids invoice.pdf')
    }

  } catch (e) {
    console.log(e)
  }
}

function* handleUpdateCar (action) {
  try {
    const { payload } = action;
    const { values } = payload;
    const { data } = yield call(processRequest, `/win_bids/reject?id=${win_id}`, 'PUT', { product: values });

    if(data) {
      yield put(carsActions.updateCarSuccess());
    }

  } catch (e) {
    console.log(e);
  }
}

function* handleDeleteCar (action) {
  try {
    const { payload } = action;
    const { id } = payload;
    const { data } = yield call(processRequest, `/products/${id}`, 'DELETE');

    if(data) {
      yield put(carsActions.deleteCarSuccess(id));
    }

  } catch (e) {
    console.log(e);
  }
}

function* handleGetMotorCheckInfo(action) {
  yield put(globalActions.setLoading(true))

  try {
    const { payload } = action;
    const { product_id } = payload;
    const { data } = yield call(processRequest, `/motor_check`, 'POST', {product_id});

    if(data) {
      yield put(carsActions.getMotorCheckInfoSuccess(data));
      yield put(carsActions.toggleMotorCheckModal(true));
    }

    yield put(globalActions.setLoading(false))
  } catch (e) {
    yield put(globalActions.setLoading(false))

    const {response} = e;
    console.log(response)
    const {status, data} = response || {};
    const {error_message} = data || {};
    if (status === 400 && error_message)
      yield put(carsActions.getMotorCheckInfoError(error_message));
    else
      yield put(carsActions.getMotorCheckInfoError(response));
  }
}

export function* watchCarsSagas() {
  yield takeEvery(CARS_ACTIONS.PRE_BID, handlePreBid);
  yield takeEvery(CARS_ACTIONS.GET_AUCTIONS, handleGetAuctions);
  yield takeEvery(CARS_ACTIONS.GET_CARS, handleGetCars);
  yield takeEvery(CARS_ACTIONS.GET_MY_CARS, handleGetMyCars);
  yield takeEvery(CARS_ACTIONS.GET_FOLLOWING_CARS, handleGetFollowingCars);
  yield takeEvery(CARS_ACTIONS.GET_PURCHASED_CARS, handleGetPurchasedCars);
  yield takeEvery(CARS_ACTIONS.GET_ARCHIVED_CARS, handleGetArchivedCars);
  yield takeEvery(CARS_ACTIONS.GET_RELATED_CARS, handleGetRelatedCars);
  yield takeEvery(CARS_ACTIONS.CREATE_CAR, handleCreateCar);
  yield takeEvery(CARS_ACTIONS.GET_CAR_INFO, handleGetCarInfo);
  yield takeEvery(CARS_ACTIONS.ADD_CAR_PHOTO, handleAddCarPhoto);
  yield takeEvery(CARS_ACTIONS.ADD_CAR_SERVICE_HISTORY, handleAddCarServiceHistory);
  yield takeEvery(CARS_ACTIONS.GET_ATTACHMENTS, handleGetAttachments);
  yield takeEvery(CARS_ACTIONS.REMOVE_ATTACHMENTS, handleRemoveAttachments);
  yield takeEvery(CARS_ACTIONS.GET_CLUTCH, handleGetClutch);
  yield takeEvery(CARS_ACTIONS.GET_TYRES, handleGetTyres);
  yield takeEvery(CARS_ACTIONS.GET_DAMAGE, handleGetDamage);
  yield takeEvery(CARS_ACTIONS.GET_HISTORY_TYPES, handleGetHistoryTypes);
  yield takeEvery(CARS_ACTIONS.GET_BODY_TYPES, handleGetBodyTypes);
  yield takeEvery(CARS_ACTIONS.GET_FUEL_TYPES, handleGetFuelTypes);
  yield takeEvery(CARS_ACTIONS.GET_TRANSMITION_TYPES, handleGetTransmitionTypes);
  yield takeEvery(CARS_ACTIONS.GET_COLORS, handleGetColors);
  yield takeEvery(CARS_ACTIONS.CREATE_ZONE, handleCreateZone);
  yield takeEvery(CARS_ACTIONS.RELEASE_CAR, handleReleaseCar);
  yield takeEvery(CARS_ACTIONS.CREATE_BUYER, handleCreateBuyer);
  yield takeEvery(CARS_ACTIONS.GET_BUYERS, handleGetBuyers);
  yield takeEvery(CARS_ACTIONS.ACCEPT_WIN, handleAcceptWin);
  yield takeEvery(CARS_ACTIONS.REJECT_WIN, handleRejectWin);
  yield takeEvery(CARS_ACTIONS.GET_INVOICE, handleGetInvoice);
  yield takeEvery(CARS_ACTIONS.GET_FEE_INVOICE, handleGetFeeInvoice);
  yield takeEvery(CARS_ACTIONS.UPDATE_CAR, handleUpdateCar);
  yield takeEvery(CARS_ACTIONS.DELETE_CAR, handleDeleteCar);
  yield takeEvery(CARS_ACTIONS.GET_MOTOR_CHECK_INFO, handleGetMotorCheckInfo);
}