import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { carsActions } from '@actions'
import { Pagination } from 'antd'
import TabCard from './TabCard'
import { scrollToTheTop } from '@helpers'

import {MY_CARS_TABS} from '../../redux/constants';

const MyTab = () => {
  const { myCars, myCarsMeta } = useSelector(state => state.carsReducer)
  const {selectedTab} = useSelector(({myCarsTabsReducer}) => myCarsTabsReducer);
  const dispatch = useDispatch()

  useEffect(() => {
    if (MY_CARS_TABS.cars === selectedTab)
      dispatch(carsActions.getMyCars({ ...myCarsMeta }, true))
  }, [selectedTab])

  const handlePageChange = (page) => {
    dispatch(carsActions.getMyCars({ ...myCarsMeta, page: page }, true))
    scrollToTheTop()
  }

  return (
    <div className="tab-cards">
      {myCars.map(car => {
          const { attributes: attr } = car
          return <TabCard 
                    key={car.id}
                    car={attr}
                    my
                  />
        })}
        { (myCarsMeta.total_pages &&  myCarsMeta.total_pages > 1) && <Pagination style={{ alignSelf: 'flex-start' }} className="home__pagination" onChange={handlePageChange} current={myCarsMeta.page} defaultCurrent={1} total={myCarsMeta.total_pages} pageSize={1} /> }
    </div>
  )
}

export default MyTab
