import React from 'react'

const ReconZones = ({ reconZone }) => {
  return (
    <div className="car-characteristics__stats">
      <div className="desc-box">
        <h2>ReCon Zone </h2>
        <div className="desc-box__stat wide">
          <li><strong>Clutch:</strong> {reconZone?.clutch?.split('_').join(' ')}</li>
          {/* <li><strong>Damage:</strong>
            { reconZone?.grip_point === "ok" ? <span className="desc-box__ok">All okay</span> : <span className="desc-box__damage">There is damage</span> }
          </li> */}
        </div>
        <div className="desc-box__stat wide">
          {/* <li><strong>Smokey:</strong> { reconZone?.grip_point === "ok" ? <span className="desc-box__ok">All okay</span> : <span className="desc-box__damage">There is damage</span> }</li>
          <li><strong>Unusual Noises:</strong>
            { reconZone?.noises === "ok" ? <span className="desc-box__ok">All okay</span> : <span className="desc-box__damage">There is damage</span> }
          </li> */}
          <li><strong>Engine:</strong> { reconZone?.engine_condition }</li>
        </div>
        <div className="desc-box__stat wide">
          <li><strong>Other ReCon:</strong> { reconZone?.other }</li>
        </div>
      </div>
      <div className="desc-box">
        {/* <h2>ReCon Zone 2</h2> */}
        <ul className="desc-box__stats--column zone-2">
            <li className="desc-box__stat"><strong>Tyres:</strong> {reconZone?.tyres_mms}</li>
            <li className="desc-box__stat"><strong>Refurbs:</strong> {reconZone?.alloy_refurbs}</li>
        </ul>
        { reconZone?.dash_warnings_info && <div className="desc-box__stat wide">
        <li><strong>Dash Warnings:</strong> {reconZone?.dash_warnings_info}</li>
        </div>
        }
      </div>
      { reconZone?.summarise_damage_info &&
        <div className="desc-box">
          <div className="desc-box__stat wide">
            <li><strong>Options/Accessories: </strong>
            <p style={{ margin: 0, wordWrap: 'break-all' }}>{reconZone?.summarise_damage_info}</p></li>
          </div>
        </div>
      }
    </div>
  )
}

export default ReconZones
