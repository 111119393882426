export const filterBlackList = (params) => {
  const {
    blackList = [],
    blockedMe = [],
    posts = [],
    replies = [],
    users = [],
    chats = [],
    feed = [],
    skipComments = false,
  } = params;
  const theList = [...blockedMe, ...blackList].filter(
    (item, index, self) => self.indexOf(item) === index,
  );
  if (posts[0]) {
    const withoutUsers = posts.reduce((newArr, item) => {
      if (theList.indexOf(item.user.id) === -1 && item.attachments?.[0]?.id)
        return [
          ...newArr,
          {...item, replies: skipComments ? [] : item.replies},
        ];
      else return newArr;
    }, []);
    // const withoutUsers = posts.reduce(item: Post,
    //   (newArr: Post[]) => {theList.indexOf(item.user.id) === -1},
    // ) as Post[];
    const arr = [];
    if (!skipComments) {
      withoutUsers.forEach((item) => {
        if (item.replies) {
          const replies = filterBlackList({
            replies: item.replies,
            blackList,
            blockedMe,
          });
          arr.push({
            ...item,
            replies,
          });
        } else arr.push(item);
      });
    }
    return skipComments ? withoutUsers : arr;
  } else if (replies && replies[0]) {
    return replies.filter(
      (item) => theList.indexOf(item.user?.id || -1) === -1,
    );
  } else if (users[0]) {
    return users.filter((item) => theList.indexOf(item.id) === -1);
  } else if (chats?.[0]) {
    return chats.filter(
      (item) =>
        item.last_message && theList.indexOf(item.opponent.id) === -1,
    );
  } else if (feed?.[0]) {
    const newFeed = []
    feed.forEach((item) => {
      const toCheck = [];
      if (item.user) toCheck.push(item.user.id);
      if (
        item.feedable.likeable?.user &&
        item.feedable?.likeable.user?.id !== item.user.id
      )
        toCheck.push(item.feedable.likeable.user.id);
      // joining arrays of black lists and user ids, if there are any matches, l will be > 0
      const l = [...theList, ...toCheck]
        .sort((a, b) => a - b)
        .filter((el, index, self) => self[index - 1] === el).length;
      if (l === 0) newFeed.push(item);
    });
    return newFeed;
  } else return [];
};
