import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div className="terms">
      <h1>PRIVACY &amp; COOKIE POLICY</h1>
      <p><br/></p>
      <p>BEFORE SELLING, BIDDING OR BUYING ANY VEHICLE ON MOTOBIDS PLATFORM, IT IS IMPORTANT THAT YOU READ THESE TERMS AND CONDITIONS.</p>
      <p><strong>Introduction and General Terms</strong></p>
      <p>MotoBids.ie is a trading name of MotoBids Ltd, (Co. Reg&rsquo;d No.693896) Cirrus Suite, The Atrium, John&rsquo;s Lane, Naas, Co Kildare. We provide an Online Platform for professional Motor Traders to buy and sell vehicles.<br/>In order to comply with our responsibilities under the General Data Protection Regulations 2016, we have an obligation to ensure we are clear with how your data is handled while in our possession.<br/>This policy provides details on what information and data we collect from our clients in relation to our business activities, why we need it, what we do with it, what we won&rsquo;t do with it and your rights in relation to our processing of this information.</p>
      <p>As a data controller and data processor we&nbsp;are committed to protecting your personal information when you are using MotoBids.ie services. This Privacy and Cookies Policy relates to our use of any personal information we collect from you via the following online services:</p>
      <p>&bull;&nbsp;Company website that links to this Privacy and Cookies Policy;</p>
      <p>&bull; social media;</p>
      <p>It also relates to our use of any personal information you provide to us by online registration form, phone, SMS, email, in letters and other correspondence and in person.</p>
      <p>In order to provide you with our auction services, we sometimes need to collect information about you.</p>
      <p>This Privacy and Cookies Policy explains the following:</p>
      <p>&bull; what information&nbsp;we may collect about you;</p>
      <p>&bull; how&nbsp;we will use information we collect about you;</p>
      <p>&bull; when&nbsp;we may use your details to contact you;</p>
      <p>&bull; whether&nbsp;we will disclose your details to anyone else;</p>
      <p>&bull; your choices regarding the personal information you provide to us;</p>
      <p>&bull; the use of cookies on&nbsp;MotoBids.ie websites.</p>
      <p>We are committed to safeguarding your personal information. Whenever you provide such information, we are legally obliged to use your information in line with all applicable laws concerning the protection of personal information, including the Data Protection Act&nbsp;2018 (these laws are referred to collectively in this Privacy and Cookies Policy as the &quot;data protection laws&quot;) and EU General Data Protection Regulation (GDPR). No website can be completely secure; if you have any concerns that your&nbsp;MotoBids account could have been compromised e.g. someone could have discovered your password, please get in touch straight away at hello@motobids.ie.</p>
      <p>Our&nbsp;website contains hyperlinks to websites owned and operated by third parties. These third party websites have their own privacy policies, and are also likely to use cookies, and we therefore urge you to review them. They will govern the use of personal information you submit when visiting these websites, which may also be collected by cookies. We do not accept any responsibility or liability for the privacy practices of such third party websites and your use of such websites is at your own risk.</p>
      <p><strong>Cookies &amp; Privacy</strong></p>
      <p>Like many websites we use cookies to store and then retrieve information on your computer when you visit. This information is used to make the site work as you expect it to. It is not personally identifiable to you, but it can be used to give you a more personalised web experience.</p>
      <p>Some of the information stored is put there by other companies whose software we have added to the site, and this can also impact your experience of other websites you may visit after leaving ours.</p>
      <p>If you continue to use this site without taking action to prevent the storage of this information, you are effectively agreeing to this use.</p>
      <p>If you want to learn more about the general uses of cookies, including how to stop them being stored by your computer, please visit <a href="https://cookiepedia.co.uk/all-about-cookies">Cookiepedia - all about cookies</a> .</p>
      <p>Below is a list of the different types of cookies used on this site, and an explanation of what they are used for. If you would like any more information, please get in touch&nbsp;at hello@motobids.ie.</p>
      <ul>
          <li><strong>Strictly Necessary Cookies</strong></li>
      </ul>
      <p>These cookies are necessary for the website to function. They are usually only set in response to actions made by you which amount to a request for services, such as logging in or filling in forms.</p>
      <p>You can set your browser to block or alert you about these cookies, but some parts of the site will not function. These cookies do not store any personally identifiable information.</p>
      <ul>
          <li><strong>Performance Cookies</strong></li>
      </ul>
      <p>These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site.</p>
      <p>All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not know when you have visited our site, and will not be able to monitor its performance.</p>
      <ul>
          <li><strong>Functionality Cookies</strong></li>
      </ul>
      <p>These cookies enable the website to provide enhanced functionality and personalisation. They may be set by us or by third party providers whose services we have added to our pages.</p>
      <p>If you do not allow these cookies then some or all of these services may not function properly.</p>
      <p><strong>Other information collected from web browsers</strong></p>
      <p>Your web browser may also provide&nbsp;MotoBids.ie with information about your device, such as an IP address and details about the browser that you are using. We use information provided by your browser or by the link that you have clicked to understand the webpage that directed you to&nbsp;MotoBids.ie and this may be captured by performance cookies.</p>
      <p><br/></p>
      <p><strong>Apps&nbsp;&amp; Devices</strong>&nbsp;<br/>When you download or use MotoBids.ie on your mobile device, information may be accessed from or stored to your device. Most often this is used in a similar way to a web browser cookie, such as by enabling MotoBids.ie to &lsquo;remember&rsquo; you or provide you with the content you have requested.</p>
      <p>Your web browser or device may also provide&nbsp;us with information about your device, such as a device identifier or IP address. Device identifiers may be collected automatically, such as the device ID, IP address, MAC address, IMEI number and app ID (a unique identifier relating to the particular copy of the website you are running).</p>
      <p>When you sign in to MotoBids.ie your sign-in details may be stored securely on the device you are using, so you can access&nbsp;the app on the same device without needing to enter your sign-in details again.</p>
      <p><strong>What information will&nbsp;MotoBids.ie collect about me?</strong><br/>When you participate in our auctions, we may receive personal information about you. This can consist of information such as your name, email address, postal address, bank account details, telephone or mobile number.</p>
      <p>We&nbsp;collect information about how you use&nbsp;our websites and the device(s) you use to access the services. This includes collecting unique online identifiers such as IP addresses, which are numbers that can uniquely identify a specific computer or other network device on the internet.</p>
      <p><strong>How will&nbsp;MotoBids.ie use the information it collects about me?</strong><br/>We will use your personal information for a number of purposes including the following:</p>
      <p>&bull; to provide our auction services, activities or online content, to provide you with information about them and to deal with your requests and enquiries;</p>
      <p>&bull; to provide you with the most user-friendly online navigation experience;</p>
      <p>&bull; for &quot;administration purposes&quot;, which means that MotoBids.ie&nbsp;may contact you for reasons related to&nbsp;auction services, forwarding on invoicesor online content you have signed up for (e.g. to provide you with password reminders, speak to you about invoicing, documentation)</p>
      <p>&bull; to use IP addresses and device identifiers to block disruptive use</p>
      <p><strong>When will MotoBids.ie contact me?</strong><br/>We may contact you:</p>
      <p>&bull; in relation to any&nbsp;product we have in for auction</p>
      <p>&bull; in relation to any correspondence we receive from you or any comment or complaint you make about MotoBids.ie.</p>
      <p>&bull; in relation to any personalised services you are using eg Online Auctions</p>
      <p>&bull; for marketing purposes.</p>
      <p>&bull;&nbsp;Arranging the collection or delivery of vehicles</p>
      <p><strong>Who has access to your information?<br/></strong>The staff of&nbsp;MotoBids.ie have access to the information you provide to us. The information they have access to is controlled and they can only access information required to ensure the services we provide to our clients are met.<strong><br/></strong>We use third party software in our day to day activities including for cloud storage, marketing and accounting. Our IT contractors may have access to your information in the course of providing IT support to us.</p>
      <p><strong>Will I be contacted for marketing purposes?</strong></p>
      <p>We will only send you marketing emails or contact you about&nbsp;auctions where you have agreed to this. We offer regular emails &amp; SMS text messages, to let you know about&nbsp;forthcoming auctions. We may personalise the message content based upon any information you have provided to us.</p>
      <p><strong>Will&nbsp;MotoBids.ie share my personal information with anyone else?</strong><br/>We will keep your information within MotoBids except where disclosure is required or permitted by law (for example to government bodies and law enforcement agencies) and to <a href="https://www.prommt.com/cookie-policy/">Prompt</a> our chosen partner for <a href="https://www.prommt.com/cookie-policy/">SMS, email and payment acceptance</a>.&nbsp;</p>
      <p>We will hold your personal information on our systems for as long as is necessary for the relevant activity, or as long as is set out in any relevant contract you hold with us. If you delete your account then your personal information is deleted immediately.&nbsp;</p>
      <p>If you have not used your MotoBids.ie account in the last 7 years then your account may be classed as dormant or may be deleted in line with this privacy policy.&nbsp;</p>
      <p>However, if a customer has purchased or sold items from us the CMS will retain it for 7 years.&nbsp;</p>
      <p><strong>How will we protect your information?<br/></strong>We will endeavour to maintain physical, technical and procedural safeguards that are appropriate to the sensitivity of the personal information in question. These safeguards are designed to protect your personal information from loss and unauthorised access, copying, use, modification or disclosure. Examples of these safeguards include password protected and encrypted computers.</p>
      <p><strong>Can I delete my data?</strong><br/>You can always delete your MotoBids.ie account.&nbsp;</p>
      <p>Deleting your MotoBids.ie account will erase any personal information in your account that we have about you and it will mean any data we hold about you will be made anonymous. However, if a customer has purchased or sold items from us the CMS will retain PII for 7 years.&nbsp;</p>
      <p>Individuals wishing to have personal data erased by MotoBids under the General Data Protection Regulation can send a request by email to: <a href="mailto:hello@motobids.ie.">hello@motobids.ie.</a></p>
      <p>The personal data to be erased should be clearly identified. We may require conformation of the identity of the data subject and/or the person making the request. We will respond to requests within one month of receipt of the submitted request (provided sufficient information has been given to us to enable us to process the request). If your request is complex we may require up to an additional two months to complete the request. If this is the case we will inform you how much additional time is needed and the reasons why within the first month.</p>
      <p>Once Information has been identified for disposal/deletion this information should be recorded in the company&rsquo;s GDPR-Log of Information Assets For Disposal log file, this is so that in the event of a recovery of data from a historical backup the information identified for disposal/deletion can be removed again. Any personal data to be deleted should be deleted in all formats (hard copy, digital, back-up copies etc).</p>
      <p><strong>Can I find out what personal information&nbsp;MotoBids.ie holds about me?</strong><br/>Under the Data Protection Act and come May 2018 under the EU General Data Protection Regulation (GDPR) you have the right to request a copy of the personal information MotoBids holds about you and to have any inaccuracies corrected. To ensure we are releasing data to the right person we require you to complete a subject access request form and provide us with proof of your identity and of your address. Please supply us with a photocopy or scanned image (do not send the originals) of one of both of the following:</p>
      <ol>
          <li>Proof of Identity -&nbsp;Passport, photo driving licence, national identity card, birth certificate.</li>
          <li>Proof of Address -&nbsp;Utility bill, bank statement, credit card statement (no more than 3 months old); current driving licence; current TV licence; local authority tax bill, HMRC tax document (no more than 1 year old).</li>
      </ol>
      <p>If we are not satisfied you are who you claim to be, we reserve the right to refuse to grant your request. We will use reasonable efforts consistent with our legal duty to supply, correct or delete personal information about you on our files.</p>
      <p>If at any time a client is unhappy with how the company has processed or processes their data, wishes to make alterations to any data which they believe is incorrect or irrelevant or wishes to stop receiving marketing information from us they can do this by contacting&nbsp;us by phone or email.<br/>If you feel your request was not handled or dealt with correctly by the company, you may raise the issue with the Information Commissioner&rsquo;s Office.&nbsp;</p>
      <p>Individuals wishing to&nbsp;access there personal data can make a subject access request&nbsp;to MotoBids under the General Data Protection Regulation, you can send a request by email to:<a href="mailto:hello@motobids.ie.">hello@motobids.ie.</a></p>
      <p>&nbsp;</p>
      <p><strong>Contacting MotoBids.ie about this Cookies and Privacy Policy</strong></p>
      <p>If you any questions or comments about this Cookies and Privacy Policy please contact:</p>
      <p>The Data Protection Officer<br/>MotoBids.ie, Cirrus Suite, The Atrium, John&rsquo;s Lane, Naas, Co Kildare, W91 WF21.<br/>(Email: hello@motobids.ie)</p>
      <p>This Privacy and Cookies Policy may be updated from time to time so you may wish to check it each time you submit personal information to us. The date of the most recent revisions will appear on this page. If you do not agree to these changes, please do not continue to use MotoBids.ie website to submit personal information. You can also delete your account at any time by geting in touch with MotoBids.ie.</p>
      <p>Page Updated March 2022.</p>
      <p>Version 1</p>
    </div>
  )
}

export default PrivacyPolicy
